/*-------------------------------------------------------
 ------------------->>> HOME <<<----------------------
 -------------------------------------------------------  */


.home, .annonce, .shop, .search-page, .page-login, .page-about, .blog-listing, .blog-show, .page-statique, .page-404, .blog-search {
    .banniere {
        background-color: #84c6d0;
        @media (max-width: 1600px) {
            background-image: none !important;

        }
        @media screen and (min-width: $break-large) {
            // background-image: url('#{$imgPath}banniere/banniere-header.jpg');
            background-image: url('#{$imgPath}banniere/image-header.png');
            background-size: auto;
            background-position: right;
            background-repeat: no-repeat;
            min-height: 365px;
            @include flex(column, center, null);
            // overflow:hidden;
            // z-index:999;
            min-height:500px;
        }
        @media (max-width: 1000px) {
            .logos-sponsors {
                display: none;
            }
        }

        @media screen and (min-width: $break-super-large) {
            min-height: 500px;
        }

        > .container {
            position: relative;
            display: block;

            .galet.header {
                display: none;
                @media screen and (min-width: $break-medium) {
                    // display: block;
                    opacity: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include translate(-49%, -53%);
                    width: 725px;
                    height: 569px;
                }
                @media screen and (min-width: $break-super-large) {
                    width: 1413px;
                    height: 1034px;
                    @include translate(-49%, -52%);
                }
            }

            .capsule-titre {
                position: relative;
                z-index: 9;
                padding-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 100px;
                max-width: 800px;
                line-height: 2em;
                padding-right: 130px;
                margin-left: 10px;
                @media (max-width: 1000px) {
                    justify-content: center;
                    margin-left: 0;
                    margin-right: 0;
                    padding-right: 0;
                    .logo a img {
                        width: 70px;
                    }
                    h1 {
                        min-width: 280px;
                        padding-left: 20px;
                        font-size: 10px;
                    }
                }

                .logos-sponsors {
                    display: none;
                    background-color: #4bbbe0;
                    @media screen and (min-width: $break-small) {
                        display: block;
                    }
                    position: absolute;
                    top: 0;
                    left: 0;

                    div {
                        display: inline-block;
                        padding-right: 10px;

                        img {
                            height: 50px;
                        }
                    }
                }

                .logo {
                    text-align: center;

                    img {
                        max-width: 127px;
                        height: auto;
                        margin: 0 auto;
                    }
                }

                .title {
                    text-align: center;
                    font-size: 0.9rem;
                    color: $white;
                    font-weight: bold;
                    padding-bottom: 1rem;
                    padding-top: 1.5rem;
                    @media screen and (min-width: $break-large) {
                        font-size: 1.4rem;
                        padding-top: 0.5rem;
                    }
                }

                h1 {
                    font-size: 1rem;
                    color: $white;
                    text-align: left;
                    font-weight: 300;
                    padding-bottom: 1.2rem;
                    line-height: 2em;
                    @media screen and (min-width: $break-large) {
                        font-size: 1.5rem;
                    }
                    @media screen and (min-width: $break-super-large) {
                        // font-size:3.37rem;
                        padding-top: 2rem;
                        // padding-bottom: 3rem;
                    }

                    span.highlighted {
                        font-weight: 900;
                        color: $white;
                        @media screen and (min-width: $break-medium) {
                            display: block;
                        }
                    }
                }
            }

            .searching {
                .search-form {
                    border: 0;
                    margin: 0 auto;
                    position: relative;
                    text-align: center;
                    max-width: 320px;
                    padding: 0;
                    @media screen and (min-width: $break-super-large) {
                        width: 730px;
                        max-width: inherit;
                    }

                    .search {
                        input, select {
                            color: $grey;
                            font-size: 0.87rem;
                            @include radius(100px);
                            vertical-align: middle;
                            padding: 13px 0 13px 20px;
                            width: 100%;
                            border: 0;
                            margin-bottom: 20px;
                            visibility: hidden;
                            height: 0;
                            // max-width:300px;
                            // @media screen and (min-width: $break-large) {
                            //     padding: 13px 0 13px 20px;
                            // }
                            &::-webkit-input-placeholder {
                                color: $grey;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }

                            &:-moz-placeholder {
                                color: $grey;
                                opacity: 1;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }

                            &::-moz-placeholder {
                                color: $grey;
                                opacity: 1;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }

                            &:-ms-input-placeholder {
                                color: $grey;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }
                        }
                    }

                    .action {
                        position: unset;
                        background: $deepRed;
                        @include radius(100px);
                        width: 80px;
                        height: 80px;
                        border: 1px solid $deepRed;
                        grid-column: 2 / span 1;
                        grid-row: 2 / span 2;
                        align-self: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: -30px;
                        justify-self: start;
                        box-shadow: 5px 5px 15px;
                        z-index: 0;
                        @media (min-width: 1200px) {
                            top: 0;
                        }
                        @media screen and (min-width: $break-super-large) {
                            width: 100%;

                        }

                        &:hover {
                            border: 1px solid $deepRed;
                            background: $white;

                            button {
                                color: $deepRed;
                            }
                        }

                        button {
                            color: $white;
                            font-weight: bold;
                            text-align: center;
                            font-size: 1rem;
                            padding: 11px 0;
                            background: none;
                            border: 0;

                            span {
                                display: inline;
                                font-size: 14px;
                                padding-left: 5px;
                            }

                            // span{
                            //     display:none;
                            //     @media screen and (min-width: $break-super-large) {
                            //         display:inline;
                            //     }
                            // }
                            .fa {
                                font-weight: 600;
                                font-size: 27px;
                                margin-top: 5px;
                                @media screen and (min-width: $break-super-large) {
                                    padding-right: 0.7rem;
                                }
                            }

                        }
                    }
                }
            }
        }
    }

}
.home, .annonce {
    nav.navbar{
        .small-menu{
            background:transparent;
            .logo-sticked{
                display:none;
            }
            .search{
                display:none;
            }
            .navbar-burger{
                position:absolute;
                top:5px;
                left:5px;
                display: inline-block;
                width: 50px;
                height:50px;
                @include radius(50%);
                padding: 0.3rem;
                background-color:$deepRed;
                z-index: 1;
                cursor:pointer;
                @media screen and (min-width: $break-large) {
                    position:relative;
                }
            }
        }  
    }
    main {
        overflow: hidden;
        // padding-top: 77px;
        @media screen and (min-width: $break-large) {
            padding-top: 0;
        }
    }

    .titles {
        .accroche {
            text-align: center;
            font-size: 0.87rem;
            padding-top: 20px;
            margin: 0 auto;
            @media screen and (min-width: $break-large) {
                font-size: 20px;
            }
            p{
                margin-bottom:0;
            }
        }
    }

    #offers {
        ///la première section est située à 61px du main !!!
        padding-top: 61px;
        position: relative;
        overflow: hidden;
        .capsule-galet {
            position: relative;
            .galet {
                background: #4ABBE2;
                position: absolute;
                left: unset;
                transform: rotate(132deg);
                top: -118px;
                height: 1020px;
                right: -552px;
                opacity: 0.05;
                @media screen and (min-width: $break-large) {
                    width: 1140px;
                    top: -27px;
                }
            }
        }
        .the-content {
            @include flexDirection(row);
            padding-top: 40px;
            .blog-annonce-small {
                @media screen and (min-width: $break-medium) {
                    display: none;
                }
                .vignette{
                    .capsule {
                        min-height: 316px;
                        margin-bottom: 15px;
                        @include radius(12px);
                        @include flex(column, null, null);
                        background-size:cover;
                        background-repeat:no-repeat;
                        background-position:center center;
                        .about-post {
                            margin: auto 0 0 0;
                            padding: 0 20px 20px 20px;
                            z-index: 1;
                            .title {
                                color: $white;
                                font-weight: bold;
                                font-size: 26px
                            }
                            .underline {
                                color: $white;
                                font-weight: bold;
                                font-size: 12px;
                            }
                        }
                    }
                    &.blog{
                        padding-left:15px;
                        padding-right:15px;
                        margin-bottom: 15px;
                    }
                }
            }
            .offer-carousel {
                .slick-list {
                    .vignette.blog,
                    .vignette.annonce {
                        display: none;
                    }
                }
                button.slick-next,
                button.slick-prev {
                    display: none !important;
                }
                .slick-slide {
                    opacity: 0.3;
                }
                .slick-slide.slick-active {
                    opacity: 1;
                }
            }
            > .capsule-nav{
                bottom: 110px;
                position: absolute;
                left: 0;
                right: 0;
                @media screen and (min-width: $break-medium) {
                    display:none;
                }
            }
            .vignette-annonce {
                @media screen and (min-width: $break-medium) {
                    order: 2;
                }
                .capsule {
                    min-height: 385px;
                    max-width: none;
                }
                &.blog {
                    display: none;
                    @media screen and (min-width: $break-medium) {
                        order: 1;
                        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
                        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
                        display: -ms-flexbox; /* TWEENER - IE 10 */
                        display: -webkit-flex; /* NEW - Chrome */
                        display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
                        min-width: initial;
                        max-width: initial;
                    }
                    .link-article{
                        width: 100%;
                        margin: 0 5px;
                        .capsule {
                            width: 100%;
                            padding: 20px;
                            .about-post {
                                z-index: 1;
                                margin: auto 0 0 0;
                                .title {
                                    color: $white;
                                    font-weight: bold;
                                    font-size: 26px;
                                }
                                .underline {
                                    color: $white;
                                    text-decoration: underline;
                                    font-weight: bold;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
                &.annonce {
                    display: none;
                    @media screen and (min-width: $break-medium) {
                        display: block;
                        order: 3;
                    }
                    a{
                        display:block;
                        .capsule {
                            background-size:cover;
                            background-repeat:no-repeat;
                            background-position:center; 
                            @include radius(0);
                            margin: 0 5px;
                        }
                    }
                }
                &:nth-child(1n+8) {
                    @media screen and (min-width: $break-medium) {
                        display: none;
                    }
                }
                &:last-child {
                    @media screen and (min-width: $break-medium) {
                        display: block;
                    }

                }
            }
            .overlay{
                position: absolute;
                @include radius(12px);
                bottom: 0;
                min-height: 130px;
                @include gradient(black, transparent, to top);
                z-index: 0;
                width: 100%;
                left: 0;
            }
        }

        .cta {
            padding-top: 40px;
            .btn-cta{
                @media screen and (min-width: $break-large) {
                    padding:14px 66px;
                }
            }
        }
    }

    .capsule-galet {
        position: relative;

        .galet {
            background: #E25B45;
            position: absolute;
            opacity: 0.05;
            left: -416px;
            top: -154px;
            @include rotate(1deg);
            @media screen and (min-width: $break-large) {
                left: -357px;
                top: -150px;
                @include radius (701px 802px 722px 814px /371px 616px 744px 968px);
                @include rotate(48deg);
            }
        }
    }

    .capsule-sub-forme {
        margin-top: 80px;
        @include flex(column, center, center);
        position: relative;
        height: 90px;
        overflow: hidden;

        .sub-forme {
            position: absolute;
            top: 0;
            width: 120vw;
            @include radius (50% 50% 0 0 /80px 80px 0 0);
            background: $blocPro;
            height: 90px;
        }
        @media screen and (min-width: $break-large) {
            display: none;
        }
    }

    #professionals {
        position: relative;
        @include flex(column, null, center);
        overflow: hidden;
        @media screen and (min-width: $break-large) {
            @include flex(column, center, center);
            margin-top: 80px;
        }
        .pro-forme {
            position: absolute;
            top: 0;
            width: 120vw;
            background: $blocPro;
            height: 1160px;
            z-index: -1;
            left: -10vw; //pour IE
            @media screen and (min-width: $break-medium) {
                height: 1435px;
            }
            @media screen and (min-width: $break-large) {
                @include radius (50% 50% 0 0 /140px 140px 0 0);
                height: 1250px;
            }
        }
        .titles {
            @media screen and (min-width: $break-large) {
                padding-top: 80px;
            }
        }
        .the-content {
            padding-bottom: 80px;
            .structure-pro {
                padding-top: 40px;
                >.capsule-nav{
                    bottom: 205px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    @media screen and (min-width: $break-medium) {
                        display:none;
                    }
                }
                .pro-carousel {
                    .slick-list {
                        .vignette.blog,
                        .vignette.annonce {
                            display: none;
                        }
                    }
                    button.slick-next,
                    button.slick-prev {
                        display: none !important;
                    }
                    .slick-slide {
                        opacity: 0.3;
                    }
                    .slick-slide.slick-active {
                        opacity: 1;
                    }

                    .vignette {
                        .capsule{
                            @media screen and (min-width: $break-medium) {
                                margin:0 5px 60px 5px;
                            }
                        }
                        &:nth-child(3) {
                            margin-bottom: 0;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .annonce {
                    padding-bottom: 17px;
                    .capsule {
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        min-height: 652px;
                        height: 100%;
                        @include radius(0);
                    }
                }
            }
            .cta {
                padding-top: 40px;
                @media screen and (min-width: $break-large) {
                    padding-top: 40px;
                }
                .btn-cta{
                    @media screen and (min-width: $break-large) {
                        padding:14px 66px;
                    }
                }
            }
        }
    }

    .capsule-galet {
        position: relative;
        .galet2 {
            background: #E25B45;
            position: absolute;
            right: -392px;
            top: -435px;
            opacity: 0.05;
            @include rotate(-95deg);
            @media screen and (min-width: $break-super-large) {
                right: -275px;
                top: -328px;
                width: 485px;
            }
        }
    }

    .capsule-galet {
        position: relative;

        .galet3 {
            background: #6CC7E6;
            position: absolute;
            left: -283px;
            top: 770px;
            opacity: 0.05;
            @include rotate(10deg);
            @media screen and (min-width: $break-large) {
                left: 20px;
                top: 174px;
                width: 594px;
                height: 666px;
                @include rotate(14deg);
            }
            @media screen and (min-width: $break-super-large) {
                left: 105px;
            }
        }
    }

    #blog {
        .titles {
            padding-top: 5rem;
            padding-bottom: 40px;
        }

        #carouselBlog {
            .capsule-nav{
                bottom: -30px;
                position: absolute;
                left: 0;
                right: 0;
                @media screen and (min-width: $break-large) {
                    bottom: -18px; 
                }
            }
            .carousel-control-next,
            .carousel-control-prev {
                display: none;
                @media screen and (min-width: $break-super-large) {
                    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
                    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
                    display: -ms-flexbox; /* TWEENER - IE 10 */
                    display: -webkit-flex; /* NEW - Chrome */
                    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
                    opacity: 1;
                }
            }
            .carousel-control-prev {
                left: -11%;
            }
            .carousel-control-next {
                right: -11%;
            }
            .carousel-control-next-icon {
                background-image: url('#{$imgPath}/pictos/right-arrow.png');
                width: 15px;
            }
            .carousel-control-prev-icon {
                background-image: url('#{$imgPath}/pictos/left-arrow.png');
                width: 15px;
            }
            .carousel-inner {
                @media screen and (min-width: $break-large) {
                    min-height: 490px;
                    padding: 0 15px;
                }
                @media screen and (min-width: $break-extra-large) {
                    min-height: 480px;
                }
                .carousel-item {
                    .post {
                        .post-pict {
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center center;
                            min-height: 205px;
                            padding: 0;
                            display:block;
                            @media screen and (min-width: $break-large) {
                                @include radius(42px);
                                padding: 0 15px;
                                min-height: 419px;
                            }
                            img {
                                @media screen and (min-width: $break-large) {
                                    display: none;
                                }
                            }
                        }
                        .post-txt {
                            @include flex (column, null, space-between);
                            background: $lightBlue;
                            color: $white;
                            padding: 30px 20px;
                            @include radius(0px 42px 42px 42px);
                            max-width: 95%;
                            margin: -35px auto 0 auto;
                            min-height: 225px;
                            @media screen and (min-width: $break-medium) {
                                max-width: 80%;
                            }
                            @media screen and (min-width: $break-large) {
                                padding: 45px 65px;
                                position: absolute;
                                top:26px;
                                right: 0;
                                min-height: 449px;
                                max-width: 450px;
                                margin: 0;
                            }
                            .date {
                                font-weight: 900;
                                font-size: 0.75rem;
                                @media screen and (min-width: $break-large) {
                                    font-size: 1rem;
                                }
                            }
                            .title {
                                font-weight: bold;
                                font-size: 1.12rem;
                                padding-top: 1.25rem;
                                padding-bottom: 0.62rem;
                                @media screen and (min-width: $break-large) {
                                    font-size: 1.7rem;
                                    padding-bottom: 0.8rem;
                                }
                            }
                            .excerpt {
                                font-size: 0.9rem;
                                @media screen and (min-width: $break-large) {
                                    font-size: 1.3rem;
                                    padding-top: 1rem;
                                }
                            }
                            .read {
                                margin: auto 0 0 0;
                                font-weight: 900;
                                font-size: 0.68rem;
                                color: white;
                                text-decoration: underline;
                                @media screen and (min-width: $break-large) {
                                    text-transform: uppercase;
                                    font-size: 1.12rem;
                                }
                            }
                        }
                    }

                }
            }
        }

        .cta {
            margin-top: 60px;
            .btn-cta{
                @media screen and (min-width: $break-large) {
                    margin-top: 40px;
                    padding:14px 66px;
                }
            }
        }

    }

    #searching {
        margin-top: 96px;

        .capsule-search {
            min-height: 620px;
            position: relative;
            overflow: hidden;
            @include flex(column, center, flex-start);
            @media screen and (min-width: $break-large) {
                min-height: 650px;
            }
            .search-engine{
                grid-template-columns: 1fr 65px !important;
                .action{
                    right: 0;
                    -webkit-transform: translate(0, -120%) !important;
                    -ms-transform: translate(0, -120%) !important;
                    transform: translate(0, -120%) !important;
                    width: 195px;
                    i{
                        font-weight: 600;
                        font-size: 27px;
                        margin-top: 5px;
                    }
                }
            }
        }

        .search-forme {
            background: linear-gradient(rgba(75, 187, 224, 0.9), rgba(75, 187, 224, 0.9)), url(#{$imgPath}banniere/bandeau-search.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            position: absolute;
            top: 0;
            width: 120vw;
            left: -10vw; //pour IE
            @include radius (0% 0% 50% 50%/ 0% 0% 80px 80px);
            height: 619px;
            //z-index:999;
            @media screen and (min-width: $break-large) {
                height: 537px;
                @include radius (0% 0% 50% 50% /0% 0% 140px 140px);
            }
        }

        .the-content {
            @media screen and (min-width: $break-large) {
                padding-top: 5.6rem;
            }

            .bloc-pictos {
                z-index: 2;
                @include order(2);
                @media screen and (min-width: $break-large) {
                    @include order(1);
                }
            }

            .bloc-form {
                z-index: 2;
                @include order(1);
                @media screen and (min-width: $break-large) {
                    @include order(2);
                }
            }

            .pictos {
                background: $white;
                @include radius(20px);
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
                max-width: 350px;
                margin: 0 auto;
                &.small-pict{
                    display: block;
                    @media screen and (min-width: $break-large) {
                        display: none;
                    }
                }
                &.big-pict{
                    display: none;
                    @media screen and (min-width: $break-large) {
                        display: block;
                    }
                }
                .picto-content {
                    width: 100%;
                    margin: 0;
                    .pict {
                        cursor: pointer;
                        padding: 22px 0;
                        height: 130px;
                        width: 130px;
                        @include flex(column, center, center);
                        @media screen and (min-width: 375px) {
                            @include flex(column, center, space-between);
                        }
                        @media screen and (min-width: $break-large) {
                            height: 175px;
                            @include flex(column, center, flex-start);
                        }
                        img.hover {
                            display: none;
                        }
                        p.cat {
                            padding-top: 9px;
                            color: $deepGrey;
                            font-weight: 500;
                            text-transform: capitalize;
                            text-align: center;
                            font-size: 0.75rem;
                            margin-bottom:0;
                            min-height:45px;
                            @media screen and (min-width: 375px) {
                                min-height:auto;
                            }
                            @media screen and (min-width: $break-large) {
                                font-size: 1rem;
                                padding-top: 24px;
                                min-height:72px;
                            }
                            span {
                                display: block;
                            }
                        }
                        &.left{
                            border-right: 1px solid $lightWhite;
                            border-bottom: 1px solid $lightWhite;
                        }
                        &.left-last {
                            border-right: 1px solid $lightWhite;
                        }
                        &.right {
                            border-bottom: 1px solid $lightWhite;
                        }
                    }
                    .categorie {
                        &:hover,
                            &.active{
                            img {
                                display: none;
                            }

                            img.hover {
                                display: block;
                            }

                            p.cat {
                                color: white;
                            }
                        }
                    }

                    .pict:first-child {
                        @include radius(20px 0 0 0);
                    }

                    .pict:nth-child(2) {
                        @include radius(0 20px 0 0);
                    }

                    .pict:nth-last-child(2) {
                        @include radius(0 0 0 20px);
                    }

                    .pict:last-child {
                        @include radius(0 0 20px 0);
                    }
                }
            }
            .titles {
                padding: 30px 0;
                @media screen and (min-width: $break-large) {
                    padding-top: 60px;
                }
                h2 {
                    color: $white;
                    @media screen and (min-width: $break-large) {
                        text-align: left;
                    }
                    span {
                        color: $white;
                    }
                }
            }
            .searching {
                padding-bottom: 25px;
                display: none;
                @media screen and (min-width: $break-large) {
                    display: block;
                    padding-bottom: 0;
                    margin-top: 60px;
                }
                .search-form {
                    border: 0;
                    margin: 0 auto;
                    position: relative;
                    text-align: center;
                    max-width: 320px;
                    padding: 0;
                    @media screen and (min-width: $break-large) {
                        width: 515px;
                        max-width: inherit;
                    }
                    @media screen and (min-width: $break-extra-large) {
                        width: 730px;
                    }
                    .search {
                        position: relative;
                        z-index: 99;
                        input {
                            color: $grey;
                            font-size: 0.87rem;
                            @include radius(100px);
                            vertical-align: middle;
                            padding: 13px 30px 13px 30px;
                            display: block;
                            border: 0;
                            width: 100%;
                            
                            @media screen and (min-width: $break-large) {
                                padding: 13px 50px 13px 50px;
                            }
                            &::-webkit-input-placeholder {
                                color: $grey;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }
                            &:-moz-placeholder {
                                color: $grey;
                                opacity: 1;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }
                            &::-moz-placeholder {
                                color: $grey;
                                opacity: 1;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }
                            &:-ms-input-placeholder {
                                color: $grey;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }
                        }
                    }

                    .action {
                        z-index: 999;
                        background: $deepRed;
                        border: 1px solid $deepRed;
                        @include radius(100px);
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 45px;
                        @media screen and (min-width: $break-large) {
                            top: 50%;
                            right: 0;
                            @include translate(0, -50%);
                            width: 195px;
                        }
                        @media screen and (min-width: $break-extra-large) {
                            width: 237px;
                        }
                        &:hover{
                            border:1px solid $deepRed;
                            background:$white;
                            button{
                                color:$deepRed;
                            }
                        }
                        button {
                            color: $white;
                            font-weight: bold;
                            text-align: center;
                            font-size: 1rem;
                            padding: 11px 0;
                            background: none;
                            border: 0;

                            span {
                                display: none;
                                @media screen and (min-width: $break-large) {
                                    display: inline;
                                }
                            }

                            .fa {
                                @media screen and (min-width: $break-large) {
                                    padding-right: 0.7rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #annonce {
        padding-top: 14px;
        .capsule {
            height: 202px;
            //border: 1px solid $grey;
            z-index: 2;
            position: relative;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .capsule-galet-2 {
        position: relative;

        .galet3 {
            background: #6CC7E6;;
            position: absolute;
            opacity: 0.05;
            right: -167px;
            top: -132px;
            width: 300px;
            height: 333px;
            @media screen and (min-width: $break-large) {
                width: 594px;
                height: 666px;
                right: -164px;
                top: -533px;
                @include rotate(23deg);
            }
        }
    }

    .capsule-galet-3 {
        position: relative;

        .galet3 {
            background: #E25B45;
            position: absolute;
            opacity: 0.05;
            width: 334px;
            height: 343px;
            left: -255px;
            top: 806px;
            @include rotate(-81deg);

            @media screen and (min-width: $break-large) {
                left: 22px;
                top: -192px;
                @include rotate(-81deg);
                @include radius (641px 366px 484px 422px/ 702px 544px 454px 342px);
            }
            @media screen and (min-width: $break-end) {
                left: 110px;
            }
        }
    }

    #presents {
        position:relative;
        .titles {
            padding-top: 5rem;
            padding-bottom: 40px;
        }
        .capsule-nav{
            bottom: 100px; 
            position: absolute;
            left: 0;
            right: 0;
            @media screen and (min-width: $break-large) {
                bottom: 100px; 
            }
        }
        .present-carousel {
            .slick-slide{
                width: 275px;
            }
            button.slick-next,
            button.slick-prev {
                display: none !important;
            }
            .slick-slide {
                opacity: 0.3;
            }
            .slick-slide.slick-active {
                opacity: 1;
            }
            .vignette-annonce {
                .capsule {
                    max-width: none;
                }
            }
        }

        .cta {
            margin-top: 40px;

            .btn-cta {
                @media screen and (min-width: $break-large) {
                    max-width: 367px;
                    padding:14px 66px;
                }
            }
        }
    }

    #highlighted-shop{
        position:relative;
        .titles {
            padding-top: 5rem;
            padding-bottom: 40px;
        }
        .vignette{
            margin-bottom: 50px;
            @media screen and (min-width: $break-medium) {
                max-width:inherit;
            }
            .capsule{
                @media screen and (min-width: $break-medium) {
                    margin:0 5px;
                }
            }

        }
        .capsule-nav{
            @media screen and (min-width: $break-medium) {
                display:none;
            }   
        }
    }


    #about {
        margin-top: 6.18rem;

        .capsule-about {
            @include flex(column, center, center);
            min-height: 680px;
            overflow: hidden;
            position: relative;

            .galet {
                display: none;
                @media screen and (min-width: $break-extra-large) {
                    display: block;
                    position: absolute;
                    top: -25px;
                    left: -21px;
                    opacity: 1;
                    width: 1138px;
                    height: 1015px;
                    @include radius (523px 818px 693px 814px /367px 554px 744px 968px);
                }
                @media screen and (min-width: $break-super-large) {
                    left: 75px;
                }

            }

            .about-forme {
                position: absolute;
                top: 0;
                left: -10vw; //POUR IE
                width: 120vw;
                @include radius (50% 50% 0 0 /80px 80px 0 0);
                background: $blocPro;
                height: 1160px;
                @media screen and (min-width: $break-large) {
                    @include radius (50% 50% 0 0 /140px 140px 0 0);
                }
            }
        }

        .the-content {
            padding-top: 3.5rem;
            position: relative;
            @media screen and (min-width: $break-large) {
                padding-top: 7.18rem;
            }

            .about {
                @include flex(column, null, space-between);
                @include alignSelf (stretch);

                h2 {
                    text-align: center;
                    margin-bottom: 0;
                    font-size: 1.5rem;;
                    @media screen and (min-width: $break-large) {
                        font-size: 2.18rem;;
                        text-align: left;
                    }
                }

                .first {
                    padding-top: 1.25rem;
                    padding-bottom: 1.25rem;
                }

                .first,
                .second {
                    color: $grey;
                    font-size: 0.87rem;
                    text-align: center;
                    @media screen and (min-width: $break-large) {
                        text-align: left;
                        font-size: 1.25rem;
                    }
                }

                a {
                    color: lightblue;
                    text-decoration:underline;
                }

                .partenaires {
                    padding-top: 1.87rem;
                    padding-bottom: 1.87rem;
                    @include alignItems (flex-end);
                    @include justifyContent (space-between);
                    @media screen and (min-width: $break-large) {
                        padding-top: 1.25rem;
                        padding-bottom: 0;
                    }
                }

            }

            .pict {
                padding-bottom: 1.87rem;
                @media screen and (min-width: $break-large) {
                    @include alignSelf (stretch);
                    padding-bottom: 0;
                }
                @media screen and (min-width: $break-super-large) {
                    position: absolute;
                    right: -40px;
                    width: 677px;
                    height: 400px;
                    overflow: hidden;

                }
            }
        }

        .cta {
            padding-bottom: 1.87rem;
            z-index: 2;
            position: relative;
            @media screen and (min-width: $break-large) {
                margin-top: 5.87rem;
            }
            .btn-cta {
                @media screen and (min-width: $break-large) {
                    max-width: 367px;
                    padding:14px 66px;
                }
            }
        }
    }


}
