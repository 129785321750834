/*-------------------------------------------------------
 ------------------->>> SHOP: les commerçants<<<----------------------
=> la vue search avec listing + vue show
 -------------------------------------------------------  */

// listing announces
// .listing-container{
//     .listing{
//         .offre-shop{
//             .vignette{
//                 &.container-cards-col{
//                     @media screen and (min-width: 2234px){
//                         max-width: 25%;
//                     }
//                     @media screen and (min-width: 2734px){
//                         max-width: 20%;
//                     }
//                     @media screen and (min-width: 3134px){
//                         max-width: 16%;
//                     }
//                     @media screen and (min-width: 3634px){
//                         max-width: 14%;
//                     }
//                     @media screen and (min-width: 4134px){
//                         max-width: 12%;
//                     }
//                     @media screen and (min-width: 4934px){
//                         max-width: 11%;
//                     }
//                     @media screen and (min-width: 5534px){
//                         max-width: 10%;
//                     }
//                     @media screen and (min-width: 6634px){
//                         max-width: -1%;
//                     }
//                 }
//             }
//         }
//     }
// }

/* vue de recherche d'un commerçant :overide du header du layout */
.search{
    header{
        .banniere{
            @media screen and (min-width: $break-large) {
                min-height:247px;
            }
            .capsule-titre{
                @media screen and (min-width: $break-large) {
                    .logo{
                        img{
                            max-width: 127px;
                            height: auto;
                        }
                    }
                }
            }
        }
    }

    .listing-container{
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        padding-top:77px;
        overflow-x:hidden;
        @media screen and (min-width: $break-large) {
            margin-top:1.87rem;
            padding-top:0;
        }
        #map-search{
            @include order(0);
            padding-left:0;
            padding-right:0;
            @media screen and (min-width: $break-large) {
                padding-left:15px;
                padding-right:15px;
                @include order(inherit);
                position:absolute;
                top:0;
                right:0;
                height:100%;
            }
            &.sticked{
                position:fixed;
                top:128px;
            }
            .capsule-map{
                @media screen and (min-width: $break-large) {
                    position: -webkit-sticky;
                    // position: fixed; POUR IE
                    position: sticky;
                    top:1rem;
                    padding-bottom:1rem;
                }
                .map{
                    height: 300px;
                    @media screen and (min-width: $break-small) {
                        height: 400px;
                    }
                    @media screen and (min-width: $break-medium) {
                        height: 500px;
                    }
                    @media screen and (min-width: $break-large) {
                        height: 800px;
                    }
                }
            }
        }
        .bloc-listing{
            @include order(1);
            padding-left:0;
            padding-right:0;
            @media screen and (min-width: $break-large) {
                padding-left:15px;
                padding-right:15px;
                @include order(inherit);
                min-height:810px;
            } 
            .title-cat{
                font-weight:900;
                font-size: 42px;
                text-align: center;
                padding:0 0 40px 0;
            }
            .search-formulaire{
                //background:rgba(226,91,69,0.08);
                background: rgba(75, 187, 224, 0.5);
                border:0;
                padding:1.87rem;

                @media screen and (min-width: $break-large) {
                    @include radius(12px);
                }

                #select-sort, .form-group-border, .form-group .select2-container {
                    border: 2px solid #4bbbe0;
                    border-radius: 13px;
                }
                #select-sort {
                    content: '\f078';
                    font-family: "Font Awesome 5 Free";
                    color: #e25b45;
                    font-size: 1rem;
                    font-weight: 600;
                    position: absolute;
                    color: #4a4a4a;
                    font-size: 0.87rem;
                    font-weight: 300;
                    font-family: "Now", sans-serif;

                }

                .select2-container,
                input.form-control.same-select{
                    display:block !important;
                    margin: 0 auto !important;
                    @media screen and (min-width: $break-small) {
                        display:inline-block !important;
                        margin: 0!important;
                    }
                }
                .select2-container{
                    //border: none !important;
                    max-width: 100%;
                    .select2-selection--multiple{
                        border: none !important;
                        @include radius(12px);
                        .select2-selection__rendered{
                            padding: 14px 15px;
                            display: flex;
                            align-items: center;
                            gap: 0.25rem;
                            flex-wrap: wrap;
                            .select2-highlighted{
                                background: $lightBlue !important;
                            }
                            .select2-selection__choice{
                                background: $lightBlue !important;
                                box-shadow: 0 0 2px $lightBlue inset, 0 1px 0 rgba(0, 0, 0, 0.05) !important;
                                -webkit-box-shadow: 0 0 2px $lightBlue inset, 0 1px 0 rgba(0, 0, 0, 0.05) !important;
                                border: 0 !important;
                                /* Safari 3-4, iOS 1-3.2, Android 1.6- */
                                @include radius(7px);
                                @include flex(null, null, null);
                                color: white !important;
                                padding: 4px 17px !important;
                                margin-top: 0;
                                .select2-search-choice-close{
                                    width: 20px;
                                    height: 10px;
                                    position: unset;
                                }
                                .select2-selection__choice__remove{
                                    color: $white !important;
                                }
                            }
                        }
                    }
                    .select2-selection{
                        &:after{
                            content: '\f078';
                            font-family: "Font Awesome 5 Free";
                            color: #e25b45;
                            font-size: 1rem;
                            font-weight: 600;
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            @include translateY(-50%);
                        }
                    }
                }
                input.form-control.same-select{
                    @media screen and (min-width: $break-extra-large) {
                        margin: 0 auto 0 0 !important;
                        display:block!important;
                        width: 100% !important;
                        max-width: 100%;
                    }
                }
                button.btn-cta, a.btn-cta{
                    width:100%;
                    margin: auto 0 auto auto;
                    max-width:320px;
                    max-height:57px;
                }
            }
            .listing{
                .the-content{
                    padding-top:1.87rem;
                    width: 100%;
                    padding-right: 0;
                    padding-left: 0;
                    margin-right: auto;
                    margin-left: auto;
                    //display: block;
                    .sugestion-presentation {
                        display: flex;
                    }
                    @media screen and (min-width: $break-large) {
                        padding-right: 0;
                        padding-left: 0;  
                    }
                    &.offre-shop{
                        .vignette{
                            &.container-cards-col{
                                @media screen and (min-width: 2234px){
                                    max-width: 25%;
                                }
                                @media screen and (min-width: 2734px){
                                    max-width: 20%;
                                }
                                @media screen and (min-width: 3134px){
                                    max-width: 16%;
                                }
                                @media screen and (min-width: 3634px){
                                    max-width: 14%;
                                }
                                @media screen and (min-width: 4134px){
                                    max-width: 12%;
                                }
                                @media screen and (min-width: 4934px){
                                    max-width: 11%;
                                }
                                @media screen and (min-width: 5534px){
                                    max-width: 10%;
                                }
                                @media screen and (min-width: 6634px){
                                    max-width: -1%;
                                }
                            }
                        }
                    }
                    .vignette{
                        max-width:300px;
                        margin: 0 auto 75px auto;
                        //@media screen and (min-width: $break-small) {
                        //    margin: 0 0 1rem 0;
                        //}
                        @media screen and (min-width: 1400px) {
                            max-width:293px;
                        }
                        @media screen and (min-width: 1430px) {
                            -ms-flex: 0 0 33.333333%;
                            flex: 0 0 33.333333%;
                            max-width: 300px;
                        }
                        @media screen and (min-width: 1650px) { 
                            max-width: 33.333333%;
                        }
                        .capsule{
                            width: 300px;
                            @media screen and (min-width: 1700px) {
                                width: 350px;
                            }
                            .link-boutique{
                                padding: 30px 20px;
                                @media screen and (min-width: $break-small) {
                                    min-height: 336px;
                                }
                                @media screen and (min-width: $break-medium) {
                                    min-height: 310px;
                                }
                            }
                            .capsule-bottoms{
                                .bottom{
                                    span{
                                        &.bookmark{
                                            width:66%;
                                            padding-right:5px;
                                            @media screen and (min-width: $break-small) {
                                                width:50%;
                                            } 
                                            @media screen and (min-width: $break-extra-large) {
                                                width:66%;
                                            }
                                            span.hide{
                                                @media screen and (min-width: $break-small) {
                                                    display:none;
                                                }
                                                @media screen and (min-width: $break-extra-large) {
                                                    display:inline;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } 
                }
                .cta{
                    margin:2rem auto 0 auto;
                    a.btn-cta{
                        @media screen and (min-width: $break-large) {
                            max-width: 330px; 
                        }
                    }
                }

            }
            .actions{
                padding-bottom: 2rem;
            }
            .infos-cat{
                padding:80px 60px;
                margin-bottom:60px;
                h2.title{
                    font-size:35px;
                    text-align: left;
                    padding-bottom:30px;
                    span{
                        color: inherit;
                        font-weight:bold;
                    }
                }
                .infos{
                    color:$grey;
                    font-size:20px;
                }
            }
        }
    }

}

/* vue d'un commerçant */
.shop{
    main{
        overflow-x:hidden;
    }

    header{
        .banniere{
            @media screen and (min-width: $break-large) {
                min-height:340px;
            }
            .capsule-titre{
                @media screen and (min-width: $break-large) {
                    .logo{
                        img{
                            max-width: 127px;
                            height: auto;
                            padding-top: 23px;
                            padding-bottom:0px;
                        }
                    }
                }
            }
        }
    }

    #top-show{
        position: relative;
        //padding-top: 77px;
        @media screen and (min-width: $break-large) {
            padding-top: 0;
        }
        .shop-forme{
            /// background: rgba(254,114,67,0.10);
            position: absolute;
            width: 120vw;
            @include radius (0% 0% 50% 50%/ 0% 0% 80px 80px);  
            height: 1227px; 
            top:0;
            left:-10vw;//pour IE
            @media screen and (min-width: $break-medium) {
                height: 1136px; 
                @include radius (0% 0% 50% 50% /0% 0% 140px 140px);  
            }
            @media screen and (min-width: $break-large) {
                height: 740px; 
                top: -65px;///car dès le départ sous la barre de navigation 
            }
        }
        .breadcrumb{
            background:none;
            padding:30px 0;
            @include justifyContent (space-between);
            @include alignItems (center);
            margin-bottom:0;
            .btn-back{
                font-size:0.75rem;
                font-weight:500;
                color:$deepRed;
                span{
                    text-decoration:underline;
                }
                &:before{
                    content:'<';
                    text-decoration:none;
                    padding-right: 0.3rem;
                }
            }
            .fil{
                font-size:0.75rem; 
                text-transform:capitalize;
                padding:0.3rem 15px;
                @media screen and (min-width: $break-large) {
                    padding:0 15px;
                }
                span:not(:last-child){
                    font-weight:regular;
                    &:after{
                        content:'|';
                        padding: 0 0.2rem;
                    }
                }
                span:last-child{
                    font-weight:bold;
                }
            }
        }
        .article{
            &.main-img{
                margin-bottom: 50px;
                padding: 0;
                text-align: center;
            }
            background:$white;
            @include radius(12px);
            padding:30px;
            #shop-carousel{
                .carousel-item{
                    img{
                        min-width:100%;
                        @include radius(12px);
                    }
                }
            }
            .share{
                @include justifyContent (space-between);
                @include alignItems (center);
                padding:20px 0;
                .bookmark{
                    font-size:0.68rem;
                    .like{
                        cursor:pointer;
                    }
                }
                .socials{
                    font-size:0.68rem;

                    @media screen and (min-width: $break-small) {
                        text-align:right;
                    }
                    span{
                        padding-right:0.8rem;
                    }
                    a{
                        font-size:1rem;
                    }
                    a:not(:last-child){

                        padding:0 0.5rem 0 0;
                    }
                }
            }
            .contenu-article{
                .menu-content{
                    @include justifyContent (flex-start);
                    @include alignItems (center);
                    padding:12px 0;
                    @include radius(12px);
                    margin-left:0;
                    margin-right:0;
                    .item{
                        cursor:pointer;
                        color:$grey;
                        font-size:0.87rem;
                        font-weight:300;
                        text-transform: capitalize;
                        text-align:center;
                        padding:0.3rem 0;
                        @media screen and (min-width: $break-small) {
                            padding: 0 15px;
                        }
                        &.active{
                            font-weight:bold;
                            color:$deepRed;
                            text-decoration:underline;
                            a.anchor{
                                font-weight:bold;
                                color:$deepRed;
                                text-decoration:underline;
                            }
                        }
                        &.first-child{
                            @media screen and (min-width: $break-large) {
                                text-align:right;
                            }
                        }
                        &:last-child{
                            @media screen and (min-width: $break-large) {
                                text-align:center;
                            }
                        }
                        a.anchor{
                            color:$grey;
                            font-size:0.87rem;
                            font-weight:300;
                            text-transform: capitalize;
                            text-align:center;
                            padding:0.3rem 0;
                            @media screen and (min-width: $break-small) {
                                padding: 0 15px;
                            }
                        }
                    }
                }
                .all-infos{
                    margin-left:0;
                    margin-right:0;
                    padding-top:20px;
                    .info-content{
                        display: none;
                        font-size:0.87rem;
                        font-weight:normal;
                        //min-height:450px;
                        .keyword{
                            padding-top:30px;
                        }
                        &.active{
                            display:block;
                        }
                    }
                    .cta{
                        margin-top: 30px;
                    }
                }
            }
        }
        aside{
            padding-top: 84px;
            // .container-group-img{
            //     display: flex;
            //     justify-content: space-between;
            //     padding-bottom: 50px;
            //     .container-img{
            //         width: 160px;
            //         height: 156px;
            //         img{
            //             object-fit: cover;
            //             width: 100%;
            //             height: 100%;
            //             border-radius: 12px;
            //         }
            //     }
            // }
            .about-shop{
                .vignette{
                    margin-bottom: 75px;//les 30 + les 44 de débord du bas de la vingette
                    max-width:350px;
                    margin: 0 auto;
                    .capsule{
                        @include radius(12px);
                        padding: 20px;
                        position:relative;
                        //min-height: 370px;
                        min-height: 360px;
                        margin-bottom: 60px;
                        box-shadow: 0 2px 2px 0 rgba(250, 185, 129, 0.2);
                        @media screen and (min-width: $break-medium) {
                            min-height: 300px;
                        }
                        @media screen and (min-width: $break-large) {
                            min-height: 340px;
                        }
                        @media screen and (min-width: $break-extra-large) {
                            min-height: 360px;
                        }
                        .top-infos{
                            height: unset;
                            color:$white;
                            text-align:center;
                            .city{
                                font-weight:300;
                                font-size:18px;
                                padding-bottom:5px;
                            }
                            .place{
                                font-weight:bold;
                                font-size:26px;
                                //padding-bottom:30px;
                            }
                        }
                        .capsule-bottoms{

                            @include radius(12px);
                            // box-shadow: 0 0 2px 2px rgba(115,200,189,0.10);
                            background: #fff;
                            .bottom-infos{
                                background:$white;
                                padding: 25px 18px;
                                @include radius(12px 12px 0 0);
                                box-shadow: none;
                                .infos{
                                    @include flexDirection(row);
                                    @include alignItems (center);
                                    .avatar{
                                        width: 78px;
                                        height: 78px;
                                        overflow: hidden;
                                        @include radius (50%);
                                        margin: 0 auto;
                                        @media screen and (min-width: $break-medium) {
                                            margin: 0;
                                        }
                                    }
                                    .name{
                                        text-align: center;
                                        span{
                                            font-size: 17.5px;
                                            font-weight: 500;
                                            &.uppercase{
                                                text-transform: uppercase;
                                            }
                                        }
                                    }
                                    p.adress,
                                    p.city{
                                        font-size:13px;
                                        font-weight:normal;
                                        text-align:center;
                                        margin:0;
                                        color: #4a4a4a;
                                    }
                                    p.adress{
                                        font-weight: 500;
                                    }
                                    p.city{
                                        text-transform: uppercase;
                                    }
                                }
                            }
                            .bottom{
                                left: 0;
                                bottom: -12%;
                                width: 100%;
                                height: 45px;
                                padding:10px;
                                @include radius(0 0 12px 12px);
                                @include flex(row, centre, space-between);
                                @media screen and (min-width: $break-large) {
                                    font-size:1rem;
                                    padding:10px 15px;
                                }
                                span{
                                    font-size:0.8rem;
                                    color:$grey;
                                    display:inline-block;
                                    margin: 0 auto;
                                    display:none;
                                    @media screen and (min-width: $break-super-large) {
                                        display:inline;
                                    }
                                }
                                button{
                                    padding-right: 0;
                                    padding-left: 5px;
                                    font-size:0.8rem;
                                    color:$deepRed;
                                    display:inline-block;
                                    background: none;
                                    border: none;
                                    -webkit-appearance: none;
                                    &.mail{
                                        a{
                                            color:$deepRed;
                                        }
                                        &:before{
                                            content:'\f2b6';
                                            font-family: "Font Awesome 5 Free";
                                            font-weight: 900;
                                            color:$deepRed;
                                            padding-right: 5px;
                                        }
                                        &:after{
                                            content:'|';
                                            color:$deepRed;
                                            margin: 0 0.3rem 0 2.1rem;
                                            @media screen and (min-width: 375px) {
                                                margin: 0 0.3rem 0 2.7rem;
                                            }
                                            @media screen and (min-width: 410px) {
                                                margin: 0 0.3rem 0 4.1rem;
                                            }
                                            @media screen and (min-width: $break-large) {
                                                margin: 0 0.3rem 0 2.6rem;
                                            }
                                            @media screen and (min-width: $break-extra-large) {
                                                margin: 0 0.3rem 0 4.9rem;
                                            }
                                            @media screen and (min-width: $break-super-large) {
                                                margin: 0 0 0 0.2rem;
                                            }
                                        }
                                    }
                                    &.phone{
                                        a{
                                            color:$deepRed;
                                        }
                                        &:before{
                                            content:'\f095';
                                            font-family: "Font Awesome 5 Free";
                                            font-weight: 900;
                                            color:$deepRed;
                                            @include rotate(95deg);
                                            display: inline-block;
                                            padding-right: 4px;
                                        }
                                    }
                                }
                            }
                            p.website{
                                bottom: 0%;
                                left: 50%;
                                width: 100%;
                                text-align: center;
                                background: #fff;
                                &:before{
                                    content:'\f7a2';
                                    font-family: "Font Awesome 5 Free";
                                    font-weight: 900;
                                }
                                a{
                                    font-size: 14px;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                } 
            }
            .follow-shop,
            .products-shop{
                background:$white;
                padding:30px;
                @include radius(12px);
                .title{
                    font-size:1.6rem;
                    color:$lightBlue;
                    font-weight:900;
                    span{
                        font-weight:normal;
                        color:$grey;
                    }
                }
                ul{
                    padding-top:30px;
                    margin-bottom:0;
                    li{
                        padding-bottom:5px;
                        font-size:1rem;
                        color:$grey;
                        font-weight:normal;
                        a{
                            font-size:1rem;
                            font-weight:$grey;
                            font-weight:normal;
                        }
                        span{
                            padding-left:14px;
                        }
                    }
                }
            }
            .follow-shop{
                margin-top:115px;
                @media screen and (min-width: $break-large) {
                    margin-top:110px;
                }

            }
            .products-shop{
                margin-top:110px;
            }
        }
        .capsule-galet-1{
            position:relative;
            .galet{
                background: #4ABBE2;
                position: absolute;
                opacity:0.05;
                top:-4px;
                left: -680px;
                top: -1140px;
                @include rotate(1deg);
                z-index:-1;
                @media screen and (min-width: $break-large) {
                    width: 594px;
                    left: -1085px;
                    top: -468px;
                    @include rotate(-97deg);
                }
                .animated{
                    opacity:0;
                }
            }

        }
    }
    #bottom-show{
        padding-top:100px;

        .capsule-galet-2{
            position:relative;
            overflow-x:hidden;
            .galet3{
                background: #E25B45;
                position: absolute;
                opacity:0.05;
                right:-150px;
                top:-4px;
                width: 300px;
                height: 333px;
                @include rotate(152deg);
                @media screen and (min-width: $break-large) {
                    width: 594px;
                    height: 666px;
                    right:-405px;
                    top:9px; 
                    @include rotate(141deg);
                }
                @media screen and (min-width: $break-extra-large) {

                    right:-390px;
                    top:-21px; 
                    @include rotate(111deg);
                }
            }
        }
        .titles{
            padding-bottom:60px;
        }
        .offre-shop{
            .vignette-annonce{
                padding-left: 0;
                padding-right: 0;
                .capsule{
                    margin: 0 auto 30px;
                    //padding:20px;
                    @media screen and (min-width: $break-extra-large) {
                        margin: 0 5px 30px;
                    }
                    .infos{
                        .offer{
                            font-size:0.9rem;
                        }
                    }
                }
            }
        }
        .cta{
            margin:60px auto 80px auto;
            .btn-cta{
                max-width:360px;
            } 
        }
    }
}