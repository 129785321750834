/*-------------------------------------------------------
 ------------------->>> BLOG <<<----------------------
=> la vue index + vue show
 -------------------------------------------------------  */
/* vue deu listing d'articles'*/
.blog-listing {
    // header {
    //     .banniere {
    //         background-image: url('#{$imgPath}banniere/banniere-header-blog.jpg');
    //         @media screen and (min-width: $break-super-large) {
    //             min-height: 415px;
    //         }

    //         .capsule-titre {
    //             .title{
    //                 display:none;
    //             }
    //             .logo {
    //                 img {
    //                     max-width: 185px;
    //                     height: auto;
    //                 }
    //             }
    //             h1{
    //                 padding-top:2rem;
    //                 font-size:20px;
    //                 margin: 0 auto;
    //                 @media screen and (min-width: $break-large) {
    //                     font-size:1.25rem;
    //                     max-width:554px;
    //                 }
    //                 @media screen and (min-width: $break-super-large) {
    //                     max-width:730px;
    //                 }
    //                 span.highlighted{
    //                     @media screen and (min-width: $break-medium) {
    //                         display:inline;
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     nav.navbar {
    //         ul.menu {
    //             li.nav-item.dropdown {
    //                 &:before {
    //                     display: none !important;
    //                 }
    //             }
    //         }
    //     }
    // }

    main {
        overflow: hidden;
        padding-top:77px;
        @media screen and (min-width: $break-large) {
            padding-top: 0;
        }
    }

    #highlighted-post {
        margin-bottom: 96px;

        .capsule-galet {
            position: relative;

            .galet2 {
                background: #4ABBE2;
                position: absolute;
                left: -314px;
                @include rotate(98deg);
                top: -118px;
                opacity: 0.05;
                @media screen and (min-width: $break-large) {
                    top: -157px;
                }
            }
        }

        .titles {
            padding-top: 5rem;
            padding-bottom: 3.75rem;
        }

        .the-post {
            .the-content {
                @media screen and (min-width: $break-large) {
                    min-height: 490px;
                    padding: 0 15px;
                    max-width: 960px;
                    width: 100%;
                    padding-right: 15px;
                    padding-left: 15px;
                    margin-right: auto;
                    margin-left: auto;
                }
                @media screen and (min-width: $break-extra-large) {
                    min-height: 480px;
                    max-width: 1140px;
                }

                .post {
                    @media screen and (min-width: $break-large) {
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        margin-right: -15px;
                        margin-left: -15px;
                    }
                    position: relative;

                    .post-pict {
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center center;
                        min-height: 205px;
                        padding: 0;
                        @media screen and (min-width: $break-large) {
                            @include radius(42px);
                            padding: 0 15px;
                            min-height: 419px;
                        }

                        img {
                            @media screen and (min-width: $break-large) {
                                display: none;
                            }
                        }
                    }

                    .post-txt {
                        @include flex (column, null, space-between);
                        background: $white;
                        padding: 30px 20px;
                        @include radius(0px 42px 42px 42px);
                        box-shadow: 0 2px 10px 0 rgba(107, 125, 160, 0.10);
                        max-width: 95%;
                        margin: -35px auto 0 auto;
                        @media screen and (min-width: $break-medium) {
                            max-width: 80%;
                        }
                        @media screen and (min-width: $break-large) {
                            padding: 45px 65px;
                            position: absolute;
                            top: 30px;
                            right: 0;
                            min-height: 449px;
                            max-width: 450px;
                            margin: 0;
                        }

                        .infos {
                            @include flex(row, center, null);
                            .cat{
                                text-transform: capitalize;
                                color:$blogColor;
                                font-weight:900;
                                font-size:0.75rem;
                                @media screen and (min-width: $break-large) {
                                    font-size: 1rem;
                                }
                            }
                            .date{
                                padding-left:5px;
                                color:$grey;
                                font-weight:300;
                                font-size:0.75rem;
                                @media screen and (min-width: $break-large) {
                                    font-size: 1rem;
                                }
                            }

                        }

                        .title {
                            color: $blogColor;
                            font-weight: bold;
                            font-size: 1.12rem;
                            padding-top: 1.25rem;
                            padding-bottom: 0.62rem;
                            @media screen and (min-width: $break-large) {
                                font-size: 2.18rem;
                                padding-bottom: 1.25rem;
                            }
                        }
                        .excerpt {
                            font-size: 0.87rem;
                            color: $grey;
                            @media screen and (min-width: $break-large) {
                                font-size:1.2rem;
                            }
                        }

                        a.read {
                            padding-top: 50px;
                            font-weight: 900;
                            font-size: 0.68rem;
                            color: $blogColor;
                            text-decoration: underline;
                            @media screen and (min-width: $break-large) {
                                text-transform: uppercase;
                                font-size: 1.12rem;
                            }
                        }
                    }
                }


            }
        }

    }

    #all-post {
        .capsule-all-post {
            position: relative;
            .special-capsule {
                @include flex(column, center, flex-start);
                overflow:hidden;
                min-height:2300px;
                position:absolute;
                z-index: -1;
                top:49px;
                left:0;
                right:0;
                @media screen and (min-width: $break-medium) {
                    min-height:1250px; 
                }
                @media screen and (min-width: $break-large) {
                    min-height:1350px; 
                }
                .all-post-forme{
                    position:absolute;
                    top:0;
                    left:-10vw; ///POUR IE 
                    width:120vw;
                    @include radius (0% 0% 50% 50%/ 0% 0% 80px 80px);  
                    background: rgba(75,187, 244,0.10);
                    height:2300px;  
                    @media screen and (min-width: $break-medium) {
                        height:1200px; 
                    }
                    @media screen and (min-width: $break-large) {
                        min-height:1290px; 
                        @include radius(0% 0% 50% 50% /0% 0% 140px 140px);  
                    }
                }
            }
            .buttons-container{
                position:relative;
                box-shadow: 0 2px 10px 0 rgba(107,125,160,0.10);
                width: 97%;
                margin: 0 auto;
                @include radius(12px);
                background: white;
                min-height: 190px;
                padding: 0.5rem 1rem;
                @include flex(column, null, null);
                @media screen and (min-width: $break-small) {
                    min-height: 110px;
                }
                @media screen and (min-width: $break-large) {
                    @include flex(row, center, null);
                    min-height: 84px;
                }

                .button-group {
                    @include flex(column, null, null);
                    @media screen and (min-width: $break-small) {
                        @include flex(row, center, space-between);
                    }
                }

                button {
                    border: 0;
                    background: 0;
                    padding: 0 15px;
                    cursor: pointer;
                    @media screen and (min-width: $break-medium) {
                        padding: 0 38px;
                    }
                    @media screen and (min-width: $break-large) {
                        padding: 0 30px;
                    }
                    @media screen and (min-width: $break-super-large) {
                        padding: 0 50px;
                    }

                    &.is-checked {
                        color: $lightBlue;
                        text-decoration: underline;
                    }
                }

                .search-form {
                    border: 0;
                    margin: auto auto 0 auto;
                    position: relative;
                    text-align: center;
                    width: 100%;
                    max-width: 320px;
                    padding: 0;

                    @media screen and (min-width: $break-large) {
                        max-width: inherit;
                        width: 315px;
                        margin: 0 0 0 auto;
                    }

                    .search {
                        position: relative;

                        input {
                            color: $grey;
                            border: 1px solid rgba(74, 74, 74, 0.11);
                            font-size: 0.87rem;
                            @include radius(100px);
                            vertical-align: middle;
                            padding: 13px 48px 13px 15px;
                            text-align: center;
                            display: block;
                            width: 100%;
                            @media screen and (min-width: $break-large) {
                                margin: 0 0 0 auto;
                                width: 89%;
                            }

                            &::-webkit-input-placeholder {
                                color: rgba(74, 74, 74, 0.31);
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }

                            &:-moz-placeholder {
                                color: rgba(74, 74, 74, 0.31);
                                opacity: 1;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }

                            &::-moz-placeholder {
                                color: rgba(74, 74, 74, 0.31);
                                opacity: 1;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }

                            &:-ms-input-placeholder {
                                color: rgba(74, 74, 74, 0.31);
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }
                        }
                    }

                    .action {
                        ///z-index:999;
                        background: $deepRed;
                        @include radius(100px);
                        position: absolute;
                        top: -15px;
                        right: 0;
                        width: 48px;
                        height: 48px;
                        @media screen and (min-width: $break-large) {
                            top: 50%;
                            right: 17px;
                            @include translate(37%, -50%);
                        }

                        button {
                            color: $white;
                            font-weight: bold;
                            text-align: center;
                            font-size: 1rem;
                            padding: 11px 0;
                            background: none;
                            width: 48px;
                            height: 48px;
                        }
                    }
                }

            }
            .container-posts {
                .the-content {
                    padding-top: 30px;
                    .posts {
                        @media screen and (min-width: $break-large) {
                            @include alignSelf (stretch);
                        }
                        .grid {
                            .element-item {
                                width: 100%;
                                .content-item {
                                    margin-bottom: 30px;
                                    .pict {
                                        @media screen and (min-width: $break-medium) {
                                            padding-right: 0;
                                            height: 221px;
                                            overflow: hidden;
                                            @include alignSelf(stretch);
                                            min-width: 319px; //POUR IE
                                        }
                                        @media screen and (min-width: $break-extra-large) {
                                            min-width: 380px; //POUR IE
                                        }
                                        img {
                                            @include radius(12px 12px 0 0);
                                            min-width: 100%;
                                            @media screen and (min-width: $break-medium) {
                                                @include radius(12px 0 0 12px);
                                                height: 100%;
                                                //width: 100%;
                                            }
                                        }
                                    }
                                    .infos {
                                        @media screen and (min-width: $break-medium) {
                                            padding-left:0;
                                            padding-bottom:0;
                                        }
                                        .text {
                                            padding: 30px 15px;
                                            background: $white;
                                            @include flex(column, null, null);
                                            @include radius(0 0 12px 12px);
                                            // border-left:0 !important;
                                            @media screen and (min-width: $break-medium) {
                                                @include flex(column, null, null);
                                                height: 221px;
                                                @include radius(0 12px 12px 0);
                                                @include alignSelf(stretch);
                                                min-width: 320px; //POUR IE
                                                ///  border-left:inherit !important;
                                            }
                                            @media screen and (min-width: $break-large) {
                                                min-width: 295px; //POUR IE
                                            }

                                            .about {
                                                font-weight: 900;
                                                font-size: 0.87rem;
                                                @include flex(row, center, null);
                                                .cat{
                                                    text-transform: capitalize;
                                                }
                                                .date{
                                                    padding-left:5px;
                                                    color:$grey;
                                                    font-weight:300;
                                                }
                                            }

                                            .title {
                                                color: $grey;
                                                font-size: 1rem;
                                                padding: 5px 0 10px 0;
                                                @media screen and (min-width: $break-large) {
                                                    padding-top: 10px;
                                                    padding-bottom: 35px;
                                                }
                                                @media screen and (min-width: $break-extra-large) {
                                                    font-size:1.2rem;
                                                }
                                            }
                                            a.read{
                                                font-weight:900;
                                                font-size:0.87rem;
                                                text-transform:uppercase;
                                                @media screen and (min-width: $break-medium) {
                                                    margin-top: auto;
                                                }
                                                &:hover{
                                                    text-decoration:underline;
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                    .annonce {
                        @media screen and (min-width: $break-large) {
                            @include alignSelf (stretch);
                            //padding-bottom:62px;
                            min-height:1004px;
                        }
                        a.link-pub{
                            min-height: 705px;
                            .capsule {
                                @media screen and (min-width: $break-large) {
                                    min-height: 705px;
                                }
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center center;
                                @include radius(0);

                            }
                        }
                    }
                }
                .cta {
                    @media screen and (min-width: $break-large) {
                        margin-top: 1.87rem;
                    }

                    a.btn-cta {
                        max-width: 330px;
                    }
                    .btn-cta {
                        @media screen and (min-width: $break-large) {
                            max-width: 367px;
                            padding:14px 66px;
                        }
                    }
                }
            }
        }
    }

    #agendas {
        margin-top: 6rem;
        @media screen and (min-width: $break-large) {
            margin-top: 0;
        }

        .capsule-galet {
            position: relative;

            .galet {
                background: $deepRed;
                position: absolute;
                left: -314px;
                top: -118px;
                opacity: 0.05;
                @media screen and (min-width: $break-large) {
                    top: -390px;
                }
            }
        }

        @media screen and (min-width: $break-large) {
            margin-top: 9.5rem;
        }

        h2 {
            padding-bottom: 3.75rem;

            span {
                color: $agendaColor;

            }
        }
        .container-carousel{
            position:relative;
            .owl-carousel{
                .item{
                    .pict{
                        height: 353px;
                        background-size:cover;
                        background-repeat:no-repeat;
                        background-position:center center;
                        @include radius(12px 12px 0 0);
                    }
                    .text{
                        position:relative;
                        background:$agendaColor;
                        color:$white;
                        padding:30px;
                        @include radius( 0 0 12px 12px);
                        @media screen and (min-width: $break-large) {
                            min-height:210px;
                        }
                        .dates{
                            font-weight:900;
                            font-size:0.7rem;
                            padding-bottom:0.5rem;
                            @media screen and (min-width: $break-large) {
                                font-size:0.87rem;
                            }
                        }
                        .title{
                            font-size:1rem;
                            padding:0.5rem 0;
                            min-height:64px;
                            @media screen and (min-width: $break-medium) {
                                min-height:64px;
                            }
                            @media screen and (min-width: $break-large) {
                                padding-top:10px;
                                padding-bottom:35px;
                                min-height:93px;
                            }
                            @media screen and (min-width: $break-extra-large) {
                                min-height:156px;
                                font-size:1.5rem;
                            }
                        }
                        a.read{
                            color:$white;
                            font-weight:900;
                            font-size:0.87rem;
                            text-decoration:underline;
                            text-transform:uppercase;
                        }
                        .like{
                            position:absolute;
                            top: -35px;
                            right: 30px;
                            cursor:pointer;
                            .capsule-like{
                                width:52px;
                                height:52px;
                                @include radius(50%);
                                background:$white;
                                span{
                                    color:$agendaColor;
                                    display: block;
                                    font-size:1.5rem;
                                    line-height: 52px;
                                    text-align: center;
                                    i{
                                        vertical-align:middle;
                                    }
                                }
                            }
                        }
                    }
                }
                .owl-nav{
                    button.owl-prev{
                        position:absolute;
                        top:50%;
                        left:-37px;
                        background-image:url('#{$imgPath}/pictos/left-arrow-green.png');
                        width:40px;
                        height:40px;
                        background-repeat:no-repeat;
                        background-position:center center;
                        @media screen and (min-width: $break-super-large) {
                            right:-89px;
                        } 
                    }
                    button.owl-next{
                        position:absolute;
                        top:50%;
                        right:-37px;
                        background-image:url('#{$imgPath}/pictos/right-arrow-green.png');
                        width:40px;
                        height:40px;
                        background-repeat:no-repeat;
                        background-position:center center;
                        @media screen and (min-width: $break-super-large) {
                            right:-109px;
                        } 
                    }
                }
                .owl-dots{
                    text-align:center;
                    padding-top:40px;
                    button {
                        background:$agendaColor;
                        width:14px;
                        height:14px;
                        @include radius(50%);
                        opacity:0.6;
                        margin:0 6px;
                        &.active{
                            opacity:1;
                        }
                    }
                }

            }
            >.capsule-nav{
                bottom: 85px;
                position: absolute;
                left: 0;
                right: 0;
            }
            .cta{
                margin-top:60px;
                margin-bottom:5rem;
                .btn-cta {
                    @media screen and (min-width: $break-large) {
                        max-width: 367px;
                        padding:14px 66px;
                    }
                }
            }
        }
    }

    #instagram {
        .capsule-galet {
            position: relative;

            .galet {
                background: $lightBlue;
                position: absolute;
                left: unset;
                right: -322px;
                top: -118px;
                opacity: 0.05;
                @include rotate(125deg);
                @media screen and (min-width: $break-large) {
                    top: -390px;
                }
            }
        }

        .titles {
            .accroche {
                font-size: 1.25rem;
                text-align: center;
                margin: 0 auto;

                span {
                    color: $lightBlue;
                    font-weight: 900;
                }
            }
        }

        .flux {
            // padding-left: 0;
            //  padding-right: 0;
            .the-content {
                @include flexDirection(row);
                @include alignItems(center);
                @include justifyContent (flex-start);
                padding-top: 2.5rem;
                padding-bottom: 5rem;

                .square {
                    background-repeat: no-repeat;
                    -moz-background-size: cover;
                    -webkit-background-size: cover;
                    -o-background-size: cover;
                    background-size: cover;
                    background-position: center;
                    //z-index: 99;
                    padding: 0;
                    height: auto;
                    cursor: pointer;
                    display: flex;

                    &:before {
                        content: '';
                        display: inline-block;
                        padding-top: 100%;
                        padding: 50% 0;
                        vertical-align: middle;
                    }

                    a {
                        height: auto;
                        width: 100%;
                        color:$lightBlue;
                        p{
                            opacity:0;
                            width: 100%;
                            height: 100%;
                            position: relative;
                        }
                    }

                    &.hover {
                        box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.7);

                        p {
                            opacity: 1;
                            width: 100%;
                            @include flex(row, center, center);

                            a {
                                color: $lightBlue;
                                @media screen and (min-width: $break-super-large) {
                                    font-size: 30px;
                                    padding: 2rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #annonce {
        img {
            border: 1px solid $grey; //a suppr après (juste pour voir la taille car fond blanc))
        }
    }

    #about {
        margin-top: -23px;

        .capsule-about {
            @include flex(column, center, center);
            min-height: 680px;
            overflow: hidden;
            position: relative;

            .galet {
                display: none;
                @media screen and (min-width: $break-extra-large) {
                    display: block;
                    position: absolute;
                    top: -25px;
                    left: -21px;
                    opacity: 1;
                    width: 1138px;
                    height: 1015px;
                    @include radius (523px 818px 693px 814px /367px 554px 744px 968px);
                }
                @media screen and (min-width: $break-super-large) {
                    left: 75px;
                }

            }

            .about-forme {
                position: absolute;
                top: 0;
                left: -10vw; // IE
                width: 120vw;
                @include radius (50% 50% 0 0 /80px 80px 0 0);
                background: $blocPro;
                height: 1160px;
                @media screen and (min-width: $break-large) {
                    @include radius (50% 50% 0 0 /140px 140px 0 0);
                }
            }
        }

        .the-content {
            padding-top: 3.5rem;
            position: relative;
            @media screen and (min-width: $break-large) {
                padding-top: 7.18rem;
            }

            .about {
                @include flex(column, null, space-between);
                @include alignSelf (stretch);

                h2 {
                    text-align: center;
                    margin-bottom: 0;
                    font-size: 1.5rem;;
                    @media screen and (min-width: $break-large) {
                        font-size: 2.18rem;;
                        text-align: left;
                    }
                }

                .first {
                    padding-top: 1.25rem;
                    padding-bottom: 1.25rem;
                }

                .first,
                .second {
                    color: $grey;
                    font-size: 0.87rem;
                    text-align: center;
                    @media screen and (min-width: $break-large) {
                        text-align: left;
                        font-size: 1.25rem;
                    }
                }

                a {
                    color: lightblue;
                }

                .partenaires {
                    padding-top: 1.87rem;
                    padding-bottom: 1.87rem;
                    @include alignItems (flex-end);
                    @include justifyContent (space-between);
                    @media screen and (min-width: $break-large) {
                        padding-top: 1.25rem;
                        padding-bottom: 0;
                    }
                }

            }

            .pict {
                padding-bottom: 1.87rem;
                @media screen and (min-width: $break-large) {
                    @include alignSelf (stretch);
                    padding-bottom: 0;
                }
                @media screen and (min-width: $break-super-large) {
                    position: absolute;
                    right: -40px;
                    width: 677px;
                    height: 400px;
                    overflow: hidden;

                }
            }
        }

        .cta {
            padding-bottom: 1.87rem;
            z-index: 99;
            position: relative;
            @media screen and (min-width: $break-large) {
                margin-top: 5.87rem;
            }
            .btn-cta {
                @media screen and (min-width: $break-large) {
                    max-width: 367px;
                    padding:14px 66px;
                }
            }
        }
    }

}

/* vue d'une annonce*/
.blog-show {
    //pas le main en overflow pour la forme sous le menu de nazv
    overflow-x: hidden;

    //header {
    //    .banniere {
    //        background-image: url('#{$imgPath}banniere/banniere-header-blog.jpg');
    //        @media screen and (min-width: $break-super-large) {
    //            min-height: 415px;
    //        }
    //
    //        .capsule-titre {
    //            .title{
    //                display:none;
    //            }
    //            .logo {
    //                img {
    //                    max-width: 185px;
    //                    height: auto;
    //                    padding-top: 23px;
    //                }
    //            }
    //            h1{
    //                padding-top:2rem;
    //                font-size:20px;
    //                max-width:730px;
    //                margin: 0 auto;
    //                @media screen and (min-width: $break-large) {
    //                    font-size:1.25rem;
    //                }
    //                span.highlighted{
    //                    @media screen and (min-width: $break-medium) {
    //                        display:inline;
    //                    }
    //                }
    //            }
    //        }
    //    }
    //
    //    nav.navbar {
    //        ul.menu {
    //            li.nav-item.dropdown {
    //                &:before {
    //                    display: none !important;
    //                }
    //            }
    //        }
    //    }
    //}
    main{
        overflow-x:hidden;
    }
    #top-article {
        overflow-x:hidden;
       // padding-top: 77px;
        position: relative;
        padding-bottom: 100px;
        @media screen and (min-width: $break-large) {
            padding-top: 0;
        }
        .article-forme{
            background: rgba(75, 187, 224, 0.1);
            position: absolute;
            top: 0;
            left: -10vw; //POUR IE
            width: 120vw;
            @include radius (0% 0% 50% 50%/ 0% 0% 80px 80px);  
            height: 3180px; 
            @media screen and (min-width: $break-medium) {
                height: 1350px;
                @include radius (0% 0% 50% 50% /0% 0% 140px 140px);
            }
            @media screen and (min-width: $break-large) {
                top: -65px; ///car dès le départ sous la barre de navigation
                height: 1185px;
            }
        }

        .breadcrumb {
            background: none;
            padding: 30px 0;
            @include justifyContent (space-between);
            @include alignItems (center);
            margin-bottom:0;

            .fil{
                font-size:0.75rem; 
                text-transform:capitalize;
                padding:0.3rem 15px;
                @media screen and (min-width: $break-large) {
                    text-align: right;
                    padding: 0 15px;
                }

                span:not(:last-child) {
                    font-weight: regular;

                    &:after {
                        content: '|';
                        padding: 0 0.2rem;
                    }
                }

                span:last-child {
                    font-weight: bold;
                }
            }
        }

        .article {
            overflow: hidden;
            background: $white;
            @include radius(12px);
            .pict-title{
                position:relative;
                .pict{
                    height:341px;
                    background-size:cover;
                    background-repeat:no-repeat;
                    background-position:center center;
                }
                .capsule-h1{
                    position:relative;
                    &:before{
                        content:'';
                        padding-right:27px;
                        position: absolute;
                        bottom: 84px;
                        left: 21px;
                        min-height: 70px;
                    }

                }
                h1{
                    position:absolute;
                    bottom:35px;
                    left:35px;
                    color:$white;
                    font-weight:500;
                    font-size:32px;
                    max-width:480px;

                }

            }

            .the-content {
                padding: 30px;
                box-shadow: 0 2px 14px 0 rgba(107, 125, 160, 0.10);
                @include radius(0 0 12px 12px);
                .about{
                    .cat{
                        text-transform:capitalize;
                        font-size:0.87rem;
                        font-weight:900;
                    }
                    .date{
                        padding-left:5px;
                        font-size:0.87rem;
                        font-weight:500;
                    }
                    .socials{
                        font-size:0.68rem;

                        @media screen and (min-width: $break-large) {
                            text-align:right;
                        }

                        span {
                            padding-right: 0.8rem;
                        }

                        a {
                            font-size: 1rem;
                        }

                        a:not(:last-child) {
                            padding: 0 0.2rem 0 0;
                        }
                    }
                }
                .post{
                    padding-top:30px;
                    padding-bottom:20px;
                    @media screen and (min-width: $break-large) {
                        padding-bottom:65px;
                    }
                    font-size:0.87rem;
                    .chapeau{
                        font-size:1.12rem;
                        padding-bottom: 25px;
                        display: block;
                    }

                }  
                .end{
                    .btn-back{
                        font-size:0.75rem;
                        font-weight:500;
                        span{
                            text-decoration:underline;
                        }
                        &:before{
                            content:'<';
                            text-decoration:none;
                            padding-right: 0.3rem;
                        }
                    }
                    .socials{
                        text-align:left;
                        font-size:0.68rem;
                        span{
                            padding-right:0.8rem;
                        }

                        a {
                            font-size: 1rem;
                        }

                        a:not(:last-child) {
                            padding: 0 0.2rem 0 0;
                        }
                    }
                }
            }
        }

        .auteur {
            background: $white;
            @include radius(12px);
            margin-top: 30px;
            background: #E25B45;
            color: $white;
            padding: 30px;
            .avatar {
                width: 168px;
                height: 168px;
                overflow: hidden;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                margin: 0 auto;
            }
            .about {
                .name {
                    font-size: 1.6rem;

                    span {
                        font-weight: 900;
                    }
                }
                .testimonial {
                    padding: 10px 0 30px 0;
                    font-size: 0.87rem;
                }
                .know-more {
                    a{
                        color: $white;
                    }
                }
                .social {
                    a {
                        .fab {
                            font-size: 1.4rem;
                            color: $white;
                            padding-right: 0.3rem;

                            &:hover {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        aside {
            padding-top: 78px;

            .title {
                font-size: 2rem;
                padding-bottom: 20px;
                font-weight: bold;
                color: $lightBlue;

                span {
                    color: $grey;
                    font-weight: normal;
                }
            }

            .searching {
                padding: 30px;
                @include radius(12px);
                background: $white;
                margin-bottom: 60px;

                .search-form {
                    border: 0;
                    margin: 0 auto 30px auto;
                    position: relative;
                    text-align: center;
                    max-width: 320px;
                    padding: 0;
                    @media screen and (min-width: $break-super-large) {
                        max-width: inherit;
                    }

                    .search {
                        input {
                            color: $grey;
                            font-size: 0.87rem;
                            @include radius(100px);
                            vertical-align: middle;
                            padding: 13px 0 13px 30px;
                            border: 1px solid rgba(74, 74, 74, 0.11);
                            height: 52px;
                            width: 100%;
                            @media screen and (min-width: $break-large) {
                                padding: 13px 0 13px 50px;
                            }

                            &::-webkit-input-placeholder {
                                color: $grey;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }

                            &:-moz-placeholder {
                                color: $grey;
                                opacity: 1;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }

                            &::-moz-placeholder {
                                color: $grey;
                                opacity: 1;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }

                            &:-ms-input-placeholder {
                                color: $grey;
                                font-size: 0.87rem;
                                vertical-align: middle;
                            }
                        }
                    }

                    .action {
                        background: $deepRed;
                        @include radius(100px);
                        // position: absolute;
                        top: 0;
                        right: 0;
                        width: 52px;
                        height: 52px;
                        @media screen and (min-width: $break-super-large) {
                            // top: 50%;
                            left: inherit;
                            right: 0;
                            // @include translate(0%, -50%);
                        }

                        button {
                            color: $white;
                            font-weight: bold;
                            text-align: center;
                            font-size: 1rem;
                            padding: 11px 0;
                            background: none;
                            height: 75px;
                            width: 35px;
                            margin-right: 15px;
                            cursor: pointer;
                            border: 0;

                            span {
                                display: none;
                                @media screen and (min-width: $break-super-large) {
                                    display: inline;
                                }
                            }
                        }
                    }
                }
                ul{
                    li{
                        text-transform: capitalize;   
                    }
                    li:not(:last-child){
                        padding-bottom:20px;
                    }
                }
            }

            .insta {
                .flux-insta {
                    @include justifyContent (center);

                    .post {
                        -moz-background-size: cover;
                        -webkit-background-size: cover;
                        -o-background-size: cover;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        height: 175px;
                        max-width: 175px;
                        cursor: pointer;
                        padding: 0;

                        p {
                            opacity: 0;
                            position: relative;
                        }
                        a {
                            height: auto;
                            width: 100%;
                            color:$lightBlue;
                            p{
                                opacity:0;
                                width: 100%;
                                height: 100%;
                                position: relative;
                            }
                        }

                        &.hover {
                            box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.7);
                            a{
                                color: $lightBlue;
                            }
                            p{
                                opacity: 1;
                                width: 100%;
                                height: 100%;
                                @include flex(row, center, center);

                                a {
                                    color: $lightBlue;
                                    @media screen and (min-width: $break-super-large) {
                                        font-size: 30px;
                                        padding: 2rem;
                                    }
                                }
                            }
                        }
                    }

                }

            }
        }

        .capsule-galet-1 {
            position: relative;

            .galet {
                background: #E25B45;
                position: absolute;
                opacity: 0.05;
                top: -4px;
                left: -709px;
                top: -1140px;
                @include rotate(98deg);
                z-index: -1;
                @media screen and (min-width: $break-large) {
                    width: 594px;
                    left: -709px;
                    top: -1315px;
                    @include rotate(120deg);
                }
            }
        }
    }
    #highlighted-post{
        padding-bottom:20px;
        @media screen and (min-width: $break-large) {
            padding-bottom:60px;
        }
        .capsule-galet{
            position:relative;
            .galet{
                background:#4ABBE2;
                position: absolute;
                left: inherit;
                right: -327px;
                @include rotate(98deg);
                top: -118px;
                opacity: 0.05;
                @media screen and (min-width: $break-large) {
                    top: -316px;
                }
            }
        }
        .titles{
            padding-bottom:50px;
            @media screen and (min-width: $break-large) {
                padding-bottom:100px;
            }
        }
        .the-post{
            //padding-bottom:50px;
            .the-content{
                @media screen and (min-width: $break-large) {
                    min-height: 490px;
                    padding: 0 15px;
                    max-width: 960px;
                    width: 100%;
                    padding-right: 15px;
                    padding-left: 15px;
                    margin-right: auto;
                    margin-left: auto;
                }
                @media screen and (min-width: $break-extra-large) {
                    min-height: 480px;
                    max-width: 1140px;
                }

                .post {
                    @media screen and (min-width: $break-large) {
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        margin-right: -15px;
                        margin-left: -15px;
                    }
                    position: relative;

                    .post-pict {
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center center;
                        min-height: 205px;
                        padding: 0;
                        @media screen and (min-width: $break-large) {
                            @include radius(42px);
                            padding: 0 15px;
                            min-height: 419px;
                        }

                        img {
                            @media screen and (min-width: $break-large) {
                                display: none;
                            }
                        }
                    }

                    .post-txt {
                        @include flex (column, null, space-between);
                        background: $white;
                        padding: 30px 20px;
                        @include radius(0px 42px 42px 42px);
                        box-shadow: 0 2px 10px 0 rgba(107, 125, 160, 0.10);
                        max-width: 95%;
                        margin: -35px auto 0 auto;
                        @media screen and (min-width: $break-medium) {
                            max-width: 80%;
                        }
                        @media screen and (min-width: $break-large) {
                            padding: 45px 65px;
                            position: absolute;
                            top: 30px;
                            right: 0;
                            min-height: 449px;
                            max-width: 450px;
                            margin: 0;
                        }

                        .infos {
                            @include flex(row, center, null);
                            .cat{
                                text-transform: capitalize;
                                font-weight:900;
                                font-size:0.75rem;
                                @media screen and (min-width: $break-large) {
                                    font-size: 1rem;
                                }
                            }

                            .date {
                                padding-left: 1rem;
                                font-weight: 300;
                                font-size: 0.75rem;
                                @media screen and (min-width: $break-large) {
                                    font-size: 1rem;
                                }
                            }

                        }

                        .title {
                            font-weight: bold;
                            font-size: 1.12rem;
                            padding-top: 1.25rem;
                            padding-bottom: 0.62rem;
                            @media screen and (min-width: $break-large) {
                                font-size:1.8rem;
                                padding-bottom:1.25rem;
                            }
                        }

                        .excerpt {
                            font-size: 0.87rem;
                            color: $grey;
                            @media screen and (min-width: $break-large) {
                                font-size:1.2rem;
                            }
                        }
                        a.read{
                            padding-top:40px;
                            font-weight:900;
                            font-size:0.68rem;
                            text-decoration:underline;
                            @media screen and (min-width: $break-large) {
                                text-transform: uppercase;
                                font-size: 1.12rem;
                            }
                        }
                    }
                }


            }
        }

    }
    #bottom-article{
        // padding-top:30px;

        .capsule-other-post{
            @include flex(column, center, flex-end);
            min-height:2110px;
            overflow:hidden;
            position:relative;
            z-index:0;

            @media screen and (min-width: $break-medium) {
                min-height: 1600px;
            }
            @media screen and (min-width: $break-large) {
                @include flex(column, center, center);
                min-height:1150px;
            }
            .other-post-forme{
                position:absolute;
                bottom:0;
                left:-10vw;// pour IE
                width:120vw;
                background:$blocPro;
                @include radius (50% 50% 0 0 /80px 80px 0 0);  
                height:2085px;  

                @media screen and (min-width: $break-medium) {
                    height: 1535px;
                }
                @media screen and (min-width: $break-large) {
                    @include radius (50% 50% 0 0 /140px 140px 0 0); 
                    height:1100px;
                }
            }
        }

        .container {
            z-index: 1;
        }

        .other-post {
            .item {
                max-width: 350px;
                margin: 0 auto;
                margin-bottom:30px;
                .pict{
                    height: 353px;
                    overflow: hidden;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @include radius(12px 12px 0 0);
                }

                .text {
                    background: $white;
                    position: relative;
                    color: $grey;
                    padding: 30px;
                    @include radius(0 0 12px 12px);
                    @media screen and (min-width: $break-large) {
                        min-height: 210px;
                    }
                    .cat{
                        text-transform:capitalize;
                    }
                    .cta{
                        font-weight:900;
                        font-size:0.87rem;
                    }
                    .date{
                        padding-left:5px;
                        font-weight:300;
                        font-size:0.87rem;
                    }

                    .title {
                        font-size: 1.5rem;
                        padding: 0.5rem 0;
                        @media screen and (min-width: $break-large) {
                            min-height:160px;
                        }
                        @media screen and (min-width: $break-large) {
                            padding-top:10px;
                            padding-bottom:35px;
                            min-height:190px;
                        }
                        @media screen and (min-width: $break-extra-large) {
                            font-size:1.5rem;
                            min-height:160px;
                        }
                    }

                    a.read {
                        color: $white;
                        font-size: 0.87rem;
                        text-decoration: underline;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .cta {
            padding: 60px 0 80px 0;

            .btn-cta {
                max-width: 360px;
            }
        }
    }

    .galetAnimReverse.left {
        opacity: 0;
    }
}

//recherche des articles
.blog-search {
    main {
        padding-top:77px;
        @media screen and (min-width: $break-large) {
            padding-top: 0;
        }
    }
    // header {
    //     .banniere {
    //         background-image: url('#{$imgPath}banniere/banniere-header-blog.jpg');
    //         @media screen and (min-width: $break-super-large) {
    //             min-height: 415px;
    //         }
    //         .capsule-titre {
    //             .title{
    //                 display:none;
    //             }
    //             .logo {
    //                 img {
    //                     max-width: 185px;
    //                     height: auto;
    //                 }
    //             }
    //             h1{
    //                 padding-top:2rem;
    //                 font-size:20px;
    //                 margin: 0 auto;
    //                 @media screen and (min-width: $break-large) {
    //                     font-size:1.25rem;
    //                     max-width:554px;
    //                 }
    //                 @media screen and (min-width: $break-super-large) {
    //                     max-width:730px;
    //                 }
    //                 span.highlighted{
    //                     @media screen and (min-width: $break-medium) {
    //                         display:inline;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
    .titles{
        padding-top: 61px;
        .accroche{
            text-align:center;
            font-size:0.87rem;
            padding-top:20px;
            padding-bottom:20px;
            margin: 0 auto;
            @media screen and (min-width: $break-large) {
                font-size:20px;
            }

        }
    }
    #searching{
        // background: rgba(226,91,69,0.08);
        background: rgba(75, 187, 224, 0.5);
        @include radius(12px);
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;
        padding:30px;
        @media screen and (min-width: $break-small) {
            margin-left: 0;
            margin-right: 0;
        }
        .search-form{
            border:0;
            margin: 0 auto;
            position:relative;
            text-align: center;
            width:100%;
            max-width:320px;
            padding:0;
            @media screen and (min-width: $break-medium) {
                max-width:100%;
            }
            .search{
                input{
                    border: 2px solid #4bbbe0;
                    color: $grey;
                    font-size:0.87rem;
                    @include radius(100px);
                    vertical-align:middle;
                    padding: 13px 0 13px 30px;
                    width:100%;
                    // border:0;
                    @media screen and (min-width: $break-large) {
                        padding: 13px 0 13px 50px;
                    }
                    &::-webkit-input-placeholder {
                        color: $grey;
                        font-size:0.87rem;
                        vertical-align:middle;
                    }
                    &:-moz-placeholder {
                        color: $grey;
                        opacity:  1;
                        font-size:0.87rem;
                        vertical-align:middle;
                    }
                    &::-moz-placeholder {
                        color: $grey;
                        opacity:  1;
                        font-size:0.87rem;
                        vertical-align:middle;
                    }
                    &:-ms-input-placeholder {
                        color: $grey;
                        font-size:0.87rem;
                        vertical-align:middle;
                    }
                }
            }
            .action{
                background:$deepRed;
                @include radius(100px);
                position:absolute;
                top:-15px;
                right:0;
                width:45px;
                @media screen and (min-width: $break-medium) {
                    top: 49%;
                    right: 0;
                    @include translate(0, -50%);
                    width:237px;
                }
                @media screen and (min-width: $break-extra-large) {
                    display:grid;
                }
                button{
                    color:$white;
                    font-weight:bold;
                    text-align:center;
                    font-size:1rem;
                    padding: 13px 0;
                    background: none;
                    border:0;
                    span{
                        display:none;
                        @media screen and (min-width: $break-medium) {
                            display:inline;
                        }
                        @media screen and (min-width: $break-extra-large) {
                            display:none;
                        }
                    }
                    .fa{
                        @media screen and (min-width: $break-super-large) {
                            padding-right:0.7rem;
                        }
                        @media screen and (min-width: $break-extra-large) {
                            font-size: 1.75rem;;
                        }
                    }
                }
            }
        }
    }
    .result{
        font-family:$Now;
        color:$black;
        padding-bottom:20px;
        text-align:center;
        .return{
            color:$lightBlue;
            font-weight: bold;
        }
    }
    #all-post {
        margin-top: 1.5rem;
        @media screen and (min-width: $break-medium) {
            margin-bottom:50px;
        }
        .capsule-all-post {
            position: relative;
            .special-capsule {
                @include flex(column, center, flex-start);
                overflow:hidden;
                min-height:1800px;
                position:absolute;
                z-index: -1;
                top:0;
                left:0;
                right:0;

                @media screen and (min-width: $break-medium) {
                    min-height:1420px;
                }
                @media screen and (min-width: $break-large) {
                    min-height:1050px;
                }
                @media screen and (min-width: $break-super-large) {
                    min-height:1150px;
                }
                .all-post-forme{
                    position:absolute;
                    top:0;
                    left:-10vw; ///POUR IE
                    width:120vw;
                    @include radius (0% 0% 50% 50%/ 0% 0% 80px 80px);
                    background: rgba(75,187, 244,0.10);
                    height:1730px;
                    @media screen and (min-width: $break-small) {
                        height:1660px;
                    }
                    @media screen and (min-width: $break-medium) {
                        height:1370px;
                    }
                    @media screen and (min-width: $break-large) {
                        height:1035px;
                        @include radius(0% 0% 50% 50% /0% 0% 140px 140px);
                    }
                    @media screen and (min-width: $break-super-large) {
                        min-height:1085px;
                    }
                }
            }
        }
        .container-posts {
            .the-content {
                padding-top: 30px;
                .posts{
                    .element-item{
                        .content-item {
                            width: 100%;
                            margin:0 auto 30px auto;
                            .pict {
                                @media screen and (min-width: $break-medium) {
                                    padding-right: 0;
                                    height: 221px;
                                    overflow: hidden;
                                    @include alignSelf(stretch);
                                    min-width: 319px; //POUR IE
                                }
                                @media screen and (min-width: $break-large) {
                                    min-width:initial;
                                    @include alignSelf(initial);
                                    min-width:auto;
                                }
                                @media screen and (min-width: $break-extra-large) {
                                    ///min-width: 380px; //POUR IE
                                }
                                img {
                                    @include radius(12px 12px 0 0);
                                    min-width: 100%;
                                    @media screen and (min-width: $break-medium) {
                                        @include radius(12px 0 0 12px);
                                        height: 100%;
                                        width: 100%;
                                    }
                                    @media screen and (min-width: $break-extra-large) {
                                        min-width: 100%;
                                        height: auto;
                                    }
                                }
                            }
                            .infos {
                                @media screen and (min-width: $break-medium) {
                                    padding-left: 0;
                                    padding-bottom: 2rem;
                                }
                                .text {
                                    padding: 30px 15px;
                                    background: $white;
                                    @include flex(column, null, null);
                                    @include radius(0 0 12px 12px);
                                    // border-left:0 !important;
                                    @media screen and (min-width: $break-medium) {
                                        @include flex(column, null, null);
                                        height: 221px;
                                        @include radius(0 12px 12px 0);
                                        @include alignSelf(stretch);
                                        min-width: 320px; //POUR IE
                                        ///  border-left:inherit !important;
                                    }
                                    @media screen and (min-width: $break-large) {
                                        min-width: 295px; //POUR IE
                                    }
                                    .about {
                                        font-weight: 900;
                                        font-size: 0.87rem;
                                        @include flex(row, center, null);

                                        .date {
                                            padding-left: 1rem;
                                            color: $grey;
                                            font-weight: 300;
                                        }
                                    }
                                    .title {
                                        color: $grey;
                                        font-size: 1rem;
                                        padding: 5px 0 10px 0;
                                        @media screen and (min-width: $break-large) {
                                            padding-top: 10px;
                                            padding-bottom: 35px;
                                        }
                                        @media screen and (min-width: $break-extra-large) {
                                            font-size: 1.2rem;
                                        }
                                    }
                                    a.read {
                                        font-weight: 900;
                                        font-size: 0.87rem;
                                        text-decoration: underline;
                                        text-transform: uppercase;
                                        @media screen and (min-width: $break-medium) {
                                            margin-top: auto;
                                        }
                                    }
                                }
                            }

                        }
                    }
                }

                .annonce {
                    @media screen and (min-width: $break-large) {
                        @include alignSelf (stretch);
                        padding-bottom: 62px;
                        min-height: 1270px;
                    }
                    .capsule {
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        min-height: 652px;
                        @include radius(0);

                        @media screen and (min-width: $break-large) {
                            min-height: 100%;

                        }
                    }
                }
            }
            .the-footer{
                margin-bottom: 50px;
            }

            .cta {
                @media screen and (min-width: $break-large) {
                    margin-top: 1.87rem;
                }
                a.btn-cta {
                    max-width: 330px;
                }
            }
        }
        aside {
            .title {
                font-size: 2rem;
                padding-bottom: 20px;
                font-weight: bold;
                color: $lightBlue;

                span {
                    color: $grey;
                    font-weight: normal;
                }
            }

            .searching {
                width: 718px;
                padding: 30px;
                @include radius(12px);
                background: $white;
                margin-bottom: 60px;
                ul {
                    li:not(:last-child) {
                        padding-bottom: 20px;
                    }
                }
            }
            .insta {
                margin-bottom:50px;
                @media screen and (min-width: $break-large) {
                    margin-bottom:0;
                }
                .flux-insta {
                    @include justifyContent (center);
                    @media screen and (min-width: $break-large) {
                        padding: 30px 15px
                    }

                    .post {
                        -moz-background-size: cover;
                        -webkit-background-size: cover;
                        -o-background-size: cover;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        height: 175px;
                        max-width: 175px;
                        cursor: pointer;
                        padding: 0;
                        @media screen and (min-width: $break-large) {
                            height: 144px;
                            max-width: 144px;
                        }
                        @media screen and (min-width: $break-extra-large) {
                            height: 175px;
                            max-width: 175px;
                        }
                        p {
                            opacity: 0;
                            position: relative;
                        }
                        a {
                            height: auto;
                            width: 100%;
                            color:$lightBlue;
                            p{
                                opacity:0;
                                width: 100%;
                                height: 100%;
                                position: relative;
                            }
                        }
                        &.hover {
                            box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.7);
                            p {
                                opacity: 1;
                                width: 100%;
                                height: 100%;
                                @include flex(row, center, center);
                                a {
                                    color: $lightBlue;
                                    @media screen and (min-width: $break-super-large) {
                                        font-size: 30px;
                                        padding: 2rem;
                                    }
                                }
                            }
                        }
                    }

                }

            }
        }
    }

}

.picture-list{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 10px;
}

.picture-list li{
    margin-right: 8px;
    margin-bottom: 8px;
}

.picture-list li a{
    display: block;
    overflow: hidden;
    border-radius: 3px;
    opacity: 0.5;
    transition: opacity ease 0.5s;
}

.picture-list li a:hover{
    opacity: 1;
}

.picture-list li a img{
    max-width: 75px;
}