
/*-------------------------------------------------------
 ------------------->>> galets animés <<<----------------------
les class et keyframes ici
et les style des galets
 -------------------------------------------------------  */

/*pour reprendre style galet en commentaire dans l'index home'*/
.new-galet{
    background: pink;
    width: 480px;
    height: 600px;
    position: absolute;
    border-radius: 240px 303px 481px 100px /367px 559px 10% 300px;
    bottom: 66px;
    left: 191px;
    opacity: 0.3;
}



////*****GALET DANS TOUTES LES VUES *****///////
//pas de déclenchement en Js car à nativement la classe dans le dom
.galetAnim{
        width: 280px;
        height: 317px;
        opacity:0.05;
        @include radius (531px 451px 485px 397px/ 692px 557px 452px 322px); 
        background:#e25b45;
        z-index:-1;
        position: absolute;
        bottom: 0;
        z-index:-1;
        &.right{
            right: 40px; 
            left:initial;
        }
        &.left{
            left:40px;
        }
}

.upAndDown{
    animation-duration:60s;
    animation-name: upanddown;
    animation-iteration-count: infinite;
}

@keyframes upanddown {
    0% {
        bottom:0;
    }
    50%{
       bottom: calc(100% - 316px);
    }
    100%{
       bottom:0;
    }
}

////*****GALETS SPECIFIQUES A CHAQUE VUE****/////////

//galet qui qui monte 

.up{
    animation-duration:8s;
    animation-name: up, disaper;
}

@keyframes up {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-700vh);
    }
}

@keyframes disaper {
    0% {
        opacity:0;
    }
    2% {
        opacity:0.05;
    }
    100%{
        opacity:0.05;
    }
}




///// galet tournant

.rotation{
   animation: rotation  10000ms linear;
   animation-iteration-count: infinite;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

///// galet tournant et remontant

.rotateUp{
  animation-name: rotateUp;
  animation-duration: 40s;
}

@-webkit-keyframes rotateUp {
   0% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(0, -700px) rotate(360deg);
    opacity:0;
  }
}


///// galet tournant et descendant
.galetAnimReverse{
        width: 280px;
        height: 317px;
        opacity:0.05;
        @include radius (531px 451px 485px 397px/ 692px 557px 452px 322px); 
        background:#e25b45;
        z-index:-1;
        position: absolute;
        top: 0;
        z-index:-1;
        &.right{
            right: 40px; 
            left:initial;
        }
        &.left{
            left:40px;
        }
}

.rotateDown{
  animation-name: rotateDown;
  animation-duration: 40s;
}

///pour la vue show blog et 
///donc valeur de la hauteur du main de cette vue 2800px;
@-webkit-keyframes rotateDown {
   0% {
    transform: translate(0, 0) rotate(0deg);
    opacity:0;
  }
  1%{
    opacity:0.02;
  }
  50% {
    transform: translate(0, 1500px) rotate(360deg);
    opacity:0.3;
  }
  100% {
    opacity:0;
  }
}


/////// galet qui monte  depuis sa position de départ

.goUp{
    animation:goUp 40s;
}


@-webkit-keyframes goUp {
    0% {
        opacity:0;
    }
    1% {
        opacity:0.05;
        transform: translateY(0) rotate(-81deg) ;
    }
    50% {
        transform: translateY(-450px) rotate(-81deg);
    }
    70% {
        transform: translateY(-923px) rotate(-81deg);
    }
    100% {
        opacity:0;
    }
}


/////// galet qui descend  depuis sa position de départ

.goDown{
    animation:goDown 40s;
}


@-webkit-keyframes goDown {
    0% {
        opacity:0;
    }
    1% {
        opacity:0.05;
        transform: translateY(0) rotate(-97deg);
    }
    50% {
        transform: translateY(500px) rotate(-97deg);
    }
    70% {
        transform: translateY(723px) rotate(-97deg);
    }
    100% {
        opacity:0;
    }
}


/*-------------------------------------------------------
 ------------------->>> animation des dots des carousel <<<----------------------
 -------------------------------------------------------  */

@keyframes appear {
    0%   {
    }
    100% {

        width: 12px;
        height: 12px;
        margin: 0 5px;
    }
}

@keyframes disapear {
    0%   {
        width: 12px;
        height: 12px;
    }

    100% {
       	margin: 0;
        width: 0;
        height: 0;
    }
}

@keyframes becomeSmall {
    0%   {
        width: 15px;
        height: 15px;
    }
    100% {
        margin: 0 5px;
        width: 12px;
        height: 12px;
        opacity:0.2;
    }
}

@keyframes becomeBig {
    0%   {
        width: 12px;
        height: 12px;
    }
    100% {
        margin: 10px 5px;
        width: 15px;
        height: 15px;
         opacity:1;
    }
}
