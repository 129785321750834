/* ----------------------------- */
/* ==Main      */
/* ----------------------------- */
@import "config/variables";
@import "config/mixins";
@import "config/fonts";
@import "config/base";


@import "custom/layout";
@import "custom/header";
@import "custom/footer";
@import "custom/anim";

/*style home*/
@import "custom/home";
/*style shop: search boutique + show d'un boutique'*/
@import "custom/shop";
/*style blog: index + show*/
@import "custom/blog";
/*style annonce: show d'une annonce + search annonce et pro */
@import "custom/annonce";
/*style page about et page contactez-nous */
@import "custom/about";
/*style page login et register */
@import "custom/login";
/*style pages espace client */
@import "custom/client";
/* Style for search results */
@import "custom/search";


///@import "custom/sheet";
//@import "custom/news";

