/* ----------------------------- */
/* ==Base    */
/* ----------------------------- */

*{
    outline: 0 !important;
    box-sizing: border-box;
}

/*-------------------------------------------------------
 ------------------->>> style de base<<<----------------------
 -------------------------------------------------------------------  */


a {
    color:$black;
    transition: all 0.3s ease-in-out;
    &:hover {
        text-decoration: none;
    }
}

a.underline{
    text-decoration: underline; 
}

ul.unstyled, ol.unstyled {
    list-style: none;
    margin: 0;
    padding: 0;
}

.img-fluid,
.img-responsive{
    height: auto;
    max-width: 100%;
}


input[type="search"],
input[type="email"]{
    outline: 0;
}

a:focus,
input:focus,
button:focus,
.form-control:focus,
.btn-outline-secondary:focus{
    outline: 0;
    box-shadow:none !important;
}




