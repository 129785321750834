/*------------------------------------------------------------------------------------
 ------------------->>> généralité concernant le layout<<<----------------------
-------overide si besoin des éléments dans le style de la vue cocernée -----------
 -----------------------------------------------------------------------------------  */



body,
header .searching .select2-dropdown{
    --sb-track-color: #fff;
    --sb-thumb-color: #73c8bd;
    --sb-size: 10px;

    scrollbar-color: var(--sb-thumb-color)
    var(--sb-track-color);
}

body::-webkit-scrollbar,
header .searching .select2-dropdown::-webkit-scrollbar{
    width: var(--sb-size)
}

body::-webkit-scrollbar-track,
header .searching .select2-dropdown::-webkit-scrollbar-track{
    background: var(--sb-track-color);
    border-radius: 10px;
}

body::-webkit-scrollbar-thumb,
header .searching .select2-dropdown::-webkit-scrollbar-thumb{
    background: #e25b45;
    border-radius: 10px;

}

body {
    font-family: $Now;
    font-size: 16px;
    color:$grey;
    width:100%;
    height:100%;
}

//pour les galets en débord
main{
    position:relative;
}

a{
    cursor:pointer;
    transition: all 0.3s ease-in-out;
    text-decoration:none;
    &.underline{
        text-decoration:underline;
    }
}

h2{
    text-align:center;
    color:$grey;
    font-size:28px;
    font-weight:300;
    @media screen and (min-width: $break-large) {
        font-size:42px;
    }
    span{
        font-weight:900;
        color:$lightBlue;
    }
}

/* bandeau du pub 0/02/20*/

.bandeau{
    padding-top:97px;
    @media screen and (min-width: $break-large) {
        padding-top:20px;
    }
    .capsule{
        background:linear-gradient(rgba(75, 187, 224, 0.3),rgba(75, 187, 224, 0.3)),url('#{$imgPath}exemples/exemplepub.jpg');
        background-repeat:no-repeat;
        background-size:cover;
        height:230px;
        background-position:center;
        @include flex(column, center, center);
        padding:20px 0;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        @media screen and (min-width: $break-small) {
            max-width: 576px;
            margin: 0 auto;
        }
        @media screen and (min-width: $break-medium) {
            max-width: 720px;
            margin: 0 auto;
        }
        @media screen and (min-width: $break-large) {
            max-width: 960px;
            margin: 0 auto;
        }
        @media screen and (min-width: $break-extra-large) {
            max-width: 1140px;
            margin: 0 auto;
        }
        p{
            font-family: $Now;
            font-size: 16px;
            color:$white;
            margin-bottom:0;
            padding-bottom:10px;
            &.accroche{
                text-transform:uppercase;
                text-align:center;
                font-weight:600;
                color:$white;
                &:before{
                    content:'';
                    display:block;
                    text-transform:uppercase;
                    background-color:$deepRed;
                    height:3px;
                    width:50px;
                    @include radius(25px);
                    margin:0 auto 10px auto;

                }
                &:after{
                    content:'';
                    display:block;
                    background-color:$deepRed;
                    height:3px;
                    width:50px;
                    @include radius(25px);
                    margin:10px auto 0 auto;
                }
            }
            &:last-of-type{
                padding-bottom:0;
            }
        }
        .cta{
            margin-top:auto;
            button.btn-cta{
                font-size:14px;
                padding:10px 25px;
            }
        }
    }
}

/**
PARTIE VALENTIN 06/08/2019
Reprise des vignettes annonce pour les rendre clickable
*/
.slick-slide{
    &.vignette-annonce{
        .capsule{
            .fav-mobile{
                bottom: 13%;
                right: 2%;
            }
            &:hover{
                .bottom-card{
                    // bottom: 4%;
                }
            }
            .bottom-card{
                // left: 6.4%;
                width: 100%;
            }
        }
    }

}

.vignette-annonce {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    @media screen and (min-width: $break-medium) {
        margin: 0;
    }
    .capsule {
        // overflow: hidden;
        margin: 0 5px;
        position: relative;
        @include flex(column, null, null);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        // min-height: 425px;
        margin-bottom: 30px;
        max-width: 300px;
        //padding: 20px;
        overflow: hidden;
        @include radius(12px 12px 20px 20px);
        @media screen and (min-width: $break-small) {
            max-width: 255px;
        }
        @media screen and (min-width: $break-medium) {
            // min-height: 385px;
            // max-height: 385px;
            max-width: 300px;
        }
        @media screen and (min-width: $break-large) {
            // min-height: 428px;
        }
        @media screen and (min-width: $break-extra-large) {
            // min-height: 400px;
            max-width: 255px;
        }
        .link-annonce{
            position: relative;
            // padding: 20px;
            // overflow: hidden;
            .img-card-anounce{
                min-height: 170px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                position: relative;
                .picto {
                    position: absolute;
                    top: 6%;
                    left: 8%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    height: 40px;
                    width: 40px;
                    @include radius(12px);
                }
                .picture-merchant{
                    position: absolute;
                    right: 7%;
                    bottom: 0;
                    transform: translateY(50%);
                    width: 64px;
                    height: 64px;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        background-color: white;
                        object-fit: cover;
                    }
                }
            }
            .infos {
                padding: 20px;
                background: $white;
                box-shadow: 0 5px 3px 0 rgba(107, 125, 160, 0.1);
                // box-shadow: 0 2px 2px 0 rgba(107, 125, 160, 0.1);
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                // min-height: 238px;
                height: 210px;
                .city {
                    padding-bottom: 5px;
                    color: $grey;
                    font-weight: 300;
                    font-size: 10px;
                    text-transform: capitalize;
                }
                .place {
                    min-height: 57px;
                    font-weight: bold;
                    font-size: 18px;
                    padding-bottom: 5px;
                    line-height: normal;
                }
                .offer {
                    flex-wrap: wrap;
                    min-height: 73px;
                    padding-bottom: 31px;
                    color: $grey;
                    font-weight: normal;
                    font-size: 14px;
                }
                .price {
                    @include flex(row, baseline, flex-start);
                    .unit-price{
                        font-size: 14px;
                        font-weight: 900;
                    }
                    .new {
                        font-size: 22px;
                        font-weight: 900;
                    }

                    .old {
                        font-size: 0.625rem;
                        color: $grey;
                    }

                    .time {
                        padding-left: 16px;
                        color: $deepRed;
                        font-size: 0.75rem;

                        &:before {
                            color: $deepRed;
                            content: '\f017';
                            font-family: "Font Awesome 5 Free";
                            font-weight: 400;
                            font-size: 0.75rem;
                            padding-right: 6px;
                        }
                    }
                }
            }
        }
        .fav-mobile{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            position: absolute;
            bottom: 3%;
            right: 9%;
            border-radius: 8px;
            .bookmark-favoris{
                height: 16px;
                line-height: 0;
                i{
                    color: white;
                }
            }
            @media screen and (min-width: 1025px) {
                display: none;
            }
        }
        &:hover{
            .price{
                display: initial;
            }
            @media screen and (min-width: 1024px) {
                .price{
                    display: none !important;
                }
                .bottom-card{
                    display: flex;
                }
            }
        }
        .bottom-card{
            @include flex(null, center, center);
            position: absolute;
            bottom: 0;
            display: none;
            height: 44px;
            width: 100%;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            p{
                margin: 0;
                font-size: 11px;
                text-transform: uppercase;
            }
        }
        .fav{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            position: absolute;
            right: 9%;
            top: -23%;
            border-radius: 8px;
            cursor: pointer;
            .bookmark-favoris{
                height: 16px;
                line-height: 0;
                i{
                    color: white;
                }
            }
        }
        .bottom {
            padding: 15px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            @include radius(0 0 12px 12px);
            @include flex (row, baseline, space-between);
            span {
                a {
                    color: $white;
                    font-weight: bold;
                    font-size: 0.68rem;
                }
                &.bookmark {
                    position: relative;
                    width: 50%;
                    font-size: 0.6rem;
                    color: $white;
                    @media screen and (min-width: 710px) {
                        width: 62%;
                    }
                    &:after {
                        content: '|';
                        color: $white;
                        right: 0;
                        bottom: 3px;
                        position: absolute;
                        @media screen and (min-width: 710px) {
                            right:-10px;
                            bottom: 3px;
                            position: absolute;

                        }
                    }
                    .hide {
                        display: none;
                        @media screen and (min-width: 710px) {
                            display: inline;
                        }
                    }
                }
                &.like {
                    padding-right: 5px;
                    font-size: 0.8rem;
                    color: $white;
                }
            }
        }
    }
}


.vignette {
    margin-bottom: 75px; //les 30 + les 44 de débord du bas de la vignette
    padding-left: 0;
    padding-right: 0;
    @media screen and (min-width: $break-medium) {
        padding-left: inherit;
        padding-right: inherit;
        max-width:350px;
        margin:0 auto 59px auto;
    }

    .capsule {
        position: relative;
        margin: 0 5px;
        @include radius(12px);
        //padding-top: 30px; --Valentin
        position: relative;
        //min-height: 335px; --Valentin
        //  height:335px;//POUR IE
        margin-bottom: 60px;
        padding: 20px;
        @media screen and (min-width: $break-small) {
            // min-height: 317px;
            //height:317px;//POUR IE
        }
        @media screen and (min-width: $break-medium) {
            //padding-top: 25px; --Valentin
            //min-height: 266px; --Valentin
            ///height:266px;//POUR IE
            width: 350px;
            height: 330px;
            margin: 0 auto 60px auto;
        }
        .link-boutique{

            display: block;
            min-height: 335px;
            padding-top: 30px;
            @media screen and (min-width: $break-small) {
                // min-height: 317px;
                //height:317px;//POUR IE
            }
            @media screen and (min-width: $break-medium) {
                padding-top: 25px;
                min-height: 266px;
                max-width: 350px;
                margin: 0 auto 60px auto;
                ///height:266px;//POUR IE
            }
            .top-infos {
                color: $white;
                text-align: center;
                padding: 0 30px;
                // .city {
                //     font-weight: 300;
                //     font-size: 16px;
                //     padding-bottom: 5px;
                // }
                .place {
                    font-weight: bold;
                    font-size: 19px;
                    padding-bottom: 5px;
                    line-height:26px;
                    text-align: center;
                    @media screen and (min-width: $break-large) {
                        font-size: 22px;
                    }

                }

                &.pastille{
                    .city, .place{
                        text-align:left;
                    }
                }
                img{
                    margin:0 0 0 auto;
                    display:block;
                }
            }
        }
        .capsule-bottoms {
            position: relative;
            // bottom: -39px;
            // @media screen and (min-width: $break-large) {
            //     bottom: -44px;
            // }
            a{
                &:hover{
                    color: #4a4a4a;
                }
            }
            .bottom-infos {
                background: $white;
                padding: 20px;
                @include radius(12px);
                box-shadow: 0 2px 2px 0 rgba(250, 185, 129, 0.2);
                // box-shadow: 0 5px 3px 0 rgba(250, 185, 129, 0.2);
                &.vignette-bottom{
                    height: 340px;
                }
                .top-infos{
                    // height: 50px;
                    margin-bottom: 15px;
                    .place{
                        color: #4a4a4a;
                        text-align: center;
                        font-size: 22px;
                        font-weight: bold;
                        line-height: 1.32;
                    }
                }
                .imgs {
                    @include flexDirection(row);
                    @include alignItems (center);
                    @include justifyContent (center);
                    .col-card{
                        display: flex;
                        justify-content: center;
                        padding: 0;
                        .avatar {
                            width: 110px;
                            height: 110px;
                            overflow: hidden;
                            @include radius (50%);
                            margin: 0 auto;
                            @media screen and (min-width: $break-medium) {
                                margin: 0;
                            }
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .pastille{
                            width: 86px;
                            height: 86px;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }
                .infos{
                    @include justifyContent (center);
                    margin-top: 15px;
                    .city{
                        color: #4a4a4a;
                        text-align: center;
                        font-size: 13px;
                        font-weight: 500;
                    }
                    .user {
                        margin-top: 10px;
                        text-align: center;
                        .name {
                            font-size: 1rem;
                            font-weight: 500;
                            text-align: center;
                            @media screen and (min-width: $break-medium) {
                                text-align: left;
                            }
                        }
                        p.testimonial {
                            display: unset;
                            font-size: 14px;
                            font-weight: normal;
                            margin: 0;
                        }
                        p.discover{
                            display: unset;
                            font-size: 14px;
                            font-weight: bold;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .bottom-test {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                right: 30px;
                bottom: 0;
                transform: translateY(50%);
                border-radius: 8px;
                position: absolute;
                cursor: pointer;
                .bookmark-favoris{
                    height: 16px;
                    line-height: 0;
                    i{
                        color: white;
                    }
                }
                // font-size: 0.8rem;
                // @media screen and (min-width: $break-large) {
                //     font-size: 1rem;
                // }
                // span {
                //     a {
                //         font-weight: bold;
                //         font-size: 0.68rem;
                //     }
                //     &.bookmark {
                //         position: relative;
                //         font-size: 0.6rem;
                //         .hide {
                //             display: none;
                //             @media screen and (min-width: 710px) {
                //                 display: inline;
                //             }
                //         }
                //     }
                //     &.like {
                //         font-size: 0.8rem;
                //     }
                // }
            }
        }
    }
}

// dot personnalisé des carousel
.capsule-nav{
    bottom: -30px;
    position: absolute;
    left: 0;
    right: 0;
    @media screen and (min-width: $break-large) {
        bottom: -18px; 
    }
    .nav-carousel{
        list-style:none;
        //padding-top: 30px;
        padding:0;
        width: 150px;
        height: 15px;
        margin: 0 auto;
        @include flex(row, center, center);
        .elem {
            background-color: $lightBlue;
            @include radius(50%);
            opacity: 0.3;
            animation-duration: 1s;
            margin: 0 5px;
            &.far-left,
            &.far-right{
                width: 0;
                height: 0;
                margin: 0;
            }
            &.petit{
                width: 12px;
                height: 12px;
            }
            &.grand{
                width: 14px;
                height: 14px;
                margin: 10px 5px;
                opacity: 1;
            }
        }
        &.to-right{
            .far-left{
                animation-name: appear; 
            }
            .left{
                animation-name: becomeBig;
            }
            .grand{
                animation-name: becomeSmall; 
            }
            .right{
                animation-name: disapear;
            }
        }
        &.to-left{
            .far-right{
                animation-name: appear; 
            }
            .right{
                animation-name: becomeBig;
            }
            .grand{
                animation-name: becomeSmall;
            }
            .left{
                animation-name: disapear;
            }
        } 
    }
}
//les galets 
.galet{
    width: 554px;
    height: 459px;
    @include radius (523px 854px 722px 931px /367px 677px 744px 968px);  
    background:$white;
    opacity:0.2;
    @media screen and (min-width: $break-extra-large) {
        @include radius (523px 854px 722px 814px /367px 650px 744px 968px);  
        width: 760px;
        height: 680px;
    }
}


//galet en forme d'oeuf (cote droit des pros à la une)
.galet2{
    width: 377px;
    height: 728px;
    @include radius (50% 50% 50% 50% / 448px 424px 257px 257px);  
    background:$white;
    opacity:0.2;
    @media screen and (min-width: $break-extra-large) {
        /*  width: 531px;
         height: 728px;*/
    }
}

//galet en forme de galet (cote gauche du blog)
.galet3{
    width: 594px;
    height: 666px;
    @include radius (531px 359px 456px 406px/ 692px 557px 394px 283px);  
    background:$white;
    opacity:0.2;
}



//le galet du header
.galet.header{
    opacity: 0.8;
    @include radius (135% 50% 72% 110% / 112% 69% 116% 73%); 
    background-color:$lightBlue;
}


//la div qui rend opaque le site en mobil quand le menu est ouvert
.dark-backdrop {
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    &.in{
        opacity: 1;
        z-index: 9;
    }
}

.bookmark{
    cursor: pointer;
}

.erreur {
    ul {
        margin: 10px 0 0 0;
        padding: 0;

        .list-group-item {
            padding: 0px 15px;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;

            &:last-child {
                display: none;
            }
        }
    }
}

//les alertes de validation ou d'erreur
.alert{
    position: fixed;
    top: 67px;
    right: 16px;
    z-index: 1000;
}

//les boutons
//le bouton d'action standard
.cta{
    a.btn-cta{
        border: 2px solid $deepRed;
        background:$deepRed;
        color:$white;
        @include radius(50px);
        font-size:18px;
        font-weight:bold;
        padding:14px 20px;
        margin: 0 auto;
        display:block;
        max-width:280px;
        @media screen and (min-width: $break-large) {
            padding:14px 66px;
        }
        &:hover{
            background:$white;
            border: 2px solid $deepRed;
            color:$deepRed;
        }
    }
}

button.btn-cta, a.btn-cta{
    background:$deepRed;
    cursor: pointer;
    border:2px solid $deepRed;
    color:$white;
    @include radius(50px);
    font-size:18px;
    font-weight:bold;
    padding:14px 20px;
    margin: 0 auto;
    display:block;
    .fas{
        padding-right:0.3rem;
    }
    &:hover{
        background:$white;
        border: 2px solid $deepRed;
        color:$deepRed;
    }
}

.btn-cta.facebook{
    background:$white;
    border:2px solid $deepRed;
    color:$deepRed;
    @include radius(50px);
    font-size:18px;
    font-weight:bold;
    padding:14px 20px;
    margin: 0 auto;
    display:block;
    .fab{
        padding-right:15px;
    }
    &:hover{
        background:$deepRed;
        border: 2px solid $deepRed;
        color:$white;
    }
} 

input.btn-cta{
    background:$deepRed;
    border:2px solid $deepRed !important;
    color:$white;
    border:0;
    @include radius(50px);
    font-size:18px;
    font-weight:bold;
    padding:14px 20px;
    margin: 0 auto;
    display:block;
    &:hover{
        background:$white;
        border: 2px solid $deepRed;
        color:$deepRed;  
    }
}
//tous les style pour les contenus texte issus du wisiwig : blog/ annonce/boutique
.content-wisiwig{
    h2{
        font-size:24px;
        text-align:left;
        padding-bottom:10px;
    }
    h3{
        font-size:20px;
        text-align:left;
        padding-bottom:10px;
    }
    img{
        max-width:100%;
        height:auto;
        padding:0.5rem 0;
    }
    iframe{
        max-width:100%;
        padding:0.5rem 0;
    }
    p{
        font-size:1rem;
        margin:0;
        padding-bottom:10px;
    }

}


///les checkob et radio personnalisé 
.form-check{
    padding-left:15px;
    input[type=radio]{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
    }
    input[type=radio] + label:before {
        background: #fff;
        border: 1px solid $grey;
        @include radius(50%);
        margin-top: -3px;
        margin-right: 6px;
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: 17px;
        height: 17px;
    }
    input[type=radio]:checked + label:before {
        background:$lightBlue;
        border-color: $lightBlue;
        box-shadow: inset 0px 0px 0px 2px #fff;
    }
    label{
        cursor:pointer;
    }
}
.form-check{
    padding-left:15px;
    input[type="checkbox"]{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
    }
    input[type="checkbox"] + label:before {
        background: #fff;
        border: 1px solid $grey;
        margin-top: -3px;
        margin-right: 6px;
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: 17px;
        height: 17px;
    }
    input[type="checkbox"]:checked + label:before {
        background: $lightBlue;
        border-color: $lightBlue;
        box-shadow: inset 0px 0px 0px 2px #fff;
    }
    label{
        cursor:pointer;
    }
}

///les input
.form-group input{
    box-shadow: none;
    padding: 0.6em;
    border: 1px solid $grey;
    height:45px;
    font-size:0.87rem;
    @include radius(0);
    width:100%;
    ::-webkit-input-placeholder {
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
    ::-moz-placeholder {
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
    ::-ms-input-placeholder {
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
    ::placeholder{
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
}

////select et textarea
select,
textarea{
    box-shadow: none;
    padding: 0.6em;
    border: 1px solid $grey;
    height:45px;
    font-size:0.87rem;
} 

select{
    color:$grey;
    font-weight:300;
}

//style des select2 
.select2-container{
    width:100% !important;
    //max-width:320px;
    color:$grey;
    font-size:0.87rem;
    font-weight:300;
    a.select2-choice{
        color:$grey;
        font-size:0.87rem;
        font-weight:300; 
        background:white;
        border:0;
        line-height: 18px;
        padding: 17px 20px;
        height: 52px;
        @include radius(50px);
        .select2-chosen{}
        .select2-arrow{
            width:30px;
            border: 0;
            background: white;
            right:10px;
            b{
                background: url('#{$imgPath}pictos/picto-down.png') center center no-repeat;
            }
        }
    }
    .select-selection{
        @include radius(50px);
        border: none !important;
    }
    // .select2-dropdown{
    //     .select2-results__option--highlighted{
    //         background: $lightBlue !important;
    //     }
    // }
}


//style des select natif de talence shopping
///formulaire de recherche des commerçants 
.dd-container{
    width:100% !important;
    //max-width:320px;
    color:$grey;
    font-size:0.87rem;
    font-weight:300;
    background:white;
    border:0;
    line-height: 18px;
    padding: 17px 5px 17px 20px;
    height: 52px;
    .dd-select{
        width: 100% !important;
    }
    @include radius(50px);
    ::-webkit-input-placeholder {
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
    ::-moz-placeholder {
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
    ::-ms-input-placeholder {
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
    ::placeholder{
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
    .dd-pointer {
        position: absolute;
        right:0;
        top: 25%;
        margin-top: -5px;
    }
    .dd-pointer-down {
        border: 0 !important;
        border-top:0;
        height: 20px;
        width: 20px;
        //background: url('/bundles/app/img/pictos/picto-down.png') no-repeat;
        &:after{
            content: '\f078';
            font-family: "Font Awesome 5 Free";
            color: #e25b45;
            font-size: 1rem;
            font-weight: 600;
        }
    }

    .dd-pointer-down.dd-pointer-up {
        &:after{
            content: '\f077';
            font-family: "Font Awesome 5 Free";
            color: #e25b45;
            font-size: 1rem;
            font-weight: 600;
        }
    }

    .dd-options{
        padding: 10px; 
        left:10px;
        top: 55px; 
    }
    a.dd-option{
        @include flex(row, center, flex-start);
        line-height: 42px;
        label{
            margin-bottom:0;
            padding-left:5px;
        }
    }
}

//style des input natif selectized natif à talence shopping
.selectize-control{
    width:100%;
    max-width:320px;
    color:$grey;
    font-size:0.87rem;
    font-weight:300; 
    background:white;
    border:0;
    line-height: 18px;
    padding: 6px 20px;
    min-height: 52px;
    @include radius(50px);
    ::-webkit-input-placeholder {
        color:$grey;
        font-size:0.87rem;
        opacity: 1;
        font-weight:300;
    }
    ::-moz-placeholder {
        color:$grey;
        font-size:0.87rem;
        opacity: 1;
        font-weight:300;
    }
    ::-ms-input-placeholder {
        color:$grey;
        font-size:0.87rem;
        opacity: 1;
        font-weight:300;
    }
    ::placeholder{
        color:$grey;
        font-size:0.87rem;
        opacity: 1;
        font-weight:300;
    }
}

.selectize-input{
    .item{
        position:relative;
        font-size: 1rem;
    }
    input{
        border:0;
        background: white;
        ::-webkit-input-placeholder {
            color:$grey;
            font-size:0.87rem;
            font-weight:300;
        }
        ::-moz-placeholder {
            color:$grey;
            font-size:0.87rem;
            font-weight:300;
        }
        ::-ms-input-placeholder {
            color:$grey;
            font-size:0.87rem;
            font-weight:300;
        }
        ::placeholder{
            color:$grey;
            font-size:0.87rem;
            font-weight:300;
        }
    }
}
.selectize-control.plugin-remove_button [data-value] .remove{
    z-index: 1; 
    position: absolute;
    width: 17px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    vertical-align: middle;
    display: inline-block;
    padding: 2px 0 0 0;
    -webkit-border-radius: 0 2px 2px 0;
    -moz-border-radius: 0 2px 2px 0;
    border-radius: 0 2px 2px 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

}


.selectize-dropdown{
    background:$white;
    padding: 10px;
    margin-top: -7px;
    z-index:99;
    .option{
        margin-bottom: .5rem;
    }
}

//style d'un input comme le style du select2 pour le 
///formulaire de recherche des commerçants 
input.form-control.same-select{
    width:100%;
    //max-width:320px;
    color:$grey;
    font-size:0.87rem;
    font-weight:300; 
    background:white;
    border:0;
    line-height: 18px;
    padding: 0 15px;
    height: 52px;
    @include radius(12px);
    ::-webkit-input-placeholder {
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
    ::-moz-placeholder {
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
    ::-ms-input-placeholder {
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
    ::placeholder{
        color:$grey;
        font-size:0.87rem;
        font-weight:300;
    }
}

//style des pages statiques: cgv + mentions légales
.page-statique{
    header{
        min-height:216px;
        .banniere{
            @media screen and (min-width: $break-large) {
                min-height:247px;
            }
            .capsule-titre{
                @media screen and (min-width: $break-large) {
                    .logo{
                        img{
                            max-width: 127px;
                            height: auto;
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }
    .container-statique{
        padding: 30px 0;
    }
    h1{
        text-align:center;
        color:$lightBlue;
        font-size:28px;
        font-weight:500;
        @media screen and (min-width: $break-large) {
            font-size:38px;
            padding:0 0 30px 0;
        }
    }
    h2{
        text-align:left;
        color:$lightBlue;
        font-size:22px;
        font-weight:500;
        @media screen and (min-width: $break-large) {
            font-size:30px;
            padding:30px 0;
        }
    }
    h3{
        font-weight:500;
        font-size:18px;
        @media screen and (min-width: $break-large) {
            font-size:20px;
            padding:15px 0;
        }
    }
    h4{
        font-weight:normal;
        font-size:15px;
        @media screen and (min-width: $break-large) {
            font-size:16px;
            padding:10px 0;
        }
    }

    .highlighted{
        font-weight:500;
        color:$lightBlue;
    }
    .container-statique a{
        color:$lightBlue;
        &:hover{
            color:$lightBlue;
            text-decoration:underline;
        }
    }
}

///responsive recaptacha modale demande de devis
#rc-anchor-container{
    @include scale(0.77);  
    -webkit-transform-origin:0 0;
    transform-origin:0 0;  
}

/////bouton retour top qui s'affiche au scroll via js
.back-to-top {
    display:none;
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 999;
    .fas{
        font-size:32px;
        display: block;
        width:35px;
        height:35px;
        @include radius(50%);
        text-align: center;
        margin: auto;
        background:$lightBlue;
        color:$white;

    }
}



/////bandeau cookie
.cookie-message {
    position:fixed;
    top:inherit;
    bottom: 0;
    left:0;
    right:0;
    padding: 1rem;
    border-bottom:1px solid #ccc;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.33);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.33);
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.33);
    z-index: 999;
    background:$grey;
    opacity:0.95;
    @media screen and (min-width: $break-small) {
        @include flex(row,center,null);
    }
    @media screen and (min-width: $break-large) {
        max-height:150px;
    }
    p{
        color:$white;
        margin: 1.2em auto;
    }
    a{
        position: relative;
        top:inherit;
        cursor:pointer;
        display: block;
        text-align: center;
        padding: 8px 15px;
        margin:0.5rem;
        width:100%;
        right: 8px !important;
    }
    a.cookiebar-close {
        background: $lightBlue;
        border: solid 1px $lightBlue;
        color: $white !important;
        right: 8px !important;
        &:hover{
            border: solid 1px $lightBlue;
            background:$white;
            color: $lightBlue !important; 
        }

    }

    a.en-savoir-plus {
        border: solid 1px $lightBlue;
        background:$white;
        color: $lightBlue;
        right: 8px !important;
        &:hover{
            background: $lightBlue;
            border: solid 1px $lightBlue;
            color: $white !important;
        }
    }
}


//la div qui rend opaque le site en mobil quand le menu est ouvert
.dark-backdrop {
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    &.in{
        opacity: 1;
        z-index: 9;
    }
}

.z-fond{
    z-index:-1;
}

.z-1{
    z-index:1;
}


.z-2{
    z-index:2;
}

.z-3{
    z-index:3;
}




@media (min-width: 1200px) {
    .container .searching {
        width: 800px;
        .search-form{
            width: 100% !important;
            margin: 0 !important;
            .button-selection {
                display: flex !important;
                justify-content: space-around;
                .service {
                    background-color: #e25b45;
                    // width: 20%;
                    min-height: 42px;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    margin-left: 10px;
                    padding: 0 12px;
                    color: white;
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.2;
                    letter-spacing: 2.11px;
                    border: 1px solid #e25b45;
                    &.selected{
                        color: #e25b45;
                        background: #fff;
                    }
                }
            }
            .action {
                position: absolute !important;
                align-self: auto !important;
                width: 80px !important;
                height: 80px !important;
                button .fa {
                    padding-right: 0 !important;
                }
            }
            .search-engine {
                width: 100%;
                grid-template-columns: 160px 1fr 1fr 65px !important;
                grid-template-rows: 1fr !important;
                .select2-container--search span.select2-dropdown {
                    left: -190px;
                    z-index: 99999999;
                }
                div {
                    grid-column: auto !important;
                    display: grid;
                }
                .switch-container {
                    label {
                        width: 105px;
                        height: 29px;
                        margin-right: 0;
                    }
                    label.a {
                        margin-bottom: 5px !important;
                    }
                    label:after {
                        width: 22px;
                        height: 22px;
                        top: 3px;
                    }
                    
                }
            }
        }
    }   
}

@media (max-width: 1430px) {
    .container .searching {
        .search-form {
            .search-engine {
                .select2-container--search span.select2-dropdown {
                    width: 940px !important;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .container .searching {
        .search-form {
            .search-engine {
                .select2-container--search span.select2-dropdown {
                    width: 100% !important;
                }
            }
        }
    }
}

@media (min-width: 1430px) {
    .container .searching {
        .search-form {
            .search-engine {
                .select2-container--search span.select2-dropdown {
                    width: 1140px !important;
                }
            }
        }
    }
}

.searching{
    .search-form{
        max-width: unset !important;
        .search .select2-search__field{
            visibility: visible !important;
        }
        .button-selection {
            display: none;
        }
        .action {
            grid-row: 2 / span 3;
        }
        .search-engine{
            display: grid;
            grid-template-columns: 1fr 60px;
            grid-template-rows: 1fr 1fr 1fr;
            row-gap: 10px;
            padding: 50px 0;
            @media (max-width: 700px) {
                padding-top: 0px;
            }
            @media (min-width: 1200px) {
                padding: 10px 0 50px 0;
            }
            div {
                grid-column: 1 / span 1;
                display: flex;
            }
            .content-sug {
                display: block;
            }
            .switch-container {
                display: flex;
                align-items: center;
                justify-content: center;
                .isCheck {
                    justify-content: start;
                    padding-left: 20%;
                    padding-right: 20%;
                }
                .isNotCheck {
                    justify-content: flex-end;
                    padding-left: 20%;
                    padding-right: 20%;
                    padding-bottom: 7px;
                }
                input[type=checkbox]{
                    height: 0;
                    width: 0;
                    visibility: hidden;
                    margin: 0 !important;
                    display: none;
                }
                label {
                    cursor: pointer;
                    width: 100px;
                    height: 35px;
                    background: #161616;
                    display: block;
                    border-radius: 100px;
                    position: relative;
                    margin: 0 30px 0 0!important;
                    color:white;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 13px;
                    padding-right: 20px;
                }
                label:after {
                    content: '';
                    position: absolute;
                    // top: 2px;
                    left: 5px;
                    width: 25px;
                    height: 25px;
                    background: #fff;
                    border-radius: 90px;
                    transition: 0.3s;
                    color: black;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                }
                label.js:after {
                    content: "PRO" !important;
                }
                input:checked + label {
                    background: #c84b31;
                    justify-content: start;
                    padding-left: 20px;
                }
                input:checked + label:after {
                    left: calc(100% - 5px);
                    transform: translateX(-100%);
                    -webkit-transform: translateX(-100%);
                }
                // label:active:after {
                //     width: 130px;
                // }
                // div {
                //     .bootstrap-switch {
                //         width: 100px !important;
                //         height: 30px;
                //         // background: #e25b45 !important;
                //         border-radius: 15px;
                //         .bootstrap-switch-container {
                //             height: 100%;
                //             input:checked {
                //                 background: #e25b45 !important;
                //             }
                //         }
                //         .bootstrap-switch-handle-on, .bootstrap-switch-handle-off {
                //             width: 70px;
                //         }
                //         .bootstrap-switch-handle-on{
                //             background-color: #e25b45 !important;
                //         }
                //         .bootstrap-switch-handle-off{
                //             background-color: #161616 !important;
                //         }
                //         .bootstrap-switch-label {
                //             border-radius: 25px;
                //             width: 30px !important;
                //         }
                //     }
                // }
            }
            .wrapper-select{
                position: relative;
                width: 100%;
                grid-column: span 2;
                &:after{
                    content: '';
                    position: absolute;
                    right: 11%;
                    top: 30%;
                    display: inline-block;
                    width: 10px;
                    height: 9px;
                    background: url('#{$imgPath}pictos/arrow-select.svg') center right no-repeat;
                }
                #type-tag{
                    width: 240px;
                    height: 48px;
                    padding: 14px 30px;
                    background-color: #f6f5f5;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    font-size: 14px;
                    font-weight: bold;
                    color: #e25b45;
                    cursor: pointer;
                    option:not(:first-child){
                        color: #4a4a4a;
                        font-weight: normal;
                    }
                    option:first-child{
                        font-weight: normal;
                    }
                }
                select{
                    -moz-appearance:none; /* Firefox */
                    -webkit-appearance:none; /* Safari and Chrome */
                    appearance:none;
                }
            }
            @media (min-width: 1200px) {
                .wrapper-shop, .wrapper-city {
                    span.select2 {
                        height: 60px !important;
                        border-radius: 10px 0 0 10px !important;
                        span.select2-selection {
                            height: 100%;
                            ul {
                                height: 100%;
                                li {
                                    margin-top: 0;
                                }
                            }
                        }
                        
                    }
                }
                .wrapper-city {
                    span.select2 {
                        border-radius: 0 !important;
                        border-left: 1px solid grey;
                    }
                }
            }
            .wrapper-shop{
                // max-width: 260px;
                span.select2{
                    width: 100%;
                    height: 48px;
                    border-radius: 10px;
                    // .select2-dropdown{
                    //     width: 460px;
                    //     margin-top: 8px;
                    //     border: 0;
                    //     border-radius: 0;
                    //     -webkit-border-radius: 0;
                    //     -moz-border-radius: 0;
                    //     -ms-border-radius: 0;
                    //     -o-border-radius: 0;
                    // }
                    ul{
                        border-right: 1px solid #f1f1f1;
                        @media(max-width: 1200px){
                            border-right: none;
                        }
                        border-radius: 0;
                        input::placeholder{
                            font-size: 14px;
                            font-weight: 300;
                            color: #4a4a4a;
                        }
                    }
                }
            }
            .wrapper-city{
                // max-width: 260px;
                span.select2{
                    width: 100%;
                    height: 48px;
                    border-radius: 10px;
                    
                    input::placeholder{
                        font-size: 14px;
                        font-weight: 300;
                        color: #4a4a4a;
                    }
                }
            }
        }
    }
}

.select2-dropdown {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 7px;
    box-sizing: border-box;
    box-shadow: none;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: 1px solid #aaa;
    border-top-left-radius: 7px;
    border-top-right-radius:  7px;
    margin-bottom: 15px;
}
  
.select2-container--open .select2-dropdown--below {
    border-top: 1px solid #aaa;
    border-top-left-radius:  7px;
    border-top-right-radius:  7px;
    margin-top: 15px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected]{
    background: #4bbbe0;
}

.select2-results__options{
    border-radius: 7px;
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 10px;
}

.select2-results__option {
    padding: 6px;
    user-select: none;
    -webkit-user-select: none;
    border-radius: 7px;
    p {
        margin-bottom: 0;
    }
}

.select2-results__option[aria-disabled=true] {
    opacity: 0.5;
}

li.select2-results__option img {
    background: #fff;
    padding: 5px;
    border-radius: 7px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove{
    margin-right: 10px;
}

span.select2-selection.select2-selection--single {
    background: #fff;
    border-radius: 12px;
    height: 53px;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.select2-container--default .select2-results > .select2-results__options{
    max-height: 300px;
}

.select2-container--definima li.select2-results__option:hover {
    background: #4bbbe0;
    color: #fff;
}

.form-group input.select2-search__field{
    height: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice{
    margin-right: 0;
}


.partenaires {
    margin-bottom: 20px;
    & + .capsule-nav{
        position: static;
        margin-bottom: 20px;
    }
    .partenaire{
        display: flex;
        justify-content: center;
        height: 100px;
    }
}

.nav-pills .nav-link{
    cursor: pointer;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background: #73c8bd;
}