/*-------------------------------------------------------
 ------------------->>> ABOUT et CONTACT <<<----------------------
 -------------------------------------------------------  */

.page-about {

    .banniere {
        @media screen and (min-width: $break-large) {
            min-height: 247px;
        }

        .capsule-titre {
            @media screen and (min-width: $break-large) {
                .logo {
                    img {
                        max-width: 127px;
                        height: auto;
                        padding-top: 0;
                    }
                }
            }
        }
    }

    main {
        overflow: hidden;
    }

    ///la première section est située à 61px du main !!!
    .titles {
        padding-top: 105px;
        @media screen and (min-width: $break-large) {
            padding-top: 60px;
        }

        .text {
            p {
                text-align: left;
                padding: 5px 0;
                margin-bottom: 0;

                &:last-of-type {
                    padding-bottom: 0;
                }

                span {
                    color: $lightBlue;
                    font-weight: 500;
                }
            }
        }
    }


    //////généralité 
    h2 {
        text-align: left;
        color: $lightBlue;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 0;
        padding-bottom: 20px;
        @media screen and (min-width: $break-large) {
            font-size: 30px;
            padding-bottom: 30px;
        }
    }

    .text {
        p {
            text-align: left;
            padding: 10px 0;
            margin-bottom: 0;

            &:last-of-type {
                padding-bottom: 0;
            }

            &:first-of-type {
                padding-top: 0;
            }

            span {
                font-weight: 500;
            }
        }
    }

    ///Sections
    .intro {
        h1 {
            text-align: center;
            color: $lightBlue;
            font-size: 28px;
            font-weight: 900;
            margin-bottom: 0;
            padding-bottom: 30px;
            @media screen and (min-width: $break-large) {
                font-size: 38px;
                padding-bottom: 40px;
            }
        }
    }

    .team {
        padding-top: 40px;

        .capsule-galet {
            position: relative;

            .galet {
                background: rgba(226, 91, 69, 0.3);
                position: absolute;
                left: -350px;
                top: -118px;
                z-index: -1;
                width: 554px;
                height: 459px;
                @include radius(701px 802px 722px 814px /371px 616px 744px 968px);
                @include rotate(48deg);
                @media screen and (min-width: $break-large) {
                    top: -155px;
                }
            }
        }

        .item {
            .content {
                @include alignItems(center);
            }

            .row {
                .centered {
                    margin: 0 auto;
                }
            }

            .auteur {
                @include radius(12px);
                margin-top: 30px;
                color: $white;
                padding: 30px;

                &.housecat {
                    background: $houseCat;
                }

                &.discoverCat {
                    background: $discoverCat;
                }

                &.foodCat {
                    background: $foodCat;
                }

                &.workCat {
                    background: $workCat;
                }

                &.lifestyleCat {
                    background: $lifestyleCat;
                }

                .avatar {
                    width: 150px;
                    height: 150px;
                    overflow: hidden;
                    @include radius(50%);
                    margin: 0 auto;
                    @media screen and (min-width: $break-large) {
                        width: 150px;
                        height: 150px;
                    }
                }

                .about {
                    padding-top: 20px;
                    text-align: center;
                    @media screen and (min-width: $break-small) {
                        padding-top: 0;
                        text-align: left;
                    }

                    .name {
                        font-size: 1.6rem;

                        span {
                            font-weight: 900;
                            text-transform: uppercase;
                        }
                    }

                    p.poste {
                        text-transform: capitalize;
                        font-size: 1rem;
                        margin-bottom: 0;
                    }
                }
            }
            &.special{
                margin:0 auto;
            }
        }
    }

    .newsletter {
        padding-top: 40px;

        .capsule-galet {
            position: relative;

            .galet {
                background: rgba(75, 187, 244, 0.5);
                position: absolute;
                right: -314px;
                top: -118px;
                @include rotate(95deg);
                z-index: -1;
                @media screen and (min-width: $break-large) {
                    top: -305px;
                }
            }
        }

        a {
            font-weight: 500;
            text-decoration: none;
            color: $grey;

            &:hover {
                text-decoration: underline;
                color: $grey;
            }
        }
    }

    .service {
        padding-top: 40px;

        ul {
            list-style: none;
            padding-left: 10px;

            li {
                &:before {
                    content: '•';
                    color: $lightBlue;
                    padding-right: 5px;
                }

                span {
                    font-weight: 500;
                }

                a {
                    font-weight: 500;
                    text-decoration: none;
                    color: $grey;

                    &:hover {
                        text-decoration: underline;
                        color: $grey;
                    }
                }
            }
        }
    }

    .all-team {
        padding-top: 40px;

        .wrapper {
            @media screen and (min-width: $break-large) {
                min-height: 490px;
                padding: 0 15px;
                max-width: 960px;
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;
            }
            @media screen and (min-width: $break-extra-large) {
                min-height: 480px;
                max-width: 1140px;
            }

            .content {
                position: relative;
                @media screen and (min-width: $break-large) {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    margin-right: -15px;
                    margin-left: -15px;
                }

                .pict {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center center;
                    min-height: 205px;
                    padding: 0;
                    @media screen and (min-width: $break-medium) {
                        min-height: 320px;
                    }
                    @media screen and (min-width: $break-large) {
                        @include radius(42px);
                        padding: 0 15px;
                        min-height: 419px;
                    }
                }

                .txt {
                    @include flex (column, null, space-between);
                    background: $white;
                    padding: 30px 20px;
                    @include radius(0px 42px 42px 42px);
                    box-shadow: 0 2px 10px 0 rgba(107, 125, 160, 0.10);
                    max-width: 95%;
                    margin: -35px auto 0 auto;
                    @media screen and (min-width: $break-medium) {
                        max-width: 80%;
                    }
                    @media screen and (min-width: $break-large) {
                        max-width: 100%;
                    }
                    @media screen and (min-width: $break-extra-large) {
                        padding: 45px;
                        position: absolute;
                        top: 30px;
                        right: 0;
                        //min-height: 449px;
                        max-width: 410px;
                        margin: 0;
                    }
                    @media screen and (min-width: $break-super-large) {
                        padding: 45px 65px;
                        max-width: 450px;
                        top: 54px;
                        right: -45px;
                    }

                    p {
                        font-size: 0.87rem;
                        color: $grey;
                        @media screen and (min-width: $break-large) {
                            font-size: 1.2rem;
                        }

                        span {
                            font-weight: 500;
                        }

                        a {
                            font-weight: 500;
                            text-decoration: none;
                            color: $grey;

                            &:hover {
                                text-decoration: underline;
                                color: $grey;
                            }
                        }
                    }
                }
            }
        }
        .logos{
            margin-top:30px;
            .content-logo{
                @include justifyContent(center); 
                img{
                    display:block;
                    margin:auto;
                    max-width:130px;
                }
            }

        }
    }


    .capsule-about {
        @include flex(column, center, center);
        min-height: 55vh;
        overflow: hidden;
        position: relative;
        margin-top: 50px;

        .forme-about {
            position: absolute;
            top: 0;
            left: -10vw; //POUR IE
            width: 120vw;
            @include radius (50% 50% 0 0 /80px 80px 0 0);
            background: $blocPro;
            min-height: 100%;
            @media screen and (min-width: $break-large) {
                @include radius (50% 50% 0 0 /140px 140px 0 0);
            }
        }

        .the-content {
            position: relative;

            h2 {
                padding-top: 60px;
                text-align: center;
            }

            .text {
                padding-top: 20px;
                padding-bottom: 40px;

                h3 {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    font-size: 20px;
                    color: $grey;
                    font-weight: regular;
                    @media screen and (min-width: $break-large) {
                        text-align: center;
                        font-size: 38px;
                    }

                    span {
                        font-weight: 900;
                        color: $lightBlue;
                    }
                }
            }

            .cta {
                margin: 0 auto 2rem;
            }
        }
    }
}

.page-contact {
    header {
        .banniere {
            @media screen and (min-width: $break-large) {
                min-height: 247px;
            }

            .capsule-titre {
                @media screen and (min-width: $break-large) {
                    .logo {
                        img {
                            max-width: 127px;
                            height: auto;
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }

    main {
        overflow: hidden;
    }

    ///la première section est située à 61px du main !!!
    .titles {
        padding-top: 100px;

        .accroche {
            text-align: center;
            font-size: 0.87rem;
            padding-top: 20px;
            padding-bottom: 20px;
            margin: 0 auto;
            @media screen and (min-width: $break-large) {
                font-size: 20px;
            }

        }
    }

    .capsule-contact {
        @include flex(column, center, flex-start);
        min-height: 500px;
        overflow: hidden;
        position: relative;

        .forme-contact {
            position: absolute;
            top: 0;
            width: 120vw;
            left: -10vw; //POUR IE
            @include radius (50% 50% 0 0 /80px 80px 0 0);
            background: $blocPro;
            min-height: 1550px;
            @media screen and (min-width: $break-large) {
                @include radius (50% 50% 0 0 /140px 140px 0 0);
            }
        }

        .the-content {
            padding-top: 3.5rem;
            position: relative;

            .contact-formulaire {
                margin: 0 auto;
                padding-bottom: 40px;

                form {
                    input:not(.btn-cta) {
                        box-shadow: none;
                        padding: 0.6em;
                        border: 1px solid $grey;
                        height: 45px;
                        font-size: 1rem;
                    }

                    input,
                    select,
                    textarea {
                        width: 100%;
                        margin-bottom: 10px;

                    }


                }
            }

            .contacts {
                margin: 0 auto;

                .cta-contact {
                    text-align: center;
                    padding-bottom: 2rem;
                    font-size: 0.87rem;
                    @media screen and (min-width: $break-large) {
                        font-size: 20px;
                    }

                    a {
                        color: $grey;
                        font-weight: 500;

                        &:hover {
                            color: $grey;
                            text-decoration: underline;
                        }
                    }

                }
            }
        }
    }
}

.page-404 {
    header {
        .banniere {
            @media screen and (min-width: $break-large) {
                min-height: 247px;
            }

            .capsule-titre {
                @media screen and (min-width: $break-large) {
                    .logo {
                        img {
                            max-width: 127px;
                            height: auto;
                            padding-top: 0px;
                        }
                    }
                }
            }
        }
    }

    main {
        .the-content {
            min-height: 50vh;
            @include flex(column, null, flex-start);
            text-align: center;

            h1 {
                color: $lightBlue;
                padding-top: 5rem;
                font-size: 100px;
            }

            .cta {
                padding-top: 2rem;

                .btn-cta {
                    max-width: 335px;
                }
            }
        }
    }
}