/*-------------------------------------------------------
 ------------------->>> FOOTER <<<----------------------
 -------------------------------------------------------  */

.footer {

    .container-news{
        background:$deepRed;
        overflow:hidden;
        .the-content{
            position:relative;
            @include flexDirection(row);
            @include justifyContent (space-between);
            @include alignItems (center);
            padding:1rem 0;
            @media screen and (min-width: $break-large) {
                padding:3.12rem 0;  
            }   
            .about{
                color:$white;
                font-size:2rem;
                font-weight:500;
                span.highlighted{
                    color: $white;
                    display:inline;
                    font-weight:bold;
                    font-size:2rem;
                }
                span{
                    display:block;
                    font-weight:normal;
                    font-size:1.37rem;  
                }
            }
            .news-form{
                padding:1rem 15px;
                @media screen and (min-width: $break-large) {
                    padding:initial;
                }
                .input-group{
                   height: 55px; 
                   input{
                       height: 55px; 
                       border:0; 
                       @include radius(50px 0 0 50px);
                       padding-left: 25px;
                   }
                   .input-group-append{
                       height: 55px; 
                       button{
                           border:0;
                           @include radius(0 50px 50px 0);
                           color:$deepRed;
                           font-weight:bold;
                           font-size:1.12rem;
                           background:$white;
                           padding-right:25px;
                       }
                   }
                }
            }
            .galet.left{
                position:absolute;
                top: 50%;
                left: -30%;
                @include translate(-35%, -11%);
                display:none;
                @media screen and (min-width: $break-large) {
                    display:block;
                }

            }
            .galet.right{
                position:absolute;
                top: 32%;
                right: -20%;
                @include translate(16%, -85%);
                @media screen and (min-width: $break-super-large) {
                    right: -31%
                }
            }
        }
    }
    .container-top{
        background:$lightBlue;
        .the-content{
            padding:1.25rem 0;
            @include flexDirection(row);
            @include justifyContent (space-between);
            @include alignItems (center);
            .logo {
                text-align:center;
            }
            ul.menu-footer{
                margin:0;
                padding:0;
                @include flex(column, null, null);
                padding:1rem 0;
                @media screen and (min-width: $break-small) {
                    @include flex(row, center, center);
                    padding:initial;
                }
                li{
                    a{  
                        display:block;
                        position:relative;
                        color:$white;
                        padding:0.5rem 0;
                        font-size:0.9rem;
                        @media screen and (min-width: $break-small) {
                            font-size:0.9rem;
                            padding:2rem 0.5rem;
                            &:before{
                                content: '|';
                                right: 0;
                                position: absolute;
                            }
                        }
                        @media screen and (min-width: $break-large) {
                            font-size:1rem;
                            padding:2rem 1rem;
                        }
                    }
                    @media screen and (min-width: $break-small) {
                        &:last-child{
                            a{
                                &:before{
                                    content: '';

                                }
                            }
                        }
                    }
                }

            }
            .socials{
                @include justifyContent (center);
                @media screen and (min-width: $break-large) {
                    @include justifyContent (flex-end);
                }
                .rs{
                    .fab{
                        color:$white;
                        font-size: 2rem;
                        @media screen and (min-width: $break-medium) {
                            font-size: 1.3rem;
                        }
                         @media screen and (min-width: $break-large) {
                            font-size:2rem;
                        }
                    }
                } 
            }
        }

    }
    .container-bottom{
        .the-content{
            padding:1.25rem 15px;
            @include flexDirection(row);
            @include justifyContent (flex-start);
            @include alignItems (center);
            @media screen and (min-width: $break-large) {
                @include justifyContent (center);
                padding:0;
            }
        }
        ul.menu-bottom-footer{
            @include flex(column, null, null);
            margin:0;
            padding:0;
            @media screen and (min-width: $break-medium) {
                width:100%;
                @include flex(row, center, center);
            }
            li{
                a{
                    color:$grey;
                    position:relative;
                    display:block;
                    padding:0.5rem 0;
                    font-size:0.8rem;
                    @media screen and (min-width: $break-medium) {
                        padding: 0.5rem 1rem;
                        &:before{
                            content: '|';
                            right: 0;
                            position: absolute;
                        }
                    }
                    @media screen and (min-width: $break-large) {
                        padding: 1rem;
                        font-size:1rem;
                    }
                }
                @media screen and (min-width: $break-medium) {
                    &:last-child{
                        a{
                            &:before{
                                content: '';

                            }
                        }
                    }
                }
            }
        }
    }

}
