/*-------------------------------------------------------
 ------------------->>> LOGIN<<<----------------------
=> la vue login et register
 -------------------------------------------------------  */

.container-mon-compte {
    margin-top: 3rem;
    .content {
        margin: 2rem auto;

        .bloc1 {
            margin-bottom: 1rem;

            .pass {
                margin-bottom: 0.5rem;
            }
        }
        .bloc2 {
            margin-bottom: 1rem;

            .communes {
                .select2-container,
                input.form-control.same-select {
                    display: block !important;
                    margin: 0 auto !important;
                    @media screen and (min-width: $break-small) {
                        display: inline-block !important;
                        margin: 0 !important;
                    }
                }
            }
        }
        .bloc3 {
            .question-categories {
                margin-bottom: 1rem;
            }

            .check-categs {
                margin-bottom: 0.5rem;
            }
        }
    }
}
