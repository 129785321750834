header {
    .container .searching .search-form .select2-container--search span.select2-dropdown {
        .select2-results__option {
            display: flex;
        }
        .content-sug h1 {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #e25b45;
            margin-bottom: 20px;
            text-align: left;
        }
        .the-sug{
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: rgba(226, 91, 69, 0.25) 1px solid;
            .sugestion-presentation{
                display: flex;
                margin-bottom: 10px;
                align-items: center;
                img {
                    margin-right: 10px;
                }
                div {
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.61;
                    letter-spacing: normal;
                }
            }
            &:last-child {
                border: none;
            }
            .contain-info {
                display: table;
                line-height: 1;
                div {
                    display: flex;
                    justify-content: flex-start;
                }
                .suggestion-user {
                    p {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #4a4a4a;
                    }
                }
                .suggestion-city {
                    font-size: 12px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #4a4a4a;
                }
            }
        }
    }
}

.breadcrumb{
    background:none;
    padding: 1rem 0 1rem 30px;
    @include justifyContent (space-between);
    @include alignItems (center);
    margin-bottom:0;
    .btn-back{
        font-size:0.75rem;
        font-weight:500;
        color:$deepRed;
        span{
            text-decoration:underline;
        }
        &:before{
            content:'<';
            text-decoration:none;
            padding-right: 0.3rem;
        }
    }
    .fil{
        font-size:0.75rem; 
        text-transform:capitalize;
        padding:0.3rem 15px;
        @media screen and (min-width: $break-large) {
            padding:0 15px;
        }
        span:not(:last-child){
            font-weight:regular;
            &:after{
                content:'|';
                padding: 0 0.2rem;
            }
        }
        span:last-child{
            font-weight:bold;
        }
    }
}

.search header .align-sponsors .banniere {
    width: 100%;
    background-position-x: 100%;
    background-position-y: -70px;
    background-size: 30%;
    height: 550px;
    display: flex;
    //justify-content: center;
    background-color: #84c6d0;
    //background-image: none;
}

.search .listing-container {
    .titles a{
        font-weight: 500;
        text-decoration: underline;
        color: #4a4a4a;
    }
}

@media (max-width: 991px) {
    .search header .align-sponsors .banniere {
        height: 0;
    }
    .search .listing-container {
        padding-top: 15px;
    }
}

.search{
    header .align-sponsors {
        height: 250px;
    }
    main{
        overflow-x:hidden;
    }
    header{
        @media (max-width: 992px) {
            .align-sponsors {
                display: none;
            }
        }
        .logos-sponsors {
            background-image: none;
        }
        .align-sponsors .banniere{
            //background-image: none;
            height: 100%;
            //@media screen and (min-width: $break-large) {
            //    min-height:340px;
            //}
            .capsule-titre{
                position: relative;
                z-index: 9;
                padding-top: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 100px;
                max-width: 800px;
                line-height: 2em;
                padding-right: 130px;
                margin-left: 10px;
                h1 {
                    padding-top: 2rem;
                    color: #fff;
                    text-align: left;
                    font-weight: 300;
                    padding-bottom: 1.2rem;
                    line-height: 2em;
                    font-size: 1.5rem;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .search .listing-container .bloc-listing .search-formulaire button.btn-cta span {
        display: none;
    }
    .search .listing-container .bloc-listing .search-formulaire .form-group {
        margin-bottom: 0.25rem;
        .select2-container .select2-search--inline .select2-search__field {
            margin-top: 3px;
        }

    }
    .search .listing-container .bloc-listing .search-formulaire .cherche {
        padding-left: 0;
    }
}



// .contain-info {
//     display: flex;
//     flex-direction: column;
//     line-height: 1;
//     justify-content: flex-start;
//     .sugestion-presentation {
//         display: flex;
//     }