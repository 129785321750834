/*  -------------------------------------------------------
    ------------------->>> FONTS <<<----------------------
    -------------------------------------------------------  */


$Now: 'Now', sans-serif;


/*now */ 

@font-face {
    font-family: 'Now';
    src: 
        url('#{$fontPath}now/Now-Regular.eot') format('eot'),
        url('#{$fontPath}now/Now-Regular.woff2') format('woff2'),
        url('#{$fontPath}now/Now-Regular.woff') format('woff'),
        url('#{$fontPath}now/Now-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src: 
        url('#{$fontPath}now/Now-Light.eot') format('eot'),
        url('#{$fontPath}now/Now-Light.woff2') format('woff2'),
        url('#{$fontPath}now/Now-Light.woff') format('woff'),
        url('#{$fontPath}now/Now-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src:
        url('#{$fontPath}now/Now-Bold.eot') format('eot'),
        url('#{$fontPath}now/Now-Bold.woff2') format('woff2'),
        url('#{$fontPath}now/Now-Bold.woff') format('woff'),
        url('#{$fontPath}now/Now-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src: 
        url('#{$fontPath}now/Now-Black.eot') format('eot'),
        url('#{$fontPath}now/Now-Black.woff2') format('woff2'),
        url('#{$fontPath}now/Now-Black.woff') format('woff'),
        url('#{$fontPath}now/Now-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src: 
        url('#{$fontPath}now/Now-Thin.eot') format('eot'),
        url('#{$fontPath}now/Now-Thin.woff2') format('woff2'),
        url('#{$fontPath}now/Now-Thin.woff') format('woff'),
        url('#{$fontPath}now/Now-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src: 
        url('#{$fontPath}now/Now-Medium.eot') format('eot'),
        url('#{$fontPath}now/Now-Medium.woff2') format('woff2'),
        url('#{$fontPath}now/Now-Medium.woff') format('woff'),
        url('#{$fontPath}now/Now-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

