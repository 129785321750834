/*-------------------------------------------------------
 ------------------->>> LOGIN<<<----------------------
=> la vue login et register
 -------------------------------------------------------  */
.page-login,
.page-register,
.page-reset-password,
.welcome-client,
.compte-client,
.modif-password,
.confirm-modif-password{
    //header{
    //    .banniere{
    //        ///min-height:216px;
    //        @media screen and (min-width: $break-large) {
    //            min-height:247px;
    //        }
    //        .capsule-titre{
    //            @media screen and (min-width: $break-large) {
    //                .logo{
    //                    img{
    //                        max-width: 127px;
    //                        height: auto;
    //                        padding-top: 0;
    //                    }
    //                }
    //            }
    //        }
    //    }
    //}
    >.container{
        padding: 120px 15px 30px 15px;
        @media screen and (min-width: $break-large) {
            padding: 30px 15px;
        }
        .breadcrumb{
            background: none;
            padding: 30px 0;
            @include justifyContent (space-between);
            @include alignItems (center);
            margin-bottom:0;

            .fil{
                font-size:0.75rem;
                text-transform:capitalize;
                padding:0.3rem 15px;
                @media screen and (min-width: $break-large) {
                    text-align: center;
                    padding: 0 15px;
                }

                span:not(:last-child) {
                    font-weight: regular;

                    &:after {
                        content: '|';
                        padding: 0 0.2rem;
                    }
                }

                span:last-child {
                    font-weight: bold;
                }
            }
        }
    }
    h2{
        text-align:center;
        color:$grey;
        font-size:28px;
        padding:0 15px;
        @media screen and (min-width: $break-large) {
            font-size:42px;
            text-align:center;
        }
        span{
            color:$lightBlue;
            font-weight:500;
        }
    }
    h3{
        font-size:22px;
        font-weight:300; 
        margin-bottom:0;
        padding-bottom:30px;
        span{
            color:$lightBlue;
            font-weight:500;
        }
    }
    label.required{
        &:after{
            content:'*';
        }
    }
}


//page de connexion
.page-login{
    .subtitle{
        text-align:center;
        padding-bottom:20px;
    }
    .content{
        @include justifyContent(center);
        padding-top:40px;
        .login,
        .registration{
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
            padding:40px;
            margin: 0 auto;
            max-width:100%;
            .content-form{
                @media screen and (min-width: $break-medium) {
                    min-height:166px;
                }
                @media screen and (min-width: $break-large) {
                    min-height:146px;
                }
            }
            button.btn-cta{
                width:210px;
            }
        }
        .registration{
            margin-top:20px;
            @media screen and (min-width: $break-medium) {
                margin-top:0;
            }
        }
        .about-user{
            padding:15px 0;
            text-align:center;
        }
        .facebook-bloc{
            margin-top:20px;
            padding:40px;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
            background:rgba(75,187,224,0.28);
            h3{
                text-align:center;
                margin-bottom:0;
            }
            .btn-cta.facebook{
                width:210px;
                margin-top:10px;
            }
        }
    }


}
//page d'inscription'
.page-register{
    h2{
        span.first{
            display:block;
        }
    }
    .select2-selection{
        box-shadow: none;
        padding: 0.6em;
        border: 1px solid #4a4a4a;
        height: 45px;
        font-size: 0.87rem;
        @include radius(0);
        width: 100%; 
    }
    
    .select2-container--default .select2-selection--single .select2-selection__arrow{
       top: 8px; 
    }
    //dans la vue mais en dehors de son block car après les script
    .select2-drop-active{
        border:1px solid $grey;
    }
    .select2-results .select2-highlighted {
        background: $lightBlue;
        color: #fff;
    }
    .subtitle{
        text-align:center;
        padding-bottom:20px;
    }
    .content{
        .bloc-content{
            margin: 0 auto; 
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
            padding:40px;
            max-width: 380px;
            @media screen and (min-width: $break-small) {
                max-width:580px;
            }
            @media screen and (min-width: $break-medium) {
                max-width:635px;
            }
            @media screen and (min-width: $break-large) {
                max-width:770px;
            }
            @media screen and (min-width: $break-extra-large) {
                max-width:950px;
            }
            form{
                label{
                    margin-bottom:1rem;///pour faire echo au padding repeat password
                    @media screen and (min-width: $break-small) {
                        margin-bottom:0.5rem;
                    } 
                    @media screen and (min-width: $break-large) {
                        margin-bottom:0;
                    } 
                }
                .form-group{
                    @include alignItems(center);
                }
                .select2-container{
                    @media screen and (min-width: $break-small) {
                        max-width:100%;
                    } 
                    a.select2-choice{
                        height: 45px;
                        @include radius(0);
                        box-shadow:0;
                    }  
                }
            } 
            .bloc-top{
                .password-input{
                    >div {
                        margin-bottom: 1rem;
                        @media screen and (min-width: $break-small) {
                            margin-bottom:0.5rem;
                        }
                        label{
                            margin-bottom:0;
                        }
                    }
                }
                .content-civi{
                    .civilite{
                        @media screen and (min-width: $break-small) {
                            @include alignItems(center);
                        }
                        .form-check{
                            padding-top:10px;
                            padding-left:0;
                            @media screen and (min-width: $break-large) {
                                padding-left:15px;
                                padding-top:0;
                            } 
                        }
                    }
                }

            }
            .bloc-bottom{
                padding-top:30px;
                padding-bottom:30px;
                .inscription{
                    .first{
                        @media screen and (min-width: $break-large) {
                            padding-left:15px;
                        } 
                        .form-check{
                            padding-left:0;
                            @media screen and (min-width: $break-large) {
                                padding-left:0;
                            }
                        }
                    }
                    .second{
                        .form-check{
                            padding-left:0;
                            @media screen and (min-width: $break-large) {
                                padding-left:0;
                            }
                        }
                    }
                }
            }
            .bloc-end{
                .centre-interet{
                    .about{
                        padding-bottom:20px;
                        @media screen and (min-width: $break-large) {
                            padding-left:15px;
                            padding-bottom:10px;
                        }
                    }
                    .all-input{
                        @media screen and (min-width: $break-large) {
                            padding-left:15px;
                        } 
                        .form-check{
                            padding-left:0;
                            @media screen and (min-width: $break-large) {
                                padding-left:0;
                            }
                        }
                    }
                }
                .captcha{
                    .input-captcha{
                        width: auto;
                    }
                }
                .acceptCGU{
                    padding-top: 25px;
                    @include flex(null, null, null);
                    input{
                        padding-right: 15px;
                    }
                }
            }
            .bloc-submit{
                padding-top:30px;
                .btn-cta{
                    width:190px;
                }
            }
        }

    }
    .acceptCGU{
        .form-check{
            padding-left:0;
        }
    }
}
//page de modification de password
.page-reset-password{
    .content{
        margin: 0 auto; 
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
        padding:40px;
        max-width: 380px;
        margin-top: 40px;
        @media screen and (min-width: $break-small) {
            max-width:580px;
        }
        @media screen and (min-width: $break-medium) {
            max-width:635px;
        }
        @media screen and (min-width: $break-large) {
            max-width:770px;
        }
        @media screen and (min-width: $break-extra-large) {
            max-width:950px;
        }
        h3{
            text-align:center;
        }
        .form-group{
            @include alignItems (center);
            @include justifyContent (center);
            .label{
                @media screen and (min-width: $break-large) {
                    text-align:right;
                }

            }
        }
        .btn-cta{
            width:210px;
        }
    }
}
//page de confirmation de demande d'un nouveau mot de passe
.confirm-modif-password{
    .content{
        margin: 0 auto; 
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
        padding:40px;
        max-width: 380px;
        margin-top: 40px;
        @media screen and (min-width: $break-small) {
            max-width:580px;
        }
        @media screen and (min-width: $break-medium) {
            max-width:635px;
        }
        @media screen and (min-width: $break-large) {
            max-width:770px;
        }
        @media screen and (min-width: $break-extra-large) {
            max-width:950px;
        }
        h3{
            text-align:center;
        }
        p{
            margin:0;
            text-align:center;
        }
    }
}

//page de réinitialisation d emot d epasse
.modif-password{
    .content{
        margin: 0 auto; 
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
        padding:40px;
        max-width: 380px;
        margin-top: 40px;
        @media screen and (min-width: $break-small) {
            max-width:580px;
        }
        @media screen and (min-width: $break-medium) {
            max-width:635px;
        }
        @media screen and (min-width: $break-large) {
            max-width:770px;
        }
        @media screen and (min-width: $break-extra-large) {
            max-width:950px;
        }
        h3{
            text-align:center;
        }
        p{
            margin:0;
            text-align:center;
        }
        .form-group{
            @include alignItems (center);
            @include justifyContent (center);
            .label{
                @media screen and (min-width: $break-large) {
                    text-align:right;
                }

            }
        }
        .btn-cta{
            width:210px;
        }
    }
}

//page d'accueil une fois le client connecté
.welcome-client{
    .content{
        margin: 0 auto; 
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
        padding:40px 20px;
        max-width: 380px;
        margin-top: 40px;
        @media screen and (min-width: $break-small) {
            max-width:580px;
        }
        @media screen and (min-width: $break-medium) {
            max-width:635px;
        }
        @media screen and (min-width: $break-large) {
            max-width:770px;
            padding:40px;
        }
        @media screen and (min-width: $break-extra-large) {
            max-width:950px;
        }
        h3{
            text-align:center;
        }
        p{
            text-align:center;
        }

        .actions{
            >div{
                margin-top:10px;
                text-align:center;
                margin-bottom:10px;
                @media screen and (min-width: $break-large) {
                    margin-bottom:0;
                    margin-top:20px;
                }
                a{
                    font-weight:500;
                    &:hover{
                        color:$lightBlue;
                        span{
                            color:$lightBlue;
                        }
                        .fas{
                            color:$lightBlue;
                        }
                    }
                    span{
                        color:$lightBlue;
                    }
                    .picto{
                        padding-top:10px;
                        color:$black;
                        font-size:1.5rem;
                    }
                }
            }
        }
    }
}

//la page compte une fois le client connecté :compte +favoris + demandes
.compte-client{
    //dans la vue mais en dehors de son block car après les script
    .select2-drop-active{
        border:1px solid $grey;
    }
    .select2-results .select2-highlighted {
        background: $lightBlue;
        color: #fff;
    }
    //vue compte client
    .content{
        margin: 0 auto; 
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
        padding:40px 20px;
        max-width: 380px;
        //margin-top: 40px;
        @media screen and (min-width: $break-small) {
            max-width:580px;
        }
        @media screen and (min-width: $break-medium) {
            max-width:635px;
        }
        @media screen and (min-width: $break-large) {
            max-width:770px;
            padding:40px;
        }
        @media screen and (min-width: $break-extra-large) {
            max-width:950px;
        }
        h3{
            text-align:left;
            @media screen and (min-width: $break-large) {
                min-height:82px;
            }
            @media screen and (min-width: $break-extra-large) {
                min-height:initial;
            }
        }
        //Pour le datepicker sinon il plante...
        .dropdown-menu{
            display: block !important;
        }
        p{
            text-align:center;
        }
        .select2-container{
            border: 1px solid #4a4a4a;
            @media screen and (min-width: $break-small) {
                max-width:100%;
            } 
            a.select2-choice{
                height: 45px;
                @include radius(0);
                box-shadow:0;
            }
            .select2-selection{
                border: none;
            }
        }
        .form-group{
            @include alignItems(center);
        }
        .bloc:not(:first-child){
            padding-top:15px;
        }
        .bloc:not(:last-child){
            padding-bottom:40px;
            @media screen and (min-width: $break-large) {
                padding-bottom:15px;
            } 
        }
        .bloc{
            .form-check{
                padding-left:0;
                @media screen and (min-width: $break-large) {
                    padding-left:15px;
                } 
            }
        }
        .content-civi{
            .civilite{
                @media screen and (min-width: $break-small) {
                    @include alignItems(center);
                }
                @media screen and (min-width: $break-large) {
                    min-height:45px;
                }
                .form-check{
                    padding-top:10px;
                    padding-left:0;
                    @media screen and (min-width: $break-large) {
                        padding-left:15px;
                        padding-top:0;
                    } 
                }
            }
        }
        .centre-interet{
            .about{
                padding-bottom:20px;
                @media screen and (min-width: $break-large) {
                    padding-left:15px;
                    padding-bottom:10px;
                }
            }
            .all-input{
                @media screen and (min-width: $break-large) {
                    padding-left:15px;
                } 
                .form-check{
                    padding-left:0;
                    @media screen and (min-width: $break-large) {
                        padding-left:0;
                    }
                }
            }
        }
        .bloc-submit{
            padding-top:30px;
            .btn-cta{
                width:190px;
            }
        }
    }
    ///vue favoris : overdide de compte client et style particulier
    .favoris-clients{
        max-width: 100%;
        padding:40px 20px;
        @media screen and (min-width: $break-extra-large) {
            padding:40px;
        }
        .bloc-content{
            padding:0;
            @media screen and (min-width: $break-large) {
                padding:initial;
            }
        }
        h3{
            text-align:center;
            padding:40px 0 20px 0;
        }
        .annonces{
            .vignette-annonce {
                padding-left: 0;
                padding-right: 0;
                .capsule {
                    //min-height: 425px;
                    max-width:300px;
                    margin: 0 auto 20px auto;
                    &:hover{
                        .bottom-card{
                            bottom: 4%;
                        }
                    }
                    .bottom-card{
                        // left: 6.6%;
                        width: 100%;
                    }
                    /*
                    .picto {
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        height: 40px;
                        width: 40px;
                        @include radius(12px);
                    }
                    .infos {
                        padding: 21px;
                        margin: 20px 0 0 0;
                        background: $white;
                        @include radius(12px);
                        min-height:238px;
                        .city {
                            color: $grey;
                            font-weight: 300;
                            font-size: 0.62rem;
                            text-transform: capitalize;
                            padding-bottom: 10px;
                        }
                        .place {
                            font-weight: bold;
                            font-size: 1.12rem;
                            padding-bottom: 5px;
                            min-height: 57px;
                        }
                        .offer {
                            color: $grey;
                            font-weight: normal;
                            font-size: 1rem;
                            padding-bottom: 35px;
                            min-height: 73px;
                            flex-wrap: wrap;
                        }
                        .price {
                            @include flex(row, baseline, space-between);
                            .new {
                                font-size: 1.37rem;
                                font-weight: 900;
                            }
                            .old {
                                font-size: 0.625rem;
                                color: $grey;
                            }
                            .time {
                                padding-left: 16px;
                                color: $deepRed;
                                font-size: 0.75rem;
                                &:before {
                                    color: $deepRed;
                                    content: '\f017';
                                    font-family: "Font Awesome 5 Free";
                                    font-weight: 400;
                                    font-size: 0.75rem;
                                    padding-right: 6px;
                                }
                            }
                        }
                    }
                    .bottom {
                        padding: 15px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        @include radius(0 0 12px 12px);
                        @include flex (row, center, space-between);
                        span {
                            a {
                                color: $white;
                                font-weight: bold;
                                font-size: 0.68rem;
                            }
                            &.bookmark {
                                position: relative;
                                color: $white;
                                width: 50%;
                                font-size: 0.6rem;
                                @media screen and (min-width: 710px) {
                                    width: 63%;
                                }
                                @media screen and (min-width:$break-medium) {
                                    width: 50%;
                                }
                                @media screen and (min-width:$break-extra-large) {
                                    width: 66%;
                                }
                                &:after {
                                    content: '|';
                                    right: 0;
                                    position: absolute;
                                    color: $white;
                                }
                                .hide {
                                    display: none;
                                    @media screen and (min-width: 710px) {
                                        display: inline;
                                    }
                                    @media screen and (min-width: $break-medium) {
                                        display: none;
                                    }
                                    @media screen and (min-width: $break-extra-large) {
                                        display: inline;
                                    }
                                }
                            }
                            &.like {
                                padding-right: 5px;
                                font-size: 0.6rem;
                                color: $white;
                            }
                        }
                    }*/
                }
            }
        }
        .boutiques{
            .vignette {/*
                margin-bottom: 75px; //les 30 + les 44 de débord du bas de la vingette
                padding-left: 0;
                padding-right: 0;
                @media screen and (min-width: $break-medium) {
                    padding-left: inherit;
                    padding-right: inherit;
                    margin-bottom: 59px; 
                }
                .capsule {
                    margin: 0 5px;
                    @include radius(12px);
                    padding-top: 30px;
                    position: relative;
                    min-height: 385px;
                    //  height:335px;//POUR IE
                    max-width:350px;
                    margin: 0 auto 60px auto;
                    @media screen and (min-width: $break-small) {
                        min-height: 335px;
                    }
                    @media screen and (min-width: $break-medium) {
                        padding-top: 25px;
                        min-height: 266px;
                        ///height:266px;//POUR IE
                    }
                    .top-infos {
                        color: $white;
                        text-align: center;
                        padding: 0 15px;
                        .city {
                            font-weight: 300;
                            font-size: 18px;
                            padding-bottom: 5px;
                        }
                        .place {
                            font-weight: bold;
                            font-size: 26px;
                            padding-bottom: 5px;
                        }
                    }
                    .capsule-bottoms {
                        position: absolute;
                        left: 20px;
                        right: 20px;
                        bottom: -39px;

                        @media screen and (min-width: $break-large) {
                            bottom: -44px;
                        }
                        .bottom-infos {
                            background: $white;
                            padding: 25px 20px;
                            @include radius(12px 12px 0 0);

                            .infos {
                                @include flexDirection(row);
                                @include alignItems (center);

                                .avatar {
                                    width: 78px;
                                    height: 78px;
                                    overflow: hidden;
                                    @include radius (50%);
                                    margin: 0 auto;
                                    @media screen and (min-width: $break-medium) {
                                        margin: 0;
                                    }
                                }
                                .user {
                                    .name {
                                        font-size: 1rem;
                                        font-weight: 500;
                                        text-align: center;
                                        @media screen and (min-width: $break-medium) {
                                            text-align: left;
                                        }
                                    }
                                    p.testimonial {
                                        font-size: 0.87rem;
                                        font-weight: normal;
                                        text-align: center;
                                        margin: 0;
                                        @media screen and (min-width: $break-medium) {
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                        }
                        .bottom {
                            padding: 10px;
                            @include radius(0 0 12px 12px);
                            @include flex(row, center, space-between);
                            font-size: 0.8rem;
                            @media screen and (min-width: $break-large) {
                                font-size: 1rem;
                                padding: 10px 15px;
                            }
                            span {
                                a {
                                    font-weight: bold;
                                    font-size: 0.68rem;
                                }
                                &.bookmark {
                                    position: relative;
                                    width: 50%;
                                    font-size: 0.68rem;
                                    @media screen and (min-width: 710px) {
                                        width: 63%;
                                    }
                                    @media screen and (min-width:$break-medium ) {
                                        width: 50%;
                                    }
                                    @media screen and (min-width:$break-large) {
                                        width: 66%;
                                    }
                                    &:after {
                                        content: '|';
                                        right: 0;
                                        position: absolute;
                                    }
                                    .hide {
                                        display: none;
                                        @media screen and (min-width: 710px) {
                                            display: inline;
                                        }
                                        @media screen and (min-width:$break-medium ) {
                                            display: none;
                                        }
                                        @media screen and (min-width:$break-large ) {
                                            display: inline;
                                        }
                                    }
                                }
                                &.like {
                                    padding-right: 5px;
                                    font-size: 0.68rem;
                                }
                            }
                        }
                    }
                }*/
                &:nth-child(3) {
                    margin-bottom: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .agendas{
            .item{
                max-width:350px;
                margin: 0 auto 20px auto;
                .pict{
                    height: 353px;
                    background-size:cover;
                    background-repeat:no-repeat;
                    background-position:center center;
                    @include radius(12px 12px 0 0);
                }
                .text{
                    position:relative;
                    background:$agendaColor;
                    color:$white;
                    padding:30px;
                    @include radius( 0 0 12px 12px);
                    @media screen and (min-width: $break-large) {
                        min-height:210px;
                    }
                    .dates{
                        font-weight:900;
                        font-size:0.7rem;
                        padding-bottom:0.5rem;
                        @media screen and (min-width: $break-large) {
                            font-size:0.87rem;
                        }
                    }
                    .title{
                        font-size:1rem;
                        padding:0.5rem 0;
                        min-height:64px;
                        @media screen and (min-width: $break-medium) {
                            min-height:64px;
                        }
                        @media screen and (min-width: $break-large) {
                            padding-top:10px;
                            padding-bottom:35px;
                            min-height:93px;
                        }
                        @media screen and (min-width: $break-extra-large) {
                            min-height:156px;
                            font-size:1.5rem;
                        }
                    }
                    a.read{
                        color:$white;
                        font-weight:900;
                        font-size:0.87rem;
                        text-decoration:underline;
                        text-transform:uppercase;
                    }
                    .like{
                        position:absolute;
                        top: -35px;
                        right: 30px;
                        cursor:pointer;
                        .capsule-like{
                            width:52px;
                            height:52px;
                            @include radius(50%);
                            background:$white;
                            span{
                                color:$agendaColor;
                                display: block;
                                font-size:1.5rem;
                                line-height: 52px;
                                text-align: center;
                                i{
                                    vertical-align:middle;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ///vue demande : overdide de compte client et style particulier
    .demandes-clients{
        .devis{
            margin-bottom:40px;
            @media screen and (min-width: $break-medium) {
                margin-bottom:0;
            }
        }
        .all-item{
            .item{
                padding-bottom: 20px;
                .user{
                    padding-bottom:5px;
                    span{
                        text-transform:capitalize;
                        font-weight:bold;
                    }
                }
                .mail{
                    color:$lightBlue;
                    padding-bottom:10px;
                }
                .date{
                    padding-bottom:5px;
                }
                .message{}
            }
        }
    }
}