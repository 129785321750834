/*-------------------------------------------------------
 ------------------->>> VARIABLES <<<----------------------
 -------------------------------------------------------  */
    

$black: #000;
$white: #fff;
$grey:#4a4a4a;
$deepGrey:#9B9B9B;

$blogColor:#DE5B6F;
$agendaColor:#73C8BD ;
$lightWhite:#F6F6F6; 

$deepRed:#e25b45;
$lightBlue:#4bbbe0;

$houseCat:#6B7DA0;
$foodCat:#FAAB66;
$lifestyleCat:#F67452;
$discoverCat:#73C8BD;
$workCat:#478AA1;
$chillCat:#F1BDC5;

 
$blogSortie:#FAAB66;
$blogReceipe:#F67452;
$blogCulture:#6B7DA0;
$blogPlan:#DE5B6F;
$blogAgenda:#73C8BD;


$blocPro:rgba(254, 114, 67, 0.10);


//FONTS
$fontPath: '../../fonts/';

//IMAGES
$imgPath: '../../img/';



/*  -------------------------------------------------------
    ------------------->>> BREAK POINTS  <<<----------------------
    -------------------------------------------------------  */

$break-small: 576px;
$break-medium: 768px;
$break-large: 992px;
$break-extra-large: 1200px;
$break-super-large: 1500px;
$break-end: 1900px;