/*-------------------------------------------------------
 ------------------->>> HEADER <<<----------------------
 -------------------------------------------------------  */


header {
    //pour cacher le passage du galet up and down
    .wrapper-top{
        @media screen and (min-width: $break-large) {
            background:$white;
        }
    }
    .top{
        display:none;
        @media screen and (min-width: $break-large) {
            display:block;
            font-size:0.75rem;
            background: white;
            z-index: 2;
        }
        .wrap{
            @include flex(row, center, space-between);
            .about{
                width:50%;
                @media screen and (min-width: $break-large) {
                    width:45%;
                }
               
                .the-content{
                    @include flex(row, center, flex-start);
                    >div{
                        >a{
                            padding: 0.7rem 0;
                            display:block;
                        }
                    }

                    .socials{
                        width:76px;
                        @include flex(row, center, space-between);
                        .rs{
                            .fab{
                                color:$lightBlue;
                                font-size:1.12rem;
                            }
                        }
                    }
                    .infos{
                        @include flex(row, center, null);
                        width: 330px;
                        padding-left: 30px;
                        .actu{
                            &:after{
                                content: '|';
                                margin-left: 0.5rem;
                                margin-right: 0.5rem;
                            }
                        }
                    }
                }
            }
            .actions{
                width:50%;
                @media screen and (min-width: $break-large) {
                    width:55%;
                }
                .the-content{
                    @include justifyContent (flex-end);
                    >div{
                        text-align:center;
                        >a{
                            padding: 0.7rem 0;
                            display:block;
                        }
                    }
                    .event{
                        text-transform:uppercase;
                        @media screen and (min-width: $break-large) {
                            max-width: 30%;
                            flex: 0 0 30%; 
                        } 
                        @media screen and (min-width: $break-super-large) {
                            max-width: 25%;
                            flex: 0 0 25%; 
                        }  
                    }
                    .highlighted-element{
                        background:$deepRed;
                        font-weight:500;
                        a{
                            color:$white;
                        }
                        .fas{
                            color:$white;
                        }
                    }
                    .bookmark,
                    .account{
                        img{
                            padding-right:10px;
                        }
                    }
                    .pro{
                        @media screen and (min-width: $break-large) {
                            max-width: 20%;
                            flex: 0 0 20%; 
                        } 
                        @media screen and (min-width: $break-super-large) {
                            max-width: 25%;
                            flex: 0 0 25%; 
                        }  
                    }
                }
            }
        }
        &.sticked{
            position: fixed;
            top: 0;
            z-index: 99;
            max-width: 100%;
            background: white;
            left: 0;
            right: 0;
            height:40px;
            .wrap{
                @media screen and (min-width: $break-large) {
                    max-width:960px;
                    margin:0 auto;
                }
                @media screen and (min-width: $break-extra-large) {
                    max-width:1140px;
                }
            }
        }
    }
    .top-bandeau{
        background: $lightBlue;
        display:block;
        font-size:0.75rem;
        z-index: 2;
        .wrap{
            @include flex(row, center, space-between);
            .link-bandeau{
                color: $white;
                padding: 22px 0;
                width: 100%;
                text-align: center;
                @media screen and (min-width: $break-large) {
                    padding: 10px 0;
                }
            }
        }
        &.sticked{
            position: fixed;
            top: 0;
            z-index: 99;
            max-width: 100%;
            background: white;
            left: 0;
            right: 0;
            height:40px;
            .wrap{
                @media screen and (min-width: $break-large) {
                    max-width:960px;
                    margin:0 auto;
                }
                @media screen and (min-width: $break-extra-large) {
                    max-width:1140px;
                }
            }
        }
    }
    .align-sponsors{
        display: flex;
        height: 550px;
        @media (max-width: 700px) {
            height: 400px;
        }
        .logos-sponsors {
            width: 20%;
            background-color: #84c6d0;
            background-image: url('#{$imgPath}banniere/zone-blanche.png');
            background-size:150px;
            background-position:left top;
            background-repeat:no-repeat;
            padding-top: 35px;
            // @media (max-width: 1600px) {
            //     background-size:50%;
            // }
            // @media (max-width: 1200px) {
            //     background-size:70%;
            // }
            div {
                margin-top: 20px;
                width: 60px;
                height: 60px;
                margin-left: 10px;
                img {
                    width: 60px;
                }
            }
            @media (max-width: 1000px) {
                display: none;
            }
        }
        
        .banniere {
            width: 80%;
            @media (max-width: 1000px) {
                width: 100%;
                > .container {
                    margin-right: 0;
                    max-width: 100%;
                }
            }
        }
    }
    .banniere{
        @media screen and (min-width: $break-large) {
            // background-image: url('#{$imgPath}banniere/banniere-header.jpg');
            background-image: url('#{$imgPath}banniere/image-header.png');
            background-size:auto;
            background-position:left;
            background-repeat:no-repeat;
            min-height:365px;
            @include flex(column, center, null);
            // overflow:hidden;
            z-index: index 900;;
        }
        @media screen and (min-width: $break-large) {
            min-height:400px;
        }
        @media screen and (min-width: $break-super-large) {
            min-height:723px;
        }
        >.container{
            position: relative;
            display:none;
            margin-left: 0;
            @media screen and (min-width: $break-large) {
                display:block;
                .galet.header{
                    position:absolute;
                    top: 50%;
                    left: 50%;
                    display: none;
                    @include translate(-49%, -53%);
                    @media screen and (min-width: $break-medium) {
                        width: 725px;
                        height: 569px;
                    }
                    @media screen and (min-width: $break-super-large) {
                        width: 1413px;
                        height: 1034px;
                        @include translate(-49%, -52%);
                    }
                }
                .capsule-titre{
                    position:relative;
                    z-index:9;
                    padding-top: 30px;
                    .logo{
                        text-align:center;
                        img{
                            max-width:127px;
                            height:auto;
                            margin: 0 auto;
                        }
                    }
                    .title{
                        text-align: center;
                        font-size:0.9rem;
                        color:$white;
                        font-weight:bold;
                        padding-bottom:1rem;
                        @media screen and (min-width: $break-large) {
                            font-size:1.4rem;
                            padding-top: 0.5rem;
                        }
                    }
                    h1{
                        font-size:1rem;
                        color:$white;
                        text-align: center;
                        font-weight: 300;
                        padding-bottom:1.2rem;
                        @media screen and (min-width: $break-super-large) {
                            font-size:3.37rem;
                            padding-bottom: 3rem;
                        }
                        span.highlighted{
                            font-weight:500;
                            color:$white;
                            @media screen and (min-width: $break-medium) {
                                display:block;
                            }
                        }
                    }
                }
                .searching{
                    .select2-results__option--highlighted{
                        display: none !important;
                    }
                    .search-form{
                        border:0;
                        margin: 0 auto;
                        position:relative;
                        text-align: center;
                        max-width:320px;
                        padding:0;
                        z-index:9;
                        @media screen and (min-width: $break-super-large) {
                            width:730px;
                            max-width:inherit;
                        }
                        .search{
                            input{
                                color: $grey;
                                font-size:0.87rem;
                                @include radius(100px);
                                vertical-align:middle;
                                padding: 13px 0 13px 30px;
                                width:100%;
                                border:0;
                                // max-width:300px;
                                @media screen and (min-width: $break-large) {
                                    padding: 13px 0 13px 50px;
                                }
                                &::-webkit-input-placeholder {
                                    color: $grey;
                                    font-size:0.87rem;
                                    vertical-align:middle;
                                }
                                &:-moz-placeholder {
                                    color: $grey;
                                    opacity:  1;
                                    font-size:0.87rem;
                                    vertical-align:middle;
                                }
                                &::-moz-placeholder {
                                    color: $grey;
                                    opacity:  1;
                                    font-size:0.87rem;
                                    vertical-align:middle;
                                }
                                &:-ms-input-placeholder {
                                    color: $grey;
                                    font-size:0.87rem;
                                    vertical-align:middle;
                                }
                            }
                        }
                        .action{
                            background:$deepRed;
                            @include radius(100px);
                            position:absolute;
                            // top:50%;
                            right:0;
                            // @include translate(0%, -50%);
                            width:47px;
                            height:47px;
                            border:1px solid $deepRed;
                            @media screen and (min-width: $break-medium) {
                                // top: 50%;
                                left: inherit;
                                right:0;
                                // @include translate(0%, -50%);
                            }
                            @media screen and (min-width: $break-super-large) {
                                width:237px;
                            }
                            button{
                                color:$white;
                                font-weight:bold;
                                text-align:center;
                                font-size:1rem;
                                padding: 11px 0;
                                background: none;
                                border:0;
                                span{
                                    display:none;
                                    @media screen and (min-width: $break-super-large) {
                                        display:inline;
                                    }
                                }
                                .fa{

                                    @media screen and (min-width: $break-super-large) {
                                        padding-right:0.7rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //la bar de navigation avec buton burger et menu de nav
    nav.navbar{
        position:fixed;
        top:0;
        right:0;
        left:0;
        @media screen and (max-width: $break-large) {
            z-index: 99;
        }
        padding:0;
        background: $lightBlue;
        @media screen and (min-width: $break-large) {
            position:relative;
            box-shadow: 0 2px 10px 0 rgba(0,0,0,0.06);
            width: 97%;
            margin: 0 auto;
            @include radius(12px);
            margin-top: -2%;
            background: $white;
            // z-index: 1000;
            height:84px;
            padding:0.5rem 1rem;
        }
        //le burger
        .navbar-burger{
            position:absolute;
            top:5px;
            left:5px;
            display: inline-block;
            width: 50px;
            height:50px;
            @include radius(50%);
            padding: 0.3rem;
            background-color:$deepRed;
            z-index: 1;
            cursor:pointer;
            @media screen and (min-width: $break-large) {
                position:relative;
            }
            #burger{
                position:absolute;
                top: 13px;
                left: 9px;
                @include translate(-50%, -50%);
                -webkit-transition: .5s ease-in-out;
                -moz-transition: .5s ease-in-out;
                -o-transition: .5s ease-in-out;
                transition: .5s ease-in-out;
                cursor: pointer;
                span{
                    @include radius(4px);
                    display: block;
                    position: absolute;
                    height: 4px;
                    width:35px;
                    background: $white;
                    opacity: 1;
                    left:0;
                    @include rotate(0);
                    -webkit-transition: .25s ease-in-out;
                    -moz-transition: .25s ease-in-out;
                    -o-transition: .25s ease-in-out;
                    transition: .25s ease-in-out; 
                    &:nth-child(1){
                        top: 0px;
                        width:20px; 
                    }
                    &:nth-child(2){
                        top: 10px;
                    }
                    &:nth-child(3){
                        top: 20px;
                        width:20px;
                        left:0;
                        right:0;
                    }
                }
                &.open{
                    left:6px;
                }
                &.open span:nth-child(1) {
                    top: 10px;
                    // width:100%;
                    width:40px;
                    -webkit-transform: rotate(135deg);
                    -moz-transform: rotate(135deg);
                    -o-transform: rotate(135deg);
                    transform: rotate(135deg);
                }
                &.open span:nth-child(2) {
                    opacity: 0;
                    left: -60px;
                }
                &.open span:nth-child(3) {
                    //width:100%;
                    width:40px;
                    top: 10px;

                    -webkit-transform: rotate(-135deg);
                    -moz-transform: rotate(-135deg);
                    -o-transform: rotate(-135deg);
                    transform: rotate(-135deg);
                }
            }
        }
        //menu sticked en mobil 
        .small-menu,
        .small-menu.sticked{
            position: fixed;
            top: 0;
            z-index: 99;
            width: 100%;
            background: $lightBlue;
            left: 0;
            right: 0;
            height:77px;
            padding:10px 15px;
            @include flex(row, center, space-between);
            @media screen and (min-width: $break-large) {
                display:none;
            }
            .navbar-burger{
                background:none;
                position:relative;
                top: inherit;
                left: inherit;
                padding-left: 0;
            }
            .logo-sticked{
                display:block;
            }
            .search{
                display:block;
                .fa{
                    color:white;
                    font-size: 1.3rem;
                }
            }
        }

        //ul principale
        ul.menu{
            @include flex(column, null, null);
            
            margin:0;
            @media screen and (min-width: $break-large) {
                @include flex(row, center, space-between);
                width:100%;
                margin: 0 -20px;
            }
            @media screen and (max-width: $break-large) {
                margin: 0 -20px;
            }
            @media screen and (min-width: $break-extra-large) {
                @include justifyContent(center);
            }
            @media screen and (max-width: $break-large) {
                padding:2rem 0 0 0;
            }
            li.logo-sticked{
                display:none;
            }
            li.search-sticked{
                display:none;
            }
            li.nav-item.dropdown{
                list-style: none;
                position: static;
                @media screen and (max-width: $break-large) {
                    display: flex;
                    flex-direction: column;
                }
                // padding: 1rem;
                // max-height: 50px;
                // display: flex;
                // align-items: center;
                a.nav-link{
                    font-size:1rem;
                    &.selected{
                        font-weight: bold;
                    }
                    // font-weight:normal;
                    // text-transform:capitalize;
                    display:inline-block;
                    color:$white;
                    padding:0.9rem 0;
                    @media screen and (min-width: $break-large) {
                        font-size:0.8rem;
                        padding: 2rem 0 2rem 0;
                        vertical-align: baseline;
                        color:$grey;
                        display:inline;
                    }
                    @media screen and (min-width: $break-extra-large) {
                        font-size:0.8rem;
                        padding: 2rem 2.5rem 2rem 0.2rem;
                    }
                    @media screen and (min-width: $break-super-large) {
                        font-size:0.9rem;
                    }
                    @media screen and (max-width: $break-large) {
                        padding: 0.9rem 20px;
                    }
                    &:after{
                        display:none;
                    }
                }
                >.dropdown-menu{
                    border:0;
                    @include radius(0);
                    @media screen and (min-width: $break-large) {
                        top: 55px;
                        width: 100%;
                    }
                    .dropdown-item{
                        padding: 0.4rem 1.5rem;
                    }
                    @media screen and (max-width: $break-large) {

                        margin-left: -20px;
                        margin: 0;
                        .dropdown-item{
                            
                            border-radius: 0;
                            i{
                                position: absolute;
                                right: 20px;
                                top: 20px;
                            }
                        }
                        .bloc-item{

                            padding:20px 0;
                            .menu-content{
                                display: block;
                            }
                            .list-scat{
                                padding: 0;
                                a.nav-link{
                                    color: #000;
                                }
                            }
                            .list-sscat-clone{
                                display: none !important;
                            }
                            .top-cat{
                                margin: 20px;
                                padding: 0;
                                align-items: center;
                                padding-top: 20px;
                                border-top: solid #E3E3E3;
                            }
                        }
                    }
                }


            }
        }
        // la collapse ouverte en menu mobil
        .navbar-collapse {
            position: absolute;
            top: 0;
            left:-100%;
            transition: all 0.4s ease;
            display: block;
            padding: 60px 15px 0 15px;
            background:$lightBlue;
            height:100vh;
            overflow-x:hidden;
            width: 100%;
            max-width:375px;
            @media screen and (min-width: $break-large) {
                background:none;
                height:auto;
                width:auto;
                max-width:100%;
                padding: initial;
                position:relative;
                left:initial;
                top: initial;
                overflow: initial;

            }
            @media screen and (max-width: $break-large) {
                .dropdown-item{
                    display: block;
                    &.hover .dropdown-sous-menu{
                        display: block;
                    }
                }
                .dropdown-menu{
                    position: static;
                }
                .galet.header{
                    display: none;
                }
            }
        }
        .navbar-collapse.collapsing {
            height: 100vh !important;
            margin-right: 50%;
            transition: all 0.3s ease;
            display: block;
        }
        .navbar-collapse.show {
            left: 0;
        }
        .galet.header{
            background:$white;
            opacity:0.3;
            width: 700px;
            height: 105vh;
            position:absolute;
            top: 50%;
            left: 50%;
            @include translate(-52%, -55%);
            z-index:0;
            @media screen and (min-width: $break-large) {
                display:none;
            }
        }
        //partie supplémentaire de la collapse quand menu mobil
        .top-collapse-mobil{
            @media screen and (min-width: $break-large) {
                display:none;
            }
            .logo{
                text-align:center;
                padding:0.3rem 0 1rem 0;
                max-width: 125px;
                height: auto;
                margin: 0 auto;
            }
            .actions{
                padding:2rem 0 0 0;
                @include flexDirection(row);
                @include justifyContent(center);
                .bookmark,
                .account{
                    img{
                        padding-right:10px;
                    }
                    .far{
                        padding-right:10px;
                    }
                }
                .bookmark,
                .account{
                    padding:0;
                    a{
                        text-transform:capitalize;
                        color:$white;
                        font-weight:500;
                        font-size:0.9rem;
                    }
                }
                .bookmark{
                    text-align:left;
                    padding: 0 0 0 15px;
                    &:after{
                        content:'';
                        border-right:1px solid $white;
                        margin-left:13%;
                        @media screen and (min-width:342px ) {
                            margin-left:20%;
                        }
                        @media screen and (min-width:360px ) {
                            margin-left:25%;
                        }
                        @media screen and (min-width:378px ) {
                            margin-left:29%;
                        }
                    }
                }
                .account{
                    text-align:right;
                    padding: 0 15px 0 0;
                }

            }

        }
        //partie supplémentaire de la collapse quand menu mobil
        ul.second-menu-mobil{
            @include flex(column, null, null);
            padding:2rem 0 0 0;
            margin:0;
            @media screen and (min-width: $break-large) {
                display:none;
            }
            li.nav-item{
                list-style:none;
                border-top: 1px solid rgba(255, 255, 255, 0.4);
                z-index:3;
                a.nav-link{
                    font-size:1rem;
                    font-weight:normal;
                    text-transform:capitalize;
                    display:inline-block;
                    color:$white;
                    padding:0.9rem 0;
                }
            }
        }
        //partie supplémentaire de la collapse quand menu mobil
        .bottom-collapse-mobil{
            padding:1.5vh 0;
            .socials{
                @include flexDirection(row);
                @include justifyContent(center);
                .rs{
                    text-align:center;
                    .fab{
                        color:$white;
                        font-size:2rem;
                    }
                }
            }
            @media screen and (min-width: $break-large) {
                display:none;
            }
            .logos-sponsors{
                padding-top: 20px;
                @include flexDirection(row);
                @include justifyContent(center);
            }
        }
        &.sticked{
            padding: 0;
            position: fixed;
            top: 40px;
            z-index: 99;
            width: 100%;
            background: $lightBlue;
            left: 0;
            right: 0;
            box-shadow:none;
            margin-top: 0;
            @include radius(0);
            /*            @media screen and (min-width: $break-extra-large) {
                            top:68px;
                        }
                        @media screen and (min-width: $break-super-large) {
                            top:70px;
                        }
                        @media screen and (min-width: $break-end) {
                            top:78px;
                        }*/
            ul.menu{
                @include justifyContent (space-between);
                @media screen and (min-width: $break-extra-large) {
                    @include justifyContent (center);
                }
                li.logo-sticked{
                    display:list-item;
                    list-style: none;
                    padding-right: 1rem;
                }
                li.search-sticked{
                    display:list-item;
                    list-style: none;
                    text-align: right;
                    width:170px;
                    form.search-form{
                        border:0;
                        margin-bottom:0;
                        padding: 0;
                        .search{
                            position:relative;
                            .fa{
                                position:absolute;
                                color: $deepRed;
                                top: 12px;
                                left: 25px;
                            }
                            input{
                                max-width:100%;
                                color: $deepRed;
                                font-size: 0.87rem;
                                @include radius(100px);
                                padding: 10px 10px 10px 20px;
                                text-align: center;
                                border: 0;
                                &::-webkit-input-placeholder {
                                    color:  $deepRed;
                                }
                                &:-moz-placeholder {
                                    color:  $deepRed;
                                }
                                &::-moz-placeholder {
                                    color:  $deepRed;
                                }
                                &:-ms-input-placeholder {
                                    color:  $deepRed;
                                }
                            }
                        }

                    }
                }
                li.nav-item.dropdown{
                    &:before{
                        display:none;
                    }
                    .list-scat a.nav-link{
                        color: initial;
                    }
                    a.nav-link{
                        color:$white;
                        
                        font-size:0.7rem;
                        padding:2rem 0.3rem 2rem 0.3rem;
                        @media screen and (min-width: $break-extra-large) {
                            padding:2rem 1rem 2rem 1rem;
                        }
                    }
                    &:after{
                        content:'|';
                        color:$white;
                    }
                    .dropdown-menu{
                        top: 62px;
                        width: 100%;
                        .dropdown-item{
                            padding: 0.6rem 1.5rem;
                        }
                    }
                }
                li:nth-last-child(2):after{
                    display:none;
                }
            }

        }
    }
}
