/*-------------------------------------------------------
 ------------------->>> Annonce<<<----------------------
vue d'un annonce (show)+ vue de recherche annonce et pro (listing))
 -------------------------------------------------------  */

.img-card-anounce > picture img{
    width: 275px;
    height: 174px;
    object-fit: cover;
}

// listing announces
.listing-container{
    .listing{
        .offre-shop{
            .container-cards-col{
                display: flex;
                justify-content: center;
                @media screen and (min-width: 1724px){
                    max-width: 25%;
                }
                @media screen and (min-width: 2224px){
                    max-width: 20%;
                }
                @media screen and (min-width: 2734px){
                    max-width: 16%;
                }
                @media screen and (min-width: 3134px){
                    max-width: 14%;
                }
                @media screen and (min-width: 3734px){
                    max-width: 12%;
                }
                @media screen and (min-width: 4634px){
                    max-width: 10%;
                }
                @media screen and (min-width: 5634px){
                    max-width: 9%;
                }
                @media screen and (min-width: 6634px){
                    max-width: 7%;
                }
            }
            .capsule{
                .fav-mobile{
                    bottom: 12%;
                }
                // .infos{
                //     width: 100%;
                //     @media screen and (min-width:1024px){
                //         width: 255px;
                //     }
                // }
                .bottom-card{
                    width: 100%;
                }
            }
        }
    }
}


/* vue d'une annonce*/
.annonce{
    //header .align-sponsors {
    //    height: 400px;
    //}
    main{
        overflow-x:hidden;
    }
    //header{
    //    .logos-sponsors {
    //        background-image: none;
    //    }
    //    .banniere{
    //        background-image: none;
    //        @media screen and (min-width: $break-large) {
    //            min-height:340px;
    //        }
    //        .capsule-titre{
    //            @media screen and (min-width: $break-large) {
    //                .logo{
    //                    img{
    //                        max-width: 127px;
    //                        height: auto;
    //                        padding-top: 23px;
    //                        padding-bottom:0;
    //                    }
    //                }
    //            }
    //        }
    //    }
    //}
    #top-annonce{
       // padding-top: 77px;
        position: relative;
        @media screen and (min-width: $break-large) {
            padding-top: 0;
        }

        .annonce-forme{
            /// background: rgba(254,114,67,0.10);
            position: absolute;
            top: 0;
            left:-10vw;
            width: 120vw;
            @include radius (0% 0% 50% 50%/ 0% 0% 80px 80px);  
            height: 1850px; 
            @media screen and (min-width: $break-medium) {
                height: 1350px; 
                @include radius (0% 0% 50% 50% /0% 0% 140px 140px);  
            }
            @media screen and (min-width: $break-large) {
                top: -65px;///car dès le départ sous la barre de navigation 
                height: 1185px; 
            }
        }
        .breadcrumb{
            background:none;
            padding:30px 0;
            @include justifyContent (space-between);
            @include alignItems (center);
            margin-bottom:0;
            .btn-back{
                font-size:0.75rem;
                font-weight:500;
                color:$deepRed;
                span{
                    text-decoration:underline;
                }
                &:before{
                    content:'<';
                    text-decoration:none;
                    padding-right: 0.3rem;
                }
            }
            .fil{
                font-size:0.75rem; 
                text-transform:capitalize;
                padding:0.3rem 15px;
                @media screen and (min-width: $break-large) {
                    padding:0 15px;
                }
                span:not(:last-child){
                    font-weight:regular;
                    &:after{
                        content:'|';
                        padding: 0 0.2rem;
                    }
                }
                span:last-child{
                    font-weight:bold;
                }
            }
        }
        .article{
            background:$white;
            @include radius(12px);
            padding:30px;
            .pict{
                height:341px;
                @include radius(12px);
                background-size:cover;
                background-repeat:no-repeat;
                background-position:center center;
            }
            .infos{
                .place{
                    font-weight:bold;
                    font-size:1.65rem;
                    padding-top:23px;
                    padding-bottom:9px;
                }
                .annonce{
                    font-size:1.62rem;
                    padding-bottom:20px;
                }
                .more-infos{
                    @include justifyContent (flex-start);
                    @include alignItems (flex-end);
                    min-height:170px;
                    .price{
                        width:100%;
                        @include flex(row, baseline, space-between);
                        @media screen and (min-width: $break-medium){
                            @include justifyContent(flex-start);
                        }
                        @media screen and (min-width: $break-extra-large){
                            @include justifyContent(space-between);
                        }
                        .new{
                            font-size:2.37rem;
                            font-weight: bold;
                        }
                        .old{
                            font-size: 1rem;
                            color:$grey;
                            padding-left: 10px;
                        }
                        .time{
                            padding-left:16px;
                            color:$deepRed;
                            font-size:0.75rem;
                            &:before{
                                color:$deepRed;
                                content:'\f017';
                                font-family: "Font Awesome 5 Free";
                                font-weight: 400;
                                font-size:0.75rem;
                                padding-right:6px;
                            }
                        }
                    }
                    .more{
                        font-size:0.87rem;
                        text-align: right;
                    }
                    .cta{
                        margin-top:27px;
                        margin-bottom:30px;
                        .btn-cta{
                            max-width:100%;
                            @media screen and (min-width: $break-small) {
                                max-width: 280px;
                                margin: 0 auto 0 0;
                            }
                            @media screen and (min-width: $break-large) {
                                padding: 14px 39px;
                            }
                        }
                    }

                }
            }
            .share{
                @include justifyContent (space-between);
                @include alignItems (center);
                padding:0;
                .bookmark{
                    font-size:0.68rem;
                    @media screen and (min-width: $break-large) {
                        padding-bottom:9px;
                    }
                    @media screen and (min-width: $break-extra-large) {
                        padding-bottom:0;
                    }
                    .like{
                        cursor:pointer;
                    }

                }
                .socials{
                    font-size:0.68rem;
                    @media screen and (min-width: $break-small) {
                        text-align:right;
                    }
                    @media screen and (min-width: $break-large) {
                        text-align:left;
                    }
                    @media screen and (min-width: $break-extra-large) {
                        text-align:right;
                    }
                    span{
                        padding-right:0.8rem;
                    }
                    a{
                        font-size:1rem;
                    }
                    a:not(:last-child){

                        padding:0 0.2rem 0 0;
                    }
                }
            }
            .contenu-article{
                padding:30px 0;
                .title{
                    font-size:1.25rem;
                    padding-bottom:20px;
                    font-weight:bold;
                    span{
                        color:$grey;
                        font-weight:normal;
                    }
                }
                .description{
                    font-size:0.87rem;
                }

            }
        }
        .map{
            background:$white;
            @include radius(12px);
            margin-top:30px;
            .about{
                @include flex(column,null,null);
                padding:30px;
                .place{
                    font-size:1.25rem;
                    padding-bottom:20px;
                    font-weight:bold;
                    span{
                        color:$grey;
                        font-weight:normal;
                    }
                }
                p{
                    margin-bottom:0;
                    font-size:0.87rem;
                }
                .cta{
                    margin-top:20px;
                    @media screen and (min-width: $break-small) {
                        //max-width: 187px;
                        margin: 1rem auto 0 0;
                    }
                    @media screen and (min-width: $break-medium) {
                        margin-top:auto;
                    }
                    .btn-cta.reverse{
                        background:white;
                        max-width:100%;
                        @media screen and (min-width: $break-extra-large) {
                            padding: 14px 97px;
                        }
                    }
                }
            }
        }
        aside{
            padding-top: 84px;
            .about-shop{
                .vignette{
                    margin-bottom: 75px;//les 30 + les 44 de débord du bas de la vingette
                    max-width:350px;
                    margin: 0 auto;
                    @media screen and (min-width: $break-small) {
                        margin: 0 auto 0 0;
                    }
                    @media screen and (min-width: $break-large) {
                        margin: 0 auto;
                    }
                    .capsule{
                        @include radius(12px);
                        padding: 20px;
                        position:relative;
                        min-height: 360px;
                        margin-bottom: 60px;
                        box-shadow: 0 2px 2px 0 rgba(250, 185, 129, 0.2);
                        @media screen and (min-width: $break-medium) {
                            min-height: 300px;
                        }
                        @media screen and (min-width: $break-large) {
                            min-height: 340px;
                        }
                        @media screen and (min-width: $break-extra-large) {
                            min-height: 325px;
                        }
                        .top-infos{
                            color:$white;
                            text-align:center;
                            .city{
                                font-weight:300;
                                font-size:18px;
                                padding-bottom:5px;
                            }
                            .place{
                                font-weight:bold;
                                font-size:26px;
                            }
                        }


                        // .link-annonce{
                        //     position: relative;
                        //     padding: 20px;
                        //     overflow: hidden;
                        //     .img-card-anounce{
                        //         min-width: 255px;
                        //         min-height: 174px;
                        //         background-size: cover;
                        //         background-repeat: no-repeat;
                        //         border-top-left-radius: 12px;
                        //         border-top-right-radius: 12px;
                        //     }
                        //     .top-infos{
                        //         color:$white;
                        //         text-align:center;
                        //         .city{
                        //             font-weight:300;
                        //             font-size:18px;
                        //             padding-bottom:5px;
                        //         }
                        //         .place{
                        //             font-weight:bold;
                        //             font-size:26px;
                        //             padding-bottom:13px;
                        //         }
                        //     }
                        // }
                        // a.link-boutique{
                        //     padding: 30px 20px;
                        //     display: block;
                        //     min-height: 360px;
                        //     @media screen and (min-width: $break-medium) {
                        //         min-height: 300px;
                        //     }
                        //     @media screen and (min-width: $break-large) {
                        //         min-height: 340px;
                        //     }
                        //     @media screen and (min-width: $break-extra-large) {
                        //         min-height: 325px;
                        //     }
                        //     .top-infos{
                        //         color:$white;
                        //         text-align:center;
                        //         .city{
                        //             font-weight:300;
                        //             font-size:18px;
                        //             padding-bottom:5px;
                        //         }
                        //         .place{
                        //             font-weight:bold;
                        //             font-size:26px;
                        //             padding-bottom:13px;
                        //         }
                        //     }
                        // }
                        .capsule-bottoms{
                            position: absolute;
                            left: 20px;
                            right: 20px;
                            @include radius(12px);
                            //  box-shadow: 0 0 2px 2px rgba(115,200,189,0.10);
                            background: #fff;
                            .bottom-infos{
                                background:$white;
                                // border-left: 2px solid $white;
                                // border-right: 2px solid $white;
                                padding: 25px 18px;
                                @include radius(12px 12px 0 0);
                                box-shadow: none;
                                .infos{
                                    @include flexDirection(row);
                                    @include alignItems (center);
                                    // min-height:101px;
                                    // @media screen and (min-width: $break-large) {
                                    //     min-height:175px;
                                    // }
                                    // @media screen and (min-width: $break-extra-large) {
                                    //     min-height:101px;
                                    // }
                                    .avatar{
                                        width: 78px;
                                        height: 78px;
                                        overflow: hidden;
                                        @include radius (50%);
                                        margin: 0 auto;
                                        @media screen and (min-width: $break-medium) {
                                            margin: 0;
                                        }
                                    }
                                    .name{
                                        text-align:center;
                                        span{
                                            font-size: 17.5px;
                                            font-weight: 500;
                                            &.uppercase{
                                                text-transform: uppercase;
                                            }
                                        }
                                    }
                                    p.adress,
                                    p.city{
                                        font-size: 13px;
                                        font-weight:normal;
                                        text-align:center;
                                        margin:0;
                                        color: #4a4a4a;
                                    }
                                    p.adress{
                                        font-weight: 500;
                                    }
                                    p.city{
                                        text-transform: uppercase;
                                    }
                                }
                            }
                            .bottom{
                                left: 0;
                                bottom: -12%;
                                width: 100%;
                                height: 45px;
                                padding:10px;
                                @include radius(0 0 12px 12px);
                                @include flex(row, center, space-between);
                                @media screen and (min-width: $break-large) {
                                    font-size:1rem;
                                    padding:10px 15px;
                                }
                                span{
                                    font-size:0.8rem;
                                    color:$grey;
                                    display:inline-block;
                                    margin: 0 auto;
                                    display:none;
                                    @media screen and (min-width: $break-super-large) {
                                        display:inline;

                                    }
                                }
                                button{
                                    padding-right: 0;
                                    padding-left: 5px;
                                    font-size:0.8rem;
                                    color:$deepRed;
                                    display:inline-block;
                                    background: none;
                                    border: none;
                                    -webkit-appearance: none;
                                    &.mail{
                                        a{
                                            color:$deepRed;
                                        }
                                        &:before{
                                            content:'\f2b6';
                                            font-family: "Font Awesome 5 Free";
                                            font-weight: 900;
                                            color:$deepRed;
                                            padding-right: 5px;
                                        }
                                        &:after{
                                            content:'|';
                                            color:$deepRed;
                                            margin: 0 0.3rem 0 2.1rem;
                                            @media screen and (min-width: 375px) {
                                                margin: 0 0.3rem 0 2.7rem;
                                            }
                                            @media screen and (min-width: 410px) {
                                                margin: 0 0.3rem 0 3rem;
                                            }
                                            @media screen and (min-width: $break-large) {
                                                margin: 0 0.3rem 0 2.6rem;
                                            }
                                            @media screen and (min-width: $break-extra-large) {
                                                margin: 0 0.3rem 0 2rem;
                                            }
                                            @media screen and (min-width: $break-super-large) {
                                                margin: 0 0 0 0.2rem;
                                            }
                                        }
                                    }
                                    &.phone{
                                        a{
                                            color:$deepRed;
                                        }
                                        &:before{
                                            content:'\f095';
                                            font-family: "Font Awesome 5 Free";
                                            font-weight: 900;
                                            color:$deepRed;
                                            @include rotate(95deg);
                                            display: inline-block;
                                            padding-right: 4px;
                                        }
                                    }
                                }
                            }
                            p.website{
                                bottom: 0%;
                                left: 50%;
                                width: 100%;
                                text-align: center;
                                background: #fff;
                                &:before{
                                    content:'\f7a2';
                                    font-family: "Font Awesome 5 Free";
                                    font-weight: 900;
                                }
                                a{
                                    font-size: 14px;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                } 
            }
            .follow-shop,
            .products-shop{
                background:$white;
                padding:30px;
                @include radius(12px);
                .title{
                    font-size:1.6rem;
                    color:$lightBlue;
                    font-weight:900;
                    span{
                        font-weight:normal;
                        color:$grey;
                    }
                }
                ul{
                    padding-top:30px;
                    margin-bottom:0;
                    li{
                        padding-bottom:5px;
                        font-size:1rem;
                        color:$grey;
                        font-weight:normal;
                        a{
                            font-size:1rem;
                            font-weight:$grey;
                            font-weight:normal;
                        }
                        span{
                            padding-left:14px;
                        }
                    }
                }
            }
            .follow-shop{
                margin-top:140px;
                @media screen and (min-width: $break-large) {
                    margin-top:105px;
                }

            }
            .products-shop{
                margin-top:110px;
            }
        }
        .capsule-galet-1{
            position:relative;
            .galet{
                background: #4ABBE2;
                position: absolute;
                opacity:0.05;
                top:-4px;
                left: -680px;
                top: -1140px;
                @include rotate(1deg);
                z-index:-1;
                @media screen and (min-width: $break-large) {
                    left: -1085px;
                    top: -350px;
                    @include rotate(-97deg);
                }
            }
        }
    }
    #bottom-annonce{
        padding:100px 0 60px 0;
        .capsule-galet-2{
            position:relative;
            overflow-x:hidden;
            .galet3{
                background: #E25B45;
                position: absolute;
                opacity:0.05;
                right:-150px;
                top:-4px;
                width: 300px;
                height: 333px;
                @include rotate(152deg);
                @media screen and (min-width: $break-large) {
                    width: 594px;
                    height: 666px;
                    right:-405px;
                    top:9px; 
                    @include rotate(141deg);
                }
                @media screen and (min-width: $break-extra-large) {

                    right:-390px;
                    top:-21px; 
                    @include rotate(111deg);
                }
            }
        }
        .titles{
            padding-bottom:40px;
        }
        .offre-shop{
            .vignette-annonce{
                padding-left: 0;
                padding-right: 0;
                .capsule{
                    margin: 0 auto 30px;
                    //padding:20px;
                    @media screen and (min-width: $break-extra-large) {
                        margin: 0 5px 30px;
                    }
                    .infos{
                        .offer{
                            font-size:0.9rem;
                        }
                    }
                }
            }
        }
        //.cta{
        //    .btn-cta{
        //        max-width:360px;
        //    }
        //}
    }
}


/*vue de recherche des annonce et pro*/
.search-page{
    //pas le main overflow car galet sous le menu de nav
    overflow-x: hidden; 
    padding-top:77px;
    @media screen and (min-width: $break-large) {
        padding-top:0;
    }
    //header{
    //    .banniere{
    //        @media screen and (min-width: $break-large) {
    //            min-height:340px;
    //        }
    //        .capsule-titre{
    //            @media screen and (min-width: $break-large) {
    //                .logo{
    //                    img{
    //                        max-width: 127px;
    //                        height: auto;
    //                        padding-top: 23px;
    //                        padding-bottom:0px;
    //                    }
    //                }
    //            }
    //        }
    //    }
    //
    //}
    .capsule-galet-1{
        position:relative;
        .galet3{
            background: $deepRed;
            position: absolute;
            opacity:0.05;
            top:-320px;
            left: -350px;
            @include rotate(0deg);
            z-index:1;
            @media screen and (min-width: $break-large) {
                width: 594px;
                left: -360px;
                top: -333px;
                @include rotate(0deg);
            }
        }
    }

    .titles{
        padding-top: 61px;
        .accroche{
            text-align:center;
            font-size:0.87rem;
            padding-top:20px;
            padding-bottom:20px;
            margin: 0 auto;
            @media screen and (min-width: $break-large) {
                font-size:20px;
            }

        }
    }
    #searching{
        background: rgba(226,91,69,0.08);
        @include radius(12px);
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;
        padding:30px;
        @media screen and (min-width: $break-small) {
            margin-left: 0;
            margin-right: 0;
        }
        .search-form{
            border:0;
            margin: 0 auto;
            position:relative;
            text-align: center;
            width:100%;
            max-width:320px;
            padding:0;
            z-index:9;
            @media screen and (min-width: $break-medium) {
                max-width:100%;
            }
            .search{
                input{
                    color: $grey;
                    font-size:0.87rem;
                    @include radius(100px);
                    vertical-align:middle;
                    padding: 13px 0 13px 30px;
                    width:100%;
                    border:0;
                    @media screen and (min-width: $break-large) {
                        padding: 13px 0 13px 50px;
                    }
                    &::-webkit-input-placeholder {
                        color: $grey;
                        font-size:0.87rem;
                        vertical-align:middle;
                    }
                    &:-moz-placeholder {
                        color: $grey;
                        opacity:  1;
                        font-size:0.87rem;
                        vertical-align:middle;
                    }
                    &::-moz-placeholder {
                        color: $grey;
                        opacity:  1;
                        font-size:0.87rem;
                        vertical-align:middle;
                    }
                    &:-ms-input-placeholder {
                        color: $grey;
                        font-size:0.87rem;
                        vertical-align:middle;
                    }
                }
            }
            .action{
                background:$deepRed;
                @include radius(100px);
                position:absolute;
                top:0;
                right:0;
                width:45px;
                @media screen and (min-width: $break-medium) {
                    top: 50%;
                    right: 0;
                    @include translate(0, -50%);
                    width:237px;  
                }
                button{
                    color:$white;
                    font-weight:bold;
                    text-align:center;
                    font-size:1rem;
                    padding: 11px 0;
                    background: none;
                    border:0;
                    span{
                        display:none;
                        @media screen and (min-width: $break-medium) {
                            display:inline;
                        }
                    }
                    .fa{
                        @media screen and (min-width: $break-super-large) {
                            padding-right:0.7rem;
                        }
                    }
                }
            }
        }
    }
    .result{
        font-family:$Now;
        color:$black;
        padding-top:20px;
        padding-bottom:60px;
        text-align:center;
        .return{
            color:$lightBlue;
        }
    }
    .capsule-galet-2{
        overflow:hidden;
        position:relative;
        .galet{
            background: $lightBlue;
            position: absolute;
            opacity:0.05;
            width: 554px;
            height: 459px;
            top:-415px;
            left: inherit;
            right:-350px;
            @include rotate(90deg);
            z-index:-1;
            @media screen and (min-width: $break-large) {
                left: inherit;
                right:-246px;
                top: -586px;
                @include rotate(79deg);
            }
            @media screen and (min-width: $break-super-large) {
                left: inherit;
                right:-246px;
                top: -586px;
                @include rotate(79deg);
            }
        }
    }
    #annonce{
        /* padding-bottom:50px;
        @media screen and (min-width: $break-large) {
            padding-bottom:100px;
        }*/
        #more-annonces{
            @media screen and (min-width: $break-extra-large) {
                @include justifyContent (space-between);
            }
            padding-left: 0;
            padding-right: 0;
        }
        .results{
            padding-bottom:30px;
            @media screen and (min-width: $break-small) {
                @include justifyContent(space-between);
            }
            h2{
                font-weight: 900;
                color: #4bbbe0;
                text-align:left;
                font-size:1.62rem;
                @media screen and (min-width: $break-medium) {
                    font-size:1.75rem;
                }
            }
            .see-all{
                display:block;
                color:$deepRed;
                text-transform:uppercase;
                text-decoration:underline;
                font-size:1.12rem;
                font-weight: 900;
                cursor: pointer;
                @media screen and (min-width: $break-small) {
                    text-align:right;
                }
            }
        }
        .pagination{
            padding-bottom: 61px;
        }

        .vignette-annonce{

            .capsule{
                align-items: center;
                max-width: 300px;
                margin: 0 auto 30px;
                @media screen and (min-width: $break-small) {
                    max-width: 255px;
                }
                @media screen and (min-width: $break-medium) {
                    max-width: 300px;
                }
                @media screen and (min-width: $break-extra-large) {
                    max-width: 255px;
                }
                &:hover{
                    .bottom-card{
                        bottom: 4.4%;
                    }
                }
                .bottom-card{
                    left: 0;
                }
                /*
                .picto{
                    background-size:cover;
                    background-repeat:no-repeat;
                    background-position:center center;
                    height:40px;
                    width:40px;
                    @include radius(12px);
                }
                .infos{
                    padding:21px;
                    margin: 20px 0 0 0;
                    background:$white;
                    @include radius(12px);
                    .city{
                        color:$grey;
                        font-weight:300;
                        font-size:0.62rem;
                        text-transform:capitalize;
                        padding-bottom:10px;
                    }
                    .place{
                        // color:$lifestyleCat;
                        font-weight:bold;
                        font-size:1.12rem;
                        padding-bottom:5px;
                    }
                    .offer{
                        color:$grey;
                        font-weight:normal;
                        font-size:1.12rem;
                        padding-bottom:35px;
                    }
                    .price{
                        @include flex(row, baseline, space-between);
                        .new{
                            font-size:1.37rem;
                            font-weight:900; 
                        }
                        .old{
                            font-size: 0.625rem;
                            color:$grey;
                        }
                        .time{
                            padding-left:16px;
                            color:$deepRed;
                            font-size:0.75rem;
                            &:before{
                                color:$deepRed;
                                content:'\f017';
                                font-family: "Font Awesome 5 Free";
                                font-weight: 400;
                                font-size:0.75rem;
                                padding-right:6px;
                            }
                        }
                    }
                }
                .bottom{
                    padding:15px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    // background-color:$lifestyleCat;
                    @include radius(0 0 12px 12px);
                    @include flex (row, center, space-between);
                    span{
                        a{
                            color:$white;
                            font-weight:bold;
                            font-size:0.68rem;
                        }
                        &.bookmark{
                            position:relative;
                            width:66%;
                            font-size:0.6rem;
                            color:$white;
                            &:after{
                                content: '|';
                                right: 0;
                                position: absolute; 
                                color:$white;
                            }
                            .hide {
                                display: none;
                                @media screen and (min-width: $break-extra-large) {
                                    display: inline;
                                }
                            }
                        }
                        &.like{
                            padding-right:5px;
                            font-size:0.6rem;
                            color:$white;
                        }
                    }
                }*/
            }
            &.vignette-annonce-more{
                display: none;
            }
        }
    }

    .capsule-pro{
        @include flex(column, center, flex-start);
        min-height:2543px;
        overflow:hidden;
        position:relative;
        z-index:0;
        @media screen and (min-width: $break-medium) {
            min-height: 1156px;
        }
        @media screen and (min-width: $break-extra-large) {
            min-height:851px;
        }
        .pro-forme{
            position:absolute;
            bottom:0;
            background:$blocPro;
            left:-10vw; //pour IE 
            width:120vw;
            @include radius (50% 50% 0 0 /80px 80px 0 0);  
            min-height:2540px;  
            @media screen and (min-width: $break-medium) {
                min-height: 1156px;
            }
            @media screen and (min-width: $break-extra-large) {
                @include radius (50% 50% 0 0 /140px 140px 0 0); 
                min-height:851px;
            }
        }
        .results{
            padding-bottom:30px;
            padding-top:40px;
            @media screen and (min-width: $break-small) {
                @include justifyContent(space-between);
                padding-top:90px;
            }
            h2{
                font-weight: 900;
                color: #4bbbe0;
                text-align:center;         
                font-size:1.62rem;
                @media screen and (min-width: $break-small) {
                    text-align:left;
                }
                @media screen and (min-width: $break-medium) {
                    font-size:1.75rem;
                }
            }
            .see-all{
                display:block;
                color:$deepRed;
                text-transform:uppercase;
                text-decoration:underline;
                font-size:1.12rem;
                font-weight: 900;
                text-align:center;
                cursor: pointer;
                @media screen and (min-width: $break-small) {
                    text-align:right;
                }
            }
        }            
        .the-content{
            margin-bottom:40px;
            @media screen and (min-width: $break-extra-large) {
                @include justifyContent (space-between);
            }
            #more-boutiques{
                .capsule{
                    @media screen and (min-width: $break-extra-large) {
                        @include justifyContent (space-between);
                    }
                    width: 100%;
                }
            }
            .vignette{
                max-width:350px;
                margin: 0 auto;
                @media screen and (min-width: $break-medium) {
                    padding-left:inherit;
                    padding-right:inherit;
                }
                @media screen and (min-width: $break-extra-large) {
                    margin: 0;
                }
                .capsule{
                    margin-bottom: 60px;
                    @media screen and (min-width: $break-small) {
                        //min-height: 317px;
                        //height:317px;//POUR IE
                    }
                    @media screen and (min-width: $break-medium) {
                        min-height: 266px;
                        ///height:266px;//POUR IE
                    }
                    @media screen and (min-width: $break-large) {
                        min-height: 300px;
                        margin: 0 5px 60px 5px;
                        ///height:266px;//POUR IE
                    }
                    @media screen and (min-width: $break-extra-large) {
                        min-height: 266px;
                    }
                    .capsule-bottoms{
                        .bottom{
                            span{
                                &.bookmark{
                                    position:relative;
                                    width:66%;
                                    font-size:0.68rem;
                                    @media screen and (min-width: 430px) {
                                        width:50%;
                                    }
                                    @media screen and (min-width: 710px) {
                                        width:66%;
                                    }
                                    .hide{
                                        @media screen and (min-width: 430px) {
                                            display:none;
                                        } 
                                        @media screen and (min-width: 710px) {
                                            display:inline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.vignette-more{
                    display: none;
                }
            } 

        }
    } 


}